import React from "react";
import PageTitle from "../../components/PageTitle";
import { TextInputWithLabel } from "../../components/TextInputWithLabel";
import { addFloor, AddLog, addProperty, db, UploadFile } from "../../firebase";
import { useState, useEffect } from "react";
import PrimaryButton from "../../components/PrimaryButton";
import { Loading } from "../../components/Loading";
import UploadButton from "../../components/UploadButton";
import BlueItem from "../../components/BlueItem";
import DeleteButton from "../../components/DeleteButton";
import { UserAuth } from "../../AuthContext";
import PageAnimation from "../../components/PageAnimation";
import SuccessAnimation from "../../components/SuccessAnimation";
import { useLocation, useNavigate } from "react-router-dom";
import DropdownWithLabel from "../../components/DropdownWithLabel";
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import ErrorMsg from "../../components/ErrorMsg";

function AddProperty() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [other, setOther] = useState();
  const [files, setFiles] = useState([]);

  const [types, setTypes] = useState();
  const [error, setError] = useState([]);
  const { bn, user, rolesStream } = UserAuth();
  const navigate = useNavigate();
  const [fieldData] = useState([
    {
      label: "Property Name",
      placeholder: "Enter Property Name",
      label_bn: "প্রপার্টির নাম",
      placeholder_bn: "প্রপার্টির নাম লিখুন",
    },
    {
      label: "Property Size",
      placeholder: "Enter Property Size",
      label_bn: "প্রপার্টির আকার",
      placeholder_bn: "প্রপার্টির আকার লিখুন",
    },
    {
      label: "Property Location",
      placeholder: "Enter Property Location",
      label_bn: "প্রপার্টির অবস্থান",
      placeholder_bn: "প্রপার্টির অবস্থান লিখুন",
    },
    {
      label: "No. Of Floors",
      placeholder: "Enter No. Of Floors",
      label_bn: "তলার সংখ্যা",
      placeholder_bn: "তলার সংখ্যা লিখুন",
    },
    {
      label: "Default Floor Area",
      placeholder: "Enter Floor Area",
      label_bn: "ডিফল্ট তলার ক্ষেত্রফল",
      placeholder_bn: "তলার ক্ষেত্রফল লিখুন",
    },
  ]);

  const handleData = (e, label) => {
    const obj = {};
    obj[label.replace(/\s+/g, "")] = e.target.value;
    setData((prev) => {
      return { ...prev, ...obj };
    });
  };

  async function checkProperties(obj) {
    let errors = [];

    if (obj && obj["PropertyName"]) {
      console.log("yes");
      const props = [];
      const docRef = await getDocs(query(collection(db, "Properties")));
      docRef.forEach((d) => {
        props.push(d.data().PropertyName.toLowerCase());
      });
      if (
        props.length != 0 &&
        props.includes(obj["PropertyName"].toLowerCase())
      ) {
        console.log(props);
        errors.push("Same Property Name Exists");
      }
    }

    if (!obj.hasOwnProperty("PropertyName")) {
      errors.push("Property Name is missing.");
    } else if (typeof obj.PropertyName !== "string") {
      errors.push("Property Name must be a string.");
    }

    if (!obj.hasOwnProperty("PropertySize")) {
      errors.push("Property Size is missing.");
    } else if (isNaN(Number(obj.PropertySize))) {
      errors.push("Property Size must be a number");
    }

    if (!obj.hasOwnProperty("PropertyLocation")) {
      errors.push("Property Location is missing.");
    } else if (typeof obj.PropertyLocation !== "string") {
      errors.push("Property Location must be a string.");
    }

    if (isNaN(Number(obj.DefaultFloorArea))) {
      errors.push("Default Floor Area must be a number");
    }

    if (!obj.hasOwnProperty("No.OfFloors")) {
      errors.push("No. Of Floors is missing.");
    } else if (isNaN(Number(obj["No.OfFloors"]))) {
      errors.push("No. Of Floors must be a number");
    }

    return errors;
  }

  const getPropertyTypes = async () => {
    const docRef = await getDoc(doc(db, "Settings", "SystemData"));
    const propTypes = docRef.data().PropertyTypes;
    const arr = [];
    propTypes.map((p) => {
      arr.push({ Value: p, Name: p });
    });
    arr.push({ Value: "Others", Name: "Others" });
    setTypes(arr);
    setData({ PropertyType: "Apartment" });
  };

  const handleOther = (e, label) => {
    // const obj = {};
    // obj["PropertyType"] = e.target.value;
    // setData((prev) => {
    //   return { ...prev, ...obj };
    // });
    setOther(e.target.value);
  };

  const addProp = async () => {
    setLoading(true);

    const errs = await checkProperties(data);
    if (!errs.length == 0) {
      console.log(errs);
      setError(errs);
      setLoading(false);
      return;
    }
    setError([]);

    const obj = data;
    if (other != undefined) {
      await updateDoc(doc(db, "Settings", "SystemData"), {
        PropertyTypes: arrayUnion(other),
      });
      obj["PropertyType"] = other;
    }
    const propertyId = await addProperty(obj);
    console.log(propertyId);
    for (let i = 0; i < data["No.OfFloors"]; i++) {
      await addFloor(propertyId, {
        Order: i,
        FloorName: `${i}`,
        Area: data["DefaultFloorArea"],
      });
    }
    await AddLog(`${user.Name} has added a new Property: ${data.PropertyName}`);
    files.map(async (f) => {
      if (f != undefined) {
        await UploadFile(f, `Properties/${propertyId}`);
      }
    });
    setLoading(false);
    setDone(true);
    setTimeout(() => {
      navigate("/properties");
    }, [3000]);
  };

  const fileHandler = async (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles([...files, ...newFiles]);
  };

  const handleDeleteFile = async (index) => {
    setFiles((prev) => {
      delete prev[index];
      return [...prev];
    });
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  useEffect(() => {
    console.log(files);
  }, [files]);

  useEffect(() => {
    getPropertyTypes();
  }, []);

  useEffect(() => {
    if (rolesStream && user) {
      if (rolesStream[user.Role][`AddProperty`] != true) {
        navigate(`/home`);
      }
    }
  }, [rolesStream, user]);

  return (
    <>
      <PageTitle title={bn ? "Add Property" : `অ্যাড প্রপার্টি`} />
      <div className="flex-column">
        {fieldData.map((f) => {
          return (
            <TextInputWithLabel
              key={f.label}
              label={f.label}
              placeholder={f.placeholder}
              label_bn={f.label_bn}
              placeholder_bn={f.placeholder_bn}
              handleChange={handleData}
            ></TextInputWithLabel>
          );
        })}
        {types && (
          <DropdownWithLabel
            label="Property Type"
            placeholder="ble"
            items={types}
            handleChange={handleData}
          />
        )}
        {data && data.PropertyType == "Others" && (
          <TextInputWithLabel
            label="Other"
            placeholder="Other"
            handleChange={handleOther}
          />
        )}

        <br></br>
        {files.map((f, index) => {
          return (
            <div className="flex-row">
              {f && (
                <>
                  <BlueItem text={f.name} />
                  <DeleteButton name={index} handleDelete={handleDeleteFile} />
                  <br></br>
                </>
              )}
            </div>
          );
        })}
        <br></br>
        {!done && (
          <UploadButton
            text={bn ? "Add Files" : "অ্যাড ফাইল"}
            fileHandler={fileHandler}
          />
        )}

        {loading && <Loading />}

        {error.map((e) => {
          return <ErrorMsg text={e} />;
        })}
        {/* <br></br> */}
        {!loading && !done && (
          <PrimaryButton
            text={bn ? "Add Property" : `অ্যাড প্রপার্টি`}
            handleClick={addProp}
          ></PrimaryButton>
        )}

        {done && <SuccessAnimation text={"Property Added"} />}
      </div>
    </>
  );
}

export default AddProperty;
