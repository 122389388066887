import { useEffect, useState } from "react";
import PageTitle from "../../../components/PageTitle";
import { useNavigate } from "react-router-dom";
import {
  GetLatestEntryOfUnit,
  getFloor,
  getFloors,
  getProperties,
  getProperty,
  getUnits,
} from "../../../firebase";
import UnitsContainer from "../../../components/UnitsContainer";
import { Loading } from "../../../components/Loading";
import { TableHeader } from "../../../components/TableHeader";
import Table from "../../../components/Table";
import Dropdown from "../../../components/Dropdown";
import { TableItem } from "../../../components/TableItem";
import { DateInputWithLabel } from "../../../components/DateInputWithLabel";
import TextInputWithDropdown from "../../../components/TextInputWithDropdown";
import UnitTitle from "../../../components/UnitTitle";
import StatusItem from "../../../components/StatusItem";
import ErrorMsg from "../../../components/ErrorMsg";

const ServiceChargeRecieveable = () => {
  const navigate = useNavigate();
  const [propertyId, setPropertyId] = useState("allProperties");
  const [floorId, setFloorId] = useState("allFloors");
  const [units, setUnits] = useState([]);
  const [floors, setFloors] = useState([]);
  const [properties, setProperties] = useState([]);
  const [currentProperty, setCurrentProperty] = useState();
  const [currentFloor, setCurrentFloor] = useState();
  const [month, setMonth] = useState({
    "Start Date": getCurrentDateAndMonth(),
    "End Date": getCurrentDateAndMonth(),
  });
  const [loading, setLoading] = useState(true);
  const [searchData, setSearchData] = useState("");
  const [searchState, setSearchState] = useState("Block");
  const [grandTotal, setGrandTotal] = useState(0);

  function getCurrentDateAndMonth() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const monthString = month < 10 ? "0" + month : month;

    return `${year}-${monthString}`;
  }

  const fetchProperties = async () => {
    const docRef = await getProperties();
    const propertyData = [];
    docRef.forEach((d) => {
      propertyData.push({ text: d.PropertyName, value: d.id });
    });
    propertyData.push({ text: "All Properties", value: "allProperties" });
    setProperties(propertyData);
  };

  const fetchFloors = async (propertyId) => {
    const docRef = await getFloors(propertyId);
    console.log(docRef);
    const floorData = [];
    docRef.forEach((d) => {
      floorData.push({ text: d.FloorName, value: d.id });
    });
    floorData.push({ text: "All Floors", value: "allFloors" });
    setFloors(floorData);
  };

  const fetchSpecificFloorUnits = async (propertyId, floorId) => {
    let grand = 0;
    let [prop, floor, unitsArr] = await Promise.all([
      getProperty(propertyId),
      getFloor(propertyId, floorId),
      getUnits(propertyId, floorId),
    ]);

    unitsArr = unitsArr.filter((u) => u.currentTenantId != "N/A");
    const months = getMonthsInRange(month["Start Date"], month["End Date"]);

    await Promise.all(
      unitsArr.map(async (u) => {
        let total = 0;
        await Promise.all(
          // Add Promise.all here
          months.map(async (m) => {
            const latest = await GetLatestEntryOfUnit(
              propertyId,
              floorId,
              u.id,
              m ? m : getCurrentDateAndMonth(),
              `Service Charge`
            );

            if (latest[0]) {
              total += latest[0]["remainingAmount"];
            } else {
              if (m >= u.ssd && m >= u.ssd) {
                total +=
                  (parseInt(u.UnitSpace) + parseInt(u["CommonSpace(ifany)"])) *
                  u.ServiceChargeRate;
              }
            }
          })
        );
        if (!total) {
          total = 0;
        }
        console.log(total);
        u["total"] = total;
        grand += total;
        setGrandTotal(grand);
      })
    );
    const propertyArr = [
      {
        PropertyName: prop.PropertyName,
        id: prop.id,
        Floors: [
          {
            FloorName: floor.FloorName,
            id: floor.id,
            Units: unitsArr,
          },
        ],
      },
    ];

    setUnits(propertyArr);
    setLoading(false);
  };

  const fetchSpecificPropertyUnits = async (propertyId) => {
    let grand = 0;
    const [prop, floors] = await Promise.all([
      getProperty(propertyId),
      getFloors(propertyId),
    ]);

    const floorArrPromises = floors.map(async (f) => {
      let unitsArr = await getUnits(propertyId, f.id);

      unitsArr = unitsArr.filter((u) => u.currentTenantId != "N/A");
      const months = getMonthsInRange(month["Start Date"], month["End Date"]);

      await Promise.all(
        unitsArr.map(async (u) => {
          let total = 0;
          await Promise.all(
            // Add Promise.all here
            months.map(async (m) => {
              const latest = await GetLatestEntryOfUnit(
                propertyId,
                f.id,
                u.id,
                m ? m : getCurrentDateAndMonth(),
                `Service Charge`
              );

              if (latest[0]) {
                total += latest[0]["remainingAmount"];
              } else {
                if (m >= u.ssd && m >= u.ssd) {
                  total +=
                    (parseInt(u.UnitSpace) +
                      parseInt(u["CommonSpace(ifany)"])) *
                    u.ServiceChargeRate;
                }
              }
            })
          );
          if (!total) {
            total = 0;
          }
          console.log(total);
          u["total"] = total;
          grand += total;
          setGrandTotal(grand);
        })
      );

      console.log(unitsArr);
      return {
        FloorName: f.FloorName,
        id: f.id,
        Units: unitsArr,
      };
    });

    const floorsArr = await Promise.all(floorArrPromises);

    const propertyArr = [
      {
        PropertyName: prop.PropertyName,
        id: prop.id,
        Floors: floorsArr,
      },
    ];

    setUnits(propertyArr);
    setLoading(false);
  };

  useEffect(() => {
    setFloorId("allFloors");
  }, [propertyId]);

  function getMonthsInRange(startDate, endDate) {
    const start = new Date(startDate + "-01");
    const end = new Date(endDate + "-01");
    const months = [];

    while (start <= end) {
      const year = start.getFullYear();
      const month = (start.getMonth() + 1).toString().padStart(2, "0");
      months.push(`${year}-${month}`);

      start.setMonth(start.getMonth() + 1);
    }

    return months;
  }

  const fetchAllUnits = async () => {
    let grand = 0;
    const props = await getProperties();
    const propertyArrPromises = props.map(async (p) => {
      const floors = await getFloors(p.id);
      const floorArrPromises = floors.map(async (f) => {
        let unitsArr = await getUnits(p.id, f.id);

        unitsArr = unitsArr.filter((u) => u.currentTenantId != "N/A");
        const months = getMonthsInRange(month["Start Date"], month["End Date"]);

        console.log(months);
        await Promise.all(
          unitsArr.map(async (u) => {
            let total = 0;
            await Promise.all(
              // Add Promise.all here
              months.map(async (m) => {
                const latest = await GetLatestEntryOfUnit(
                  p.id,
                  f.id,
                  u.id,
                  m ? m : getCurrentDateAndMonth(),
                  `Service Charge`
                );

                if (latest[0]) {
                  total += latest[0]["remainingAmount"];
                } else {
                  if (m >= u.ssd && m >= u.ssd) {
                    total +=
                      (parseInt(u.UnitSpace) +
                        parseInt(u["CommonSpace(ifany)"])) *
                      u.ServiceChargeRate;
                  }
                }
              })
            );
            if (!total) {
              total = 0;
            }
            u["total"] = total;
            grand += total;
            setGrandTotal(grand);
          })
        );

        return {
          FloorName: f.FloorName,
          id: f.id,
          Units: unitsArr,
        };
      });
      const floorsArr = await Promise.all(floorArrPromises);
      return {
        PropertyName: p.PropertyName,
        id: p.id,
        Floors: floorsArr,
      };
    });

    const propertyArr = await Promise.all(propertyArrPromises);
    setUnits(propertyArr);
    setLoading(false);
  };

  function getPreviousMonth(dateString) {
    const date = new Date(dateString + "-01");
    date.setMonth(date.getMonth() - 1);

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const monthString = month < 10 ? "0" + month : month;

    return `${year}-${monthString}`;
  }

  const handleChangeProperty = async (id) => {
    setLoading(true);
    setPropertyId(id);
    console.log(properties);
  };

  const handleChangeFloor = async (id) => {
    setLoading(true);
    setFloorId(id);
  };

  const handleChange = (e, label) => {
    setLoading(true);
    let obj = {};
    obj[label] = e.target.value;
    setMonth((prev) => {
      return { ...prev, ...obj };
    });
  };

  const handleSearchChange = (e, label) => {
    setSearchData(e.target.value);
  };

  function formatTaka(amount) {
    const takaSymbol = "৳";
    const formattedAmount = new Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 2,
    }).format(amount);
    return `${takaSymbol}${formattedAmount}`;
  }

  useEffect(() => {
    if (propertyId == "allProperties" && floorId == "allFloors") {
      fetchAllUnits();
    } else if (floorId == "allFloors" && propertyId != "allProperties") {
      console.log("All floors of specific property");
      fetchSpecificPropertyUnits(propertyId);
    } else if (propertyId != "allProperties" && floorId != "allFloors") {
      fetchSpecificFloorUnits(propertyId, floorId);
    }

    setCurrentProperty(propertyId);
    setCurrentFloor(floorId);
    fetchProperties();
    fetchFloors(propertyId);
  }, [propertyId, floorId, month]);

  useEffect(() => {
    console.log(searchState);
  }, [searchState]);

  useEffect(() => {
    console.log(searchData);
  }, [searchData]);

  useEffect(() => {
    console.log(units);
  }, [units]);

  useEffect(() => {
    console.log(month);
  }, [month]);

  function findTextById(arr, id) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].value === id) {
        return arr[i].text;
      }
    }
    return null;
  }

  function convertDateFormat(dateStr) {
    // Create a date object from the string
    let date = new Date(dateStr);

    // Define the month names
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract the month and year from the date
    let month = monthNames[date.getUTCMonth()];
    let year = date.getUTCFullYear();

    // Return the formatted date
    return `${month}, ${year}`;
  }

  return (
    <>
      <PageTitle
        title="S/C Recieveables"
        subTitle={`of ${
          currentProperty == "allProperties"
            ? "All Properties"
            : findTextById(properties, currentProperty)
        }, ${
          currentFloor == "allFloors"
            ? "All Floors"
            : findTextById(floors, currentFloor)
        } of ${convertDateFormat(month["Start Date"])} to ${convertDateFormat(
          month["End Date"]
        )}`}
      />

      <div className="flex-wrap">
        {currentProperty && properties.length > 0 && (
          <Dropdown
            items={properties}
            label="Property"
            handleChange={handleChangeProperty}
            defaultVal={propertyId}
          />
        )}

        {currentFloor && floors.length > 0 && (
          <Dropdown
            items={floors}
            label="Floor"
            value={floorId}
            handleChange={handleChangeFloor}
            defaultVal={floorId}
          />
        )}
        <TextInputWithDropdown
          label="Search"
          name={true}
          placeholder="Search"
          setSearchState={setSearchState}
          handleChange={handleSearchChange}
        />
      </div>

      <div className="flex-wrap" style={{ marginTop: "0.5rem" }}>
        <DateInputWithLabel
          small={true}
          label="Start Date"
          placeholder={"Start Date"}
          handleChange={handleChange}
        />
        <DateInputWithLabel
          small={true}
          label="End Date"
          placeholder={"End Date"}
          handleChange={handleChange}
        />
      </div>
      <br></br>
      <div
        style={{
          color: "rgb(0, 136, 194)",
          fontWeight: "bold",
          fontSize: "1.5rem",
        }}
      >{`Total: ${formatTaka(grandTotal)}`}</div>
      <br></br>
      {month["Start Date"] > month["End Date"] && (
        <ErrorMsg text="Start Date cannot be greater than End Date" />
      )}
      <br></br>
      {units && (
        <Table>
          <TableHeader
            headings={[
              ["Unit Info", 7],
              ["Recieveable", 6],
              ["Paid Till", 6],
            ]}
          />
          {loading && <Loading />}

          {!loading &&
            units.map((p) => {
              if (p.Floors) {
                return p.Floors.map((f) => {
                  if (f.Units.length > 0) {
                    return (
                      <>
                        <UnitsContainer
                          Property={p.PropertyName}
                          Floor={f.FloorName}
                        >
                          {f.Units.map((u) => {
                            if (
                              (searchData != "" &&
                                searchState == "Block" &&
                                u.Block?.toUpperCase() ==
                                  searchData?.toUpperCase()) ||
                              (searchData != "" &&
                                searchState == "UnitNo" &&
                                u.UnitNo.toString().startsWith(
                                  searchData.toString()
                                )) ||
                              (searchData != "" &&
                                searchState == "UnitName" &&
                                u.currentBusinessName
                                  .toString()
                                  .toLowerCase()
                                  .startsWith(
                                    searchData.toString().toLowerCase()
                                  )) ||
                              searchData == ""
                            ) {
                              return (
                                <>
                                  <TableItem
                                    handleClick={() => {
                                      navigate(
                                        `/properties/${p.id}/floors/${f.id}/units/${u.id}`
                                      );
                                    }}
                                    rowData={[
                                      [
                                        <div>
                                          <UnitTitle
                                            UnitNo={u.UnitNo}
                                            UnitName={u.currentBusinessName}
                                            Block={u.Block}
                                          />
                                        </div>,
                                        7,
                                      ],
                                      [
                                        <>
                                          <div>{u.total ? u.total : "0"}</div>
                                        </>,
                                        6,
                                      ],
                                      [
                                        u.ssd != u.SCStartDate ? (
                                          <div>{`${convertDateFormat(
                                            getPreviousMonth(u.SCStartDate)
                                          )}`}</div>
                                        ) : (
                                          <div>N/A</div>
                                        ),
                                        6,
                                      ],
                                    ]}
                                  />
                                </>
                              );
                            }
                          })}
                          <br></br>
                        </UnitsContainer>
                      </>
                    );
                  }
                });
              }
            })}
        </Table>
      )}
    </>
  );
};

export default ServiceChargeRecieveable;
