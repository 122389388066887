import React, { useEffect, useState } from "react";
import { getAllRequests, getProperties } from "../../firebase";
import PageTitle from "../../components/PageTitle";
import { useNavigate } from "react-router-dom";
import { TableHeader } from "../../components/TableHeader";
import { TableItem } from "../../components/TableItem";
import PrimaryButton from "../../components/PrimaryButton";
import { Loading } from "../../components/Loading";
import TableEdit from "../../components/TableEdit";
import { UserAuth } from "../../AuthContext";
import Dropdown from "../../components/Dropdown";

function ViewAllRequests() {
  const [reqs, setReqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user, rolesStream, bn } = UserAuth();
  const [type, setType] = useState("Pending");
  const navigate = useNavigate();

  function hasMatchingParent(id, arrayOfObjects) {
    for (let i = 0; i < arrayOfObjects.length; i++) {
      if (arrayOfObjects[i].parent === id) {
        return true;
      }
    }
    return false;
  }

  const fetchRequests = async () => {
    const reqs = await getAllRequests();
    const filteredReqs = reqs.filter((r) => {
      if (
        (r.typeId == "otherComplain" &&
          rolesStream[user.Role][`RecieveOtherComplain`]) ||
        (r.typeId == "propertyComplain" &&
          rolesStream[user.Role][`RecievePropertyComplain`]) ||
        (r.typeId == "tenantComplain" &&
          rolesStream[user.Role][`RecieveTenantComplain`]) ||
        (r.typeId == "moveIn" &&
          rolesStream[user.Role][`RecieveMoveInApplication`]) ||
        (r.typeId == "moveOut" &&
          rolesStream[user.Role][`RecieveMoveOutApplication`]) ||
        (r.typeId == "changePositionTenant" &&
          rolesStream[user.Role][`RecieveChangePositionTenantApplication`]) ||
        (r.typeId == "changeSecondaryTenant" &&
          rolesStream[user.Role][`RecieveChangeSecondaryTenantApplication`]) ||
        r.RequestedBy == user.Name
      ) {
        return true;
      }
      return false;
    });
    setReqs(filteredReqs);
    setLoading(false);
  };

  const handleChangeType = async (id) => {
    setType(id);
  };

  useEffect(() => {
    fetchRequests();
  }, [rolesStream]);

  useEffect(() => {
    console.log(type);
  }, [type]);

  return (
    <>
      <PageTitle
        title={bn ? "Requests" : "প্রপার্টিজ "}
        subTitle={bn ? "All Requests" : `সব প্রপার্টি`}
      />

      <Dropdown
        items={[
          { text: "Pending", value: "Pending" },
          { text: "Approved", value: "Approved" },
          { text: "Declined", value: "Declined" },
          { text: "All", value: "All" },
        ]}
        handleChange={handleChangeType}
      />

      <br></br>

      <TableHeader
        headings={[
          [bn ? "Type" : `সম্পত্তির নাম`, 5],
          [bn ? "Business" : `তলা`, 5],
          [bn ? "Requested By" : `তলা`, 5],
          [bn ? "Recieved By" : `তলা`, 5],
          [bn ? "Status" : `তলা`, 3],
        ]}
      />
      {loading && <Loading />}
      {reqs.map((p) => {
        return (
          <div style={{ display: "flex" }} key={p.id}>
            {(type == "All" || type == p.status) && (
              <TableItem
                handleClick={() => {
                  navigate(`/requests/${p.typeId}/${p.id}`);
                }}
                rowData={[
                  [p.type, 5],
                  [p.BusinessName ? p.BusinessName : "N/A", 5],
                  [p.RequestedBy, 5],
                  [p.RecievedBy ? p.RecievedBy : "N/A", 5],
                  [p.status, 3],
                ]}
              />
            )}
          </div>
        );
      })}

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
}

export default ViewAllRequests;
