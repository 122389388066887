import React, { useEffect, useState } from "react";
import { UserAuth } from "../AuthContext";
import PageTitle from "../components/PageTitle";
import DropdownWithCheckboxes from "../components/DropdownWithCheckboxes";
import classes from "../styles/Dashboard.module.css";
import {
  GetEntries,
  GetEntriesOfMonth,
  GetEntryOfUnitOfMonth,
  GetLogs,
  getFloors,
  getPendingRequests,
  getProperties,
  getUnits,
} from "../firebase";
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJs,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

function Dashboard() {
  const { user, bn, rolesStream } = UserAuth();
  const [logs, setLogs] = useState([]);
  const [nRequests, setNRequests] = useState(0);
  const [totalRev, setTotalRev] = useState(0);
  const [lastMonthsRev, setLastMonthsRev] = useState([]);
  const [lastMonths, setLastMonths] = useState([]);
  // const [reqCount, setReqCount] = useState([]);
  const navigate = useNavigate();

  const fetchLogs = async () => {
    const logData = await GetLogs(5);
    setLogs(logData);
  };

  function getCurrentMonth() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // JavaScript months are 0-indexed

    if (month < 10) {
      return `${year}-0${month}`;
    } else {
      return `${year}-${month}`;
    }
  }

  function getLastFiveMonths() {
    let currentDate = new Date();

    let months = [];

    for (let i = 0; i < 5; i++) {
      let month = currentDate.getMonth() + 1 - i;
      let year = currentDate.getFullYear();

      if (month <= 0) {
        month += 12;
        year -= 1;
      }

      let formattedMonth = `${year}-${month.toString().padStart(2, "0")}`;
      months.unshift(formattedMonth);
    }

    setLastMonths(months);
  }

  function convertDateFormat(dateStr) {
    // Create a date object from the string
    let date = new Date(dateStr);

    // Define the month names
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract the month and year from the date
    let month = monthNames[date.getUTCMonth()];
    let year = date.getUTCFullYear();

    // Return the formatted date
    return `${month}, ${year}`;
  }

  function convertMonthsToNames(monthsArray) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return monthsArray.map((dateString) => {
      const monthIndex = parseInt(dateString.split("-")[1], 10) - 1;
      return monthNames[monthIndex];
    });
  }

  const fetchEntries = async (month) => {
    let total = 0;
    const properties = await getProperties();

    for (const p of properties) {
      const floors = await getFloors(p.id);
      for (const f of floors) {
        const units = await getUnits(p.id, f.id);
        for (const u of units) {
          const entry = await GetEntryOfUnitOfMonth(
            p.id,
            f.id,
            u.id,
            month,
            `Rent`
          );
          entry.forEach((e) => {
            total += e.AmountPaid;
            console.log(e.AmountPaid);
            // console.log(total);
          });
        }
      }
    }
    return parseInt(total);
  };

  const data = {
    labels: lastMonths
      ? [...convertMonthsToNames(lastMonths)]
      : ["Jan", "Feb", "Mar", "Apr", "May"],
    // labels: ["Jan", "Feb", "Mar", "Apr", "May"],
    datasets: [
      {
        label: "Total Revenue",
        data: lastMonthsRev ? [...lastMonthsRev] : ["0", "0", "0", "0", "0"],
        // data: ["0", "3", "1", "5", "0"],
        backgroundColor: "#0088C2",
        borderColor: "black",
        barPercentage: 0.7,
        categoryPercentage: 0.8,
        borderRadius: 7,
      },
    ],
  };

  function firebaseTimestampToReadableTime(timestamp) {
    const date = timestamp.toDate();
    const dateOptions = {
      day: "2-digit",
      month: "long",
      year: "numeric",
    };

    const timeOptions = {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    const formattedDate = new Intl.DateTimeFormat("en-US", dateOptions).format(
      date
    );
    const formattedTime = new Intl.DateTimeFormat("en-US", timeOptions).format(
      date
    );

    return `${formattedDate}, ${formattedTime}`;
  }

  function formatTaka(amount) {
    const takaSymbol = "৳";
    const formattedAmount = new Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 2,
    }).format(amount);
    return `${takaSymbol}${formattedAmount}`;
  }

  const getCurrentMonthTotal = async () => {
    const x = await GetEntriesOfMonth(`Rent`, getCurrentMonth());
    let total = 0;
    x.map((y) => {
      total += y.AmountPaid;
    });
    console.log(total);
    setTotalRev(total);
  };

  const fetchRequests = async () => {
    const reqs = await getPendingRequests();
    const filteredReqs = reqs.filter((r) => {
      if (
        (r.typeId == "otherComplain" &&
          rolesStream[user.Role][`RecieveOtherComplain`]) ||
        (r.typeId == "propertyComplain" &&
          rolesStream[user.Role][`RecievePropertyComplain`]) ||
        (r.typeId == "tenantComplain" &&
          rolesStream[user.Role][`RecieveTenantComplain`]) ||
        (r.typeId == "moveIn" &&
          rolesStream[user.Role][`RecieveMoveInApplication`]) ||
        (r.typeId == "moveOut" &&
          rolesStream[user.Role][`RecieveMoveOutApplication`]) ||
        (r.typeId == "changePositionTenant" &&
          rolesStream[user.Role][`RecieveChangePositionTenantApplication`]) ||
        (r.typeId == "changeSecondaryTenant" &&
          rolesStream[user.Role][`RecieveChangeSecondaryTenantApplication`])
      ) {
        return true;
      }
      return false;
    });

    setNRequests(filteredReqs.length);
  };

  const getRefData = async () => {
    try {
      let allMonthData = await Promise.all(
        lastMonths.map(async (l) => {
          let total = 0;
          const month = await GetEntriesOfMonth(`Rent`, l);
          month.map((m) => {
            total += m.AmountPaid;
          });
          console.log(month);
          return total;
        })
      );
      setLastMonthsRev(allMonthData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchLogs();
    getLastFiveMonths();
    getCurrentMonthTotal();
  }, []);

  useEffect(() => {
    getRefData();
  }, [lastMonths]);

  useEffect(() => {
    console.log(lastMonthsRev);
  }, [lastMonthsRev]);

  useEffect(() => {
    fetchRequests();
  }, [rolesStream, user]);

  return (
    <>
      <PageTitle
        title={bn ? "Dashboard" : `ডেশবোর্ড`}
        subTitle={`${bn ? `Welcome` : `স্বাগতম`}, ${user.Name}`}
        noBack={true}
      />
      <div className={classes.dashboardContainer}>
        <div className={classes.dashboardSubContainer}>
          {rolesStream &&
            user &&
            rolesStream[user.Role].Rent &&
            rolesStream[user.Role].Utility &&
            rolesStream[user.Role].ServiceCharge &&
            rolesStream[user.Role].RentCollected &&
            rolesStream[user.Role].UtilityCollected &&
            rolesStream[user.Role].ServiceChargeCollected && (
              <div className={classes.statistics}>
                <p>
                  {bn
                    ? `Total Revenue (${convertDateFormat(getCurrentMonth())})`
                    : `মোট রেভেনিউ`}
                </p>
                <p className={classes.totalRevenue}>{formatTaka(totalRev)}</p>
                <div className={classes.chartWrapper}>
                  <Bar
                    className={classes.bar}
                    data={data}
                    options={{
                      responsive: true,

                      maintainAspectRatio: false,
                      padding: 0,
                      margin: 0,
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                      scales: {
                        y: {
                          display: false,
                          grid: {
                            display: false,
                          },
                        },
                        x: {
                          grid: {
                            display: false,
                          },
                        },
                      },
                    }}
                  ></Bar>
                </div>

                <button
                  className={`${classes.bottomRightButton} ${classes.dashButton}`}
                  onClick={() => navigate("/statistics")}
                >
                  {bn ? `Show Statistics` : `স্টাতিস্তিক্স`}
                </button>
              </div>
            )}
          {rolesStream &&
            user &&
            (rolesStream[user.Role].RecieveMoveInApplication ||
              rolesStream[user.Role].RecieveMoveOutApplication ||
              rolesStream[user.Role].RecieveTenantComplain ||
              rolesStream[user.Role].RecievePropertyComplain ||
              rolesStream[user.Role].RecieveOtherComplain ||
              rolesStream[user.Role].RecieveChangePositionTenantApplication ||
              rolesStream[user.Role]
                .RecieveChangeSecondaryTenantApplication) && (
              <div className={classes.notifications}>
                <p>{bn ? `Requests` : `রিকোয়েস্ট`}</p>
                <p className={classes.nRequests}>{nRequests}</p>
                <p>{bn ? `New Requests` : `নতুন রিকোয়েস্ট`}</p>
                <button
                  onClick={() => navigate(`/requests/all`)}
                  className={`${classes.bottomLeftButton} ${classes.dashButton}`}
                >
                  {bn ? `Past Requests` : `অতীতের রিকোয়েস্ট`}
                </button>
              </div>
            )}
        </div>
        {rolesStream && user && rolesStream[user.Role].ActivityLog && (
          <div className={classes.activityLog}>
            <p>{bn ? `Activity Log` : `একটিভিটি লগ`}</p>
            <div className={classes.activityLogList}>
              {logs.map((l) => {
                return (
                  <div>
                    <span>{firebaseTimestampToReadableTime(l.time)}</span>:{" "}
                    {l.text}
                  </div>
                );
              })}
            </div>

            <button
              className={`${classes.bottomLeftButton} ${classes.dashButton}`}
              onClick={() => {
                navigate("/logs");
              }}
            >
              {bn ? `View all` : `শব দেখুন`}
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default Dashboard;
