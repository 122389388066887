const StatusItem = ({ status }) => {
  return (
    <>
      <div>
        {
          <div className="flex-row" style={{ alignItems: "center" }}>
            <div
              style={{
                width: "0.5rem",
                height: "0.5rem",
                borderRadius: "1rem",
                backgroundColor: `${
                  status == "Full"
                    ? "green"
                    : status == "Not Paid"
                    ? "red"
                    : status == "Partial"
                    ? "orange"
                    : ""
                }`,
                margin: "0 0.3rem 0 0.3rem",
              }}
            ></div>
            {status}
          </div>
        }
      </div>
    </>
  );
};

export default StatusItem;
