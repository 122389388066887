import { UserAuth } from "../AuthContext";
import classes from "../styles/CheckboxInputWithLabel.module.css";

export const CheckboxInputWithLabel = ({
  label,
  label_bn,
  placeholder,
  handleChange,
  notEditable,
  value,
}) => {
  const { bn } = UserAuth();
  return (
    <>
      <div className={`${classes.inputContainer} flex-row`}>
        <label>
          {bn ? label : label_bn}
          <span>
            {value != undefined && notEditable
              ? `: ${value == true ? "" : "N/A"}`
              : null}
          </span>
        </label>
        {!notEditable && placeholder && (
          <input
            type="checkbox"
            defaultChecked={value}
            // placeholder={placeholder}
            onChange={(e) => handleChange(e, label)}
          ></input>
        )}
      </div>
    </>
  );
};
