import {
  EventNote,
  LocationCity,
  LocationOn,
  People,
  PeopleOutline,
} from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../../components/PageTitle";
import { TextInputWithLabel } from "../../components/TextInputWithLabel";
import { CheckboxInputWithLabel } from "../../components/CheckboxInputWithLabel";
import FieldSection from "../../components/FieldSection";
import FieldContainer from "../../components/FieldContainer";
import {
  AddLog,
  UploadFile,
  UploadImage,
  addRequest,
  addTenant,
  addUnit,
  getFloor,
  getFloorName,
  getProperty,
  getPropertyName,
  getTenant,
  getUnit,
  updateUnit,
} from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { DateInputWithLabel } from "../../components/DateInputWithLabel";
import PrimaryButton from "../../components/PrimaryButton";
import UnitSelector from "../../components/UnitSelector";
import Dropdown from "../../components/Dropdown";
import DropdownWithLabel from "../../components/DropdownWithLabel";
import UploadButton from "../../components/UploadButton";
import DeleteButton from "../../components/DeleteButton";
import BlueItem from "../../components/BlueItem";
import { UserAuth } from "../../AuthContext";
import { serverTimestamp } from "firebase/firestore";
import { Loading } from "../../components/Loading";
import SuccessAnimation from "../../components/SuccessAnimation";
import ErrorMsg from "../../components/ErrorMsg";

function MoveOut() {
  const [loading, setLoading] = useState();
  const [done, setDone] = useState();
  const [error, setError] = useState([]);
  const navigate = useNavigate();
  const { user } = UserAuth();
  const [data, setData] = useState();
  const [currentFloor, setCurrentFloor] = useState();
  const [currentProperty, setCurrentProperty] = useState();
  const [currentFloorName, setCurrentFloorName] = useState();
  const [currentPropertyName, setCurrentPropertyName] = useState();
  const [currentUnit, setCurrentUnit] = useState();
  const [leaseEnding, setLeaseEnding] = useState("");
  const { propertyId, floorId } = useParams();
  const [rent, setRent] = useState();
  const [utility, setUtility] = useState();
  const [sc, setSc] = useState();
  const [unitData, setUnitData] = useState();
  const [tenantData, setTenantData] = useState({ TenantType: "Primary" });
  const [fieldData] = useState([
    [
      {
        fieldTitle: "Location",
        fieldIcon: <LocationOn />,
        fieldItems: [
          {
            label: "Property",
            type: "text",
          },
          {
            label: "Floor",
            type: "text",
          },
          {
            label: "Block",
            type: "text",
            origin: "unit",
          },
          {
            label: "Unit No",
            type: "text",
            origin: "unit",
          },
        ],
      },
    ],
    [
      {
        fieldTitle: "Tenant Details",
        fieldIcon: <People />,
        fieldItems: [
          {
            label: "Tenant Type",
            type: "dropdown",
            items: [
              { Value: "Primary", Name: "Primary" },
              { Value: "Position", Name: "Position" },
            ],
            origin: "tenant",
          },
          {
            label: "Business Name",
            placeholder: "Enter Business Name",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Tenant Name",
            placeholder: "Enter Tenant Name",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Lease Ending",
            placeholder: "Enter Lease Ending",
            force: "Current Lease Ending",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Lease Ending",
            placeholder: "Enter Lease Ending",
            type: "date",
            origin: "tenant",
          },
        ],
      },
    ],
  ]);
  const [imageFile, setImageFile] = useState();
  const [imageFileURL, setImageFileURL] = useState();
  const [files, setFiles] = useState([]);

  const fileHandler = async (event) => {
    const newFile = event.target.files[0]; // Get only the first file
    setImageFile(newFile);

    const reader = new FileReader();

    reader.onload = function (e) {
      setImageFileURL(e.target.result);
    };

    reader.readAsDataURL(newFile);
  };

  const handleClick = async () => {
    setLoading(true);
    if (leaseEnding == "") {
      setLoading(false);
      setError([`Lease Ending must be selected`]);
      return;
    }
    await addRequest({
      mainData: {
        leaseEnding: leaseEnding,
      },
      location: {
        currentProperty: currentProperty,
        currentFloor: currentFloor,
        currentUnit: currentUnit,
      },
      type: "Move Out",
      BusinessName: tenantData["BusinessName"],
      typeId: "moveOut",
      RequestedBy: user["Name"],
      time: serverTimestamp(),
      status: "Pending",
    });
    await AddLog(
      `${user.Name} has requested a Move Out of: ${tenantData.BusinessName}`
    );
    setLoading(false);
    setDone(true);
    setTimeout(() => {
      navigate("/home");
    }, [3000]);
  };

  const handleData = (e, label) => {
    const obj = {};
    obj[label.replace(/\s+/g, "")] = e.target.value;
    setData((prev) => {
      return { ...prev, ...obj };
    });
  };

  const handleTenantData = (e, label) => {
    const obj = {};
    obj[label.replace(/\s+/g, "")] = e.target.value;
    setTenantData((prev) => {
      return { ...prev, ...obj };
    });
  };

  const handleLeaseEnding = (e, label) => {
    setLeaseEnding(e.target.value);
  };

  const fetchUnitData = async () => {
    const unit = await getUnit(currentProperty, currentFloor, currentUnit);
    const prop = await getPropertyName(currentProperty);
    const floor = await getFloorName(currentProperty, currentFloor);

    const tenant = await getTenant(
      currentProperty,
      currentFloor,
      currentUnit,
      unit.currentTenantId
    );

    setTenantData(tenant);

    setCurrentPropertyName(prop);
    setCurrentFloorName(floor);

    setUnitData(unit);
    if (unit["Rent"] == true) {
      setRent(true);
    }
    if (unit["Utility"] == true) {
      setUtility(true);
    }
    if (unit["ServiceCharge"] == true) {
      setSc(true);
    }
  };

  useEffect(() => {
    fetchUnitData();
  }, [currentUnit]);

  useEffect(() => {
    console.log(tenantData);
  }, [tenantData]);

  const handleChange = async (e, label) => {
    // console.log(label);
    if (label == "Rent") {
      setRent(e.target.checked);
      let obj = {};
      obj["Rent"] = e.target.checked;
      setData((prev) => {
        return { ...prev, ...obj };
      });
    } else if (label == "Utility") {
      setUtility(e.target.checked);
      let obj = {};
      obj["Utility"] = e.target.checked;
      setData((prev) => {
        return { ...prev, ...obj };
      });
    } else if (label == "Service Charge") {
      setSc(e.target.checked);
      let obj = {};
      obj["ServiceCharge"] = e.target.checked;
      setData((prev) => {
        return { ...prev, ...obj };
      });
    }
  };

  useEffect(() => {
    console.log(rent);
  }, [rent]);

  useEffect(() => {
    console.log(imageFile);
  }, [imageFile]);

  const fileHandler2 = async (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles([...files, ...newFiles]);
  };

  const handleDeleteFile = async (index) => {
    setFiles((prev) => {
      delete prev[index];
      return [...prev];
    });
  };

  return (
    <>
      <PageTitle title="Move Out" image={imageFileURL || ""} />
      {!currentUnit && (
        <UnitSelector
          setCurrentProperty={setCurrentProperty}
          setCurrentFloor={setCurrentFloor}
          setCurrentUnit={setCurrentUnit}
          onlyPrimary={true}
        />
      )}

      {unitData &&
        currentUnit &&
        fieldData.map((f) => {
          return (
            <FieldContainer>
              {f.map((x) => {
                return (
                  <FieldSection>
                    <div
                      className="flex-row color-primary"
                      style={{ marginBottom: "0.5rem" }}
                    >
                      {x.fieldIcon}
                      <label>{x.fieldTitle}</label>
                    </div>
                    {x["fieldItems"].map((y) => {
                      if (
                        !y.activation ||
                        (y.activation == "Rent" && rent) ||
                        (y.activation == "Utility" && utility) ||
                        (y.activation == "SC" && sc) ||
                        (y.activation == "Position" &&
                          tenantData["TenantType"] == "Primary")
                      ) {
                        if (y.type == "text") {
                          return (
                            <TextInputWithLabel
                              label={y.label}
                              force={y.force}
                              notEditable={true}
                              value={
                                y.label == "Property"
                                  ? currentPropertyName
                                  : y.label == "Floor"
                                  ? currentFloorName
                                  : y.origin == "unit"
                                  ? unitData[y.label.replace(/\s+/g, "")]
                                  : y.origin == "tenant"
                                  ? tenantData[y.label.replace(/\s+/g, "")]
                                  : ""
                              }
                              placeholder={y.placeholder}
                              handleChange={
                                y.origin == "unit"
                                  ? handleData
                                  : y.origin == "tenant"
                                  ? handleTenantData
                                  : null
                              }
                            />
                          );
                        } else if (y.type == "checkbox") {
                          return (
                            <CheckboxInputWithLabel
                              label={y.label}
                              handleChange={handleChange}
                              notEditable={true}
                              placeholder={"bleh"}
                              value={
                                y.label == "Rent" && rent == true
                                  ? rent
                                  : y.label == "Utility" && utility == true
                                  ? utility
                                  : y.label == "ServiceCharge" && sc == true
                                  ? sc
                                  : false
                              }
                            />
                          );
                        } else if (y.type == "date") {
                          return (
                            <DateInputWithLabel
                              label={y.label}
                              notEditable={false}
                              value={
                                y.label == "Property"
                                  ? currentProperty
                                  : y.label == "Floor"
                                  ? currentFloor
                                  : ""
                              }
                              placeholder={y.placeholder}
                              handleChange={handleLeaseEnding}
                            />
                          );
                        } else if (y.type == "dropdown") {
                          return (
                            <DropdownWithLabel
                              label={y.label}
                              items={y.items}
                              defaultVal={y.items[0]}
                              handleChange={handleTenantData}
                              notEditable={true}
                            />
                          );
                        }
                      }
                    })}
                  </FieldSection>
                );
              })}
            </FieldContainer>
          );
        })}
      <br></br>

      {imageFile && (
        <div style={{ marginBottom: "1rem" }}>{imageFile.name}</div>
      )}
      {/* {currentUnit && (
        <UploadButton
          text={`${imageFile ? "Change Image" : "Add Image"}`}
          single={true}
          fileHandler={fileHandler}
          imageOnly={true}
        />
      )} */}
      {files.map((f, index) => {
        return (
          <div className="flex-row">
            {f && (
              <>
                <BlueItem text={f.name} />
                <DeleteButton name={index} handleDelete={handleDeleteFile} />
                <br></br>
              </>
            )}
          </div>
        );
      })}
      {/* {files.length > 0 && <br></br>} */}
      {/* {currentUnit && (
        <UploadButton
          text={"Add Files"}
          fileHandler={fileHandler2}
          imageOnly={false}
        />
      )}

       */}

      <br></br>
      {error.map((e) => {
        return <ErrorMsg text={e} />;
      })}
      <br></br>
      {!loading && !done && currentUnit && (
        <PrimaryButton text="Send Request" handleClick={() => handleClick()} />
      )}
      {loading && <Loading />}
      {done && <SuccessAnimation text="Request Sent" />}

      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
}

export default MoveOut;
