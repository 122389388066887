import { Delete } from "@mui/icons-material";

const DeleteButton = ({ handleDelete, name }) => {
  return (
    <div
      style={{
        color: "#008cc9",
        margin: "0.2rem 0.5rem 0.2rem 0.5rem",
      }}
    >
      <div onClick={() => handleDelete(name)}>
        <Delete />
      </div>
    </div>
  );
};

export default DeleteButton;
