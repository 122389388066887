import React, { useEffect, useState } from "react";
import classes from "../styles/DropdownWithCheckboxes.module.css";

const DropdownWithCheckboxes = ({
  items,
  mainLabel,
  handleChange,
  defaultValues,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [expandedItems, setExpandedItems] = useState([]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const updateParentState = (defaultValues) => {
    const reversedValues = reverseFormatOutput(defaultValues);
    const newSelectedItems = [...reversedValues];

    // Loop through the items and check if any child is selected, then add the parent to the selected items
    items.forEach((item) => {
      if (item.children) {
        const isAnyChildSelected = item.children.some((child) =>
          reversedValues.includes(child.value)
        );

        if (isAnyChildSelected && !newSelectedItems.includes(item.value)) {
          newSelectedItems.push(item.value);
        }
      }
    });

    setSelectedItems(newSelectedItems);
  };

  const handleCheckboxChange = (childItem) => {
    let newSelectedItems;
    if (selectedItems.includes(childItem.value)) {
      newSelectedItems = selectedItems.filter((val) => val !== childItem.value);
    } else {
      newSelectedItems = [...selectedItems, childItem.value];
    }

    // Find the parent item
    const parentItem = items.find(
      (item) => item.children && item.children.includes(childItem)
    );

    if (parentItem) {
      const isAnyChildSelected = parentItem.children.some((child) =>
        newSelectedItems.includes(child.value)
      );

      if (isAnyChildSelected && !newSelectedItems.includes(parentItem.value)) {
        newSelectedItems.push(parentItem.value);
      } else if (
        !isAnyChildSelected &&
        newSelectedItems.includes(parentItem.value)
      ) {
        newSelectedItems = newSelectedItems.filter(
          (val) => val !== parentItem.value
        );
      }
    }
    setSelectedItems(newSelectedItems);
  };

  const formatOutput = () => {
    return selectedItems
      .filter((selectedItem) =>
        items.every((item) => item.value !== selectedItem)
      )
      .map((selectedItem) => {
        const parentItem = items.find((item) =>
          item.children?.some((child) => child.value === selectedItem)
        );
        return { floor: selectedItem, parent: parentItem?.value };
      });
  };

  useEffect(() => {
    if (selectedItems.length != 0) {
      handleChange(formatOutput());
    }
  }, [selectedItems]);

  const handleParentCheckboxChange = (parentItem) => {
    if (selectedItems.includes(parentItem.value)) {
      setSelectedItems(
        selectedItems.filter(
          (val) =>
            ![
              parentItem.value,
              ...parentItem.children.map((child) => child.value),
            ].includes(val)
        )
      );
    } else {
      setSelectedItems([
        ...selectedItems,
        parentItem.value,
        ...parentItem.children.map((child) => child.value),
      ]);
    }
  };

  const toggleSubDropdown = (item) => {
    if (expandedItems.includes(item.value)) {
      setExpandedItems(expandedItems.filter((val) => val !== item.value));
    } else {
      setExpandedItems([...expandedItems, item.value]);
    }
  };

  const handleParentLabelClick = (e, item) => {
    e.stopPropagation(); // Prevent the click event from propagating to the parent div
    toggleSubDropdown(item);
  };

  const renderSubItems = (parentItem) => (
    <>
      {parentItem.children.map((child, index) => (
        <label key={index} className={classes.subdropdownitem}>
          <input
            type="checkbox"
            value={child.value}
            checked={selectedItems.includes(child.value)}
            onChange={(e) => {
              e.stopPropagation();
              handleCheckboxChange(child);
            }}
          />
          {child.label}
        </label>
      ))}
    </>
  );

  const reverseFormatOutput = (inputArray) => {
    return inputArray.flatMap((item) => {
      const parentItem = items.find((parent) => parent.value === item.parent);
      if (!parentItem || !parentItem.children) {
        return [];
      }
      const selectedItem = parentItem.children.find(
        (child) => child.value === item.floor
      );
      return selectedItem ? [selectedItem.value] : [];
    });
  };

  useEffect(() => {
    if (defaultValues != undefined) {
      setSelectedItems(reverseFormatOutput(defaultValues));
    }
    if (defaultValues != undefined && items.length > 0) {
      updateParentState(defaultValues);
    }
  }, [defaultValues, items]);

  return (
    <>
      <label className={classes.mainLabel}>{mainLabel}</label>
      <div className={classes.dropdownwrapper}>
        <button className={classes.dropdownbutton} onClick={toggleDropdown}>
          Select Items
        </button>
        {isOpen && (
          <div className={classes.dropdownmenu}>
            {items.map((item, index) => (
              <div key={index}>
                <div className={classes.dropdownitem}>
                  <input
                    type="checkbox"
                    value={item.value}
                    checked={selectedItems.includes(item.value)}
                    onClick={(e) => e.stopPropagation()}
                    onChange={() => handleParentCheckboxChange(item)}
                  />
                  <span onClick={(e) => handleParentLabelClick(e, item)}>
                    {item.label}
                  </span>
                </div>
                {item.children &&
                  expandedItems.includes(item.value) &&
                  renderSubItems(item)}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default DropdownWithCheckboxes;
