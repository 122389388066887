import classes from "../styles/UnitTitle.module.css";

const UnitTitle = ({ UnitNo, Block, UnitName }) => {
  return (
    <div className={classes.unitTitleHolder}>
      <div className="flex-column">
        <div className="flex-row">
          <p>{`#${UnitNo}, Block: ${Block}`}</p>
        </div>

        <p style={{ fontWeight: "bold", fontSize: "0.9rem" }}>{UnitName}</p>
      </div>
    </div>
  );
};

export default UnitTitle;
