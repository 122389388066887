import {
  EventNote,
  LocationCity,
  LocationOn,
  People,
  PeopleOutline,
} from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../../components/PageTitle";
import { TextInputWithLabel } from "../../components/TextInputWithLabel";
import { CheckboxInputWithLabel } from "../../components/CheckboxInputWithLabel";
import FieldSection from "../../components/FieldSection";
import FieldContainer from "../../components/FieldContainer";
import {
  AddLog,
  UploadFile,
  UploadImage,
  addRequest,
  addTenant,
  addUnit,
  getFloor,
  getFloorName,
  getProperty,
  getPropertyName,
  getUnit,
  updateUnit,
} from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { DateInputWithLabel } from "../../components/DateInputWithLabel";
import PrimaryButton from "../../components/PrimaryButton";
import UnitSelector from "../../components/UnitSelector";
import Dropdown from "../../components/Dropdown";
import DropdownWithLabel from "../../components/DropdownWithLabel";
import UploadButton from "../../components/UploadButton";
import DeleteButton from "../../components/DeleteButton";
import BlueItem from "../../components/BlueItem";
import { UserAuth } from "../../AuthContext";
import { serverTimestamp } from "firebase/firestore";
import { Loading } from "../../components/Loading";
import SuccessAnimation from "../../components/SuccessAnimation";
import ErrorMsg from "../../components/ErrorMsg";

function MoveIn() {
  const [loading, setLoading] = useState();
  const [done, setDone] = useState();
  const { user } = UserAuth();
  const [data, setData] = useState();
  const [currentFloor, setCurrentFloor] = useState();
  const [error, setError] = useState([]);
  const [currentProperty, setCurrentProperty] = useState();
  const [currentFloorName, setCurrentFloorName] = useState();
  const [currentPropertyName, setCurrentPropertyName] = useState();
  const [currentUnit, setCurrentUnit] = useState();
  const [secondary, setSecondary] = useState();
  const { propertyId, floorId } = useParams();
  const navigate = useNavigate();
  const [rent, setRent] = useState();
  const [utility, setUtility] = useState();
  const [sc, setSc] = useState();
  const [unitData, setUnitData] = useState();
  const [tenantData, setTenantData] = useState({ TenantType: "Primary" });
  const [fieldData] = useState([
    [
      {
        fieldTitle: "Location",
        fieldIcon: <LocationOn />,
        fieldItems: [
          {
            label: "Property",
            type: "text",
          },
          {
            label: "Floor",
            type: "text",
          },
          {
            label: "Block",
            type: "text",
            origin: "unit",
          },
          {
            label: "Unit No",
            type: "text",
            origin: "unit",
          },
          {
            label: "Unit Space",
            type: "text",
            origin: "unit",
          },
        ],
      },
      // {
      //   fieldTitle: "Entry Info",
      //   fieldIcon: <EventNote />,
      //   fieldItems: [
      //     {
      //       label: "Rent",
      //       type: "checkbox",
      //       origin: "unit",
      //     },
      //     {
      //       label: "Rent Start Date",
      //       placeholder: "Enter Rent Start Date",
      //       type: "date",
      //       activation: "Rent",
      //       origin: "unit",
      //     },
      //     {
      //       label: "Unit rate per sq",
      //       placeholder: "Enter Unit rate per sq",
      //       type: "text",
      //       activation: "Rent",
      //       origin: "unit",
      //     },
      //     {
      //       label: "Utility",
      //       type: "checkbox",
      //       origin: "unit",
      //     },
      //     {
      //       label: "Utility Start Date",
      //       placeholder: "Enter Utility Start Date",
      //       type: "date",
      //       activation: "Utility",
      //       origin: "unit",
      //     },
      //     {
      //       label: "Utility Rate",
      //       placeholder: "Enter Utility Rate",
      //       type: "text",
      //       activation: "Utility",
      //       origin: "unit",
      //     },
      //     {
      //       label: "Previous Usage",
      //       placeholder: "Enter Previous Usage",
      //       type: "text",
      //       activation: "Utility",
      //       origin: "unit",
      //     },
      //     {
      //       label: "Service Charge",
      //       type: "checkbox",
      //       origin: "unit",
      //     },
      //     {
      //       label: "SC Start Date",
      //       placeholder: "Enter SC Start Date",
      //       type: "date",
      //       activation: "SC",
      //       origin: "unit",
      //     },
      //     {
      //       label: "Common Space (if any)",
      //       placeholder: "Enter Common Space",
      //       type: "text",
      //       activation: "SC",
      //       origin: "unit",
      //     },
      //     {
      //       label: "Service Charge Rate",
      //       placeholder: "Enter Service Charge Rate",
      //       type: "text",
      //       activation: "SC",
      //       origin: "unit",
      //     },
      //   ],
      // },
    ],
    [
      {
        fieldTitle: "Tenant Details",
        fieldIcon: <People />,
        fieldItems: [
          {
            label: "Tenant Type",
            type: "dropdown",
            items: [
              { Value: "Primary", Name: "Primary" },
              { Value: "Position", Name: "Position" },
            ],
            origin: "tenant",
          },
          {
            label: "Business Name",
            placeholder: "Enter Business Name",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Tenant Name",
            placeholder: "Enter Tenant Name",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Father's Name",
            placeholder: "Enter Father's Name",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Home Address",
            placeholder: "Enter Home Address",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Mobile Number",
            placeholder: "Enter Mobile Number",
            type: "text",
            origin: "tenant",
          },
          {
            label: "National ID",
            placeholder: "Enter National ID",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Passport Number",
            placeholder: "Enter Passport Number",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Manager Name",
            placeholder: "Enter Manager Name",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Manager Mobile",
            placeholder: "Enter Manager Mobile",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Lease Starting",
            placeholder: "Enter Lease Starting",
            type: "date",
            origin: "tenant",
            activation: "Position",
          },
          {
            label: "Lease Ending",
            placeholder: "Enter Lease Ending",
            type: "date",
            origin: "tenant",
            activation: "Position",
          },
          {
            label: "Security Deposit",
            placeholder: "Enter Security Deposit",
            type: "text",
            origin: "tenant",
            activation: "Position",
          },
        ],
      },
      {
        fieldTitle: "Secondary Tenant Details",
        fieldTitle_bn: "সেগেন্ডারি টেন্যান্ট",
        fieldIcon: <PeopleOutline />,
        fieldItems: [
          {
            label: "Secondary Tenant",
            label_bn: "গৌণ ভাড়াটিয়া",
            type: "checkbox",
            origin: "tenant",
          },
          {
            label: "Secondary Tenant Name",
            label_bn: "গৌণ ভাড়াটিয়ার নাম",
            placeholder: "Enter Tenant Name",
            placeholder_bn: "ভাড়াটিয়ার নাম প্রবেশ করান",
            type: "text",
            origin: "tenant",
            activation: "Secondary",
          },
          {
            label: "Secondary Tenant's Father's Name",
            label_bn: "গৌণ ভাড়াটিয়ার পিতার নাম",
            placeholder: "Enter Father's Name",
            placeholder_bn: "পিতার নাম প্রবেশ করান",
            type: "text",
            origin: "tenant",
            activation: "Secondary",
          },
          {
            label: "Secondary Tenant's Home Address",
            label_bn: "গৌণ ভাড়াটিয়ার বাড়ির ঠিকানা",
            placeholder: "Enter Home Address",
            placeholder_bn: "বাড়ির ঠিকানা প্রবেশ করান",
            type: "text",
            origin: "tenant",
            activation: "Secondary",
          },
          {
            label: "Secondary Tenant's Mobile Number",
            label_bn: "গৌণ ভাড়াটিয়ার মোবাইল নম্বর",
            placeholder: "Enter Mobile Number",
            placeholder_bn: "মোবাইল নম্বর প্রবেশ করান",
            type: "text",
            origin: "tenant",
            activation: "Secondary",
          },
          {
            label: "Secondary Tenant's National ID",
            label_bn: "গৌণ ভাড়াটিয়ার জাতীয় আইডি",
            placeholder: "Enter National ID",
            placeholder_bn: "জাতীয় আইডি প্রবেশ করান",
            type: "text",
            origin: "tenant",
            activation: "Secondary",
          },
          {
            label: "Secondary Tenant's Passport Number",
            label_bn: "গৌণ ভাড়াটিয়ার পাসপোর্ট নম্বর",
            placeholder: "Enter Passport Number",
            placeholder_bn: "পাসপোর্ট নম্বর প্রবেশ করান",
            type: "text",
            origin: "tenant",
            activation: "Secondary",
          },
          {
            label: "Secondary Tenant Rent",
            label_bn: "গৌণ ভাড়াটিয়ার ভাড়া",
            placeholder: "Enter Secondary Tenant Rent",
            placeholder_bn: "গৌণ ভাড়াটিয়ার ভাড়া প্রবেশ করান",
            type: "text",
            origin: "tenant",
            activation: "Secondary",
          },
        ],
      },
    ],
    [
      {
        fieldTitle: "Emergency Contact",
        fieldIcon: <PeopleOutline />,
        fieldItems: [
          {
            label: "Emergency Contact Name",
            placeholder: "Emergency Contact Name",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Emergency Contact Relation",
            placeholder: "Enter Emergency Contact Relation",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Emergency Contact Address",
            placeholder: "Enter Emergency Contact Address",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Emergency Contact Mobile",
            placeholder: "Enter Emergency Contact Mobile",
            type: "text",
            origin: "tenant",
          },
        ],
      },
      {
        fieldTitle: "Nominee",
        activation: "Position",
        // fieldIcon: <PeopleOutline />,
        fieldItems: [
          {
            label: "Nominee Name",
            placeholder: "Nominee Name",
            type: "text",
            origin: "tenant",
            activation: "Primary",
          },
          {
            label: "Nominee Relation",
            placeholder: "Nominee Relation",
            type: "text",
            origin: "tenant",
            activation: "Primary",
          },
          {
            label: "Nominee Mobile",
            placeholder: "Enter Nominee Mobile",
            type: "text",
            origin: "tenant",
            activation: "Primary",
          },
        ],
      },
    ],
  ]);
  const [imageFile, setImageFile] = useState();
  const [imageFileURL, setImageFileURL] = useState();
  const [files, setFiles] = useState([]);

  const fileHandler = async (event) => {
    const newFile = event.target.files[0]; // Get only the first file
    setImageFile(newFile);

    const reader = new FileReader();

    reader.onload = function (e) {
      setImageFileURL(e.target.result);
    };

    reader.readAsDataURL(newFile);
  };

  async function validateTenant(obj) {
    let errors = [];

    // Check BusinessName
    if (
      (!obj.hasOwnProperty("BusinessName") || obj.BusinessName === "") &&
      obj.TenantType != "Vacant"
    ) {
      errors.push("BusinessName is missing.");
    }

    // Check TenantName
    if (
      (!obj.hasOwnProperty("TenantName") || obj.TenantName === "") &&
      obj.TenantType != "Vacant"
    ) {
      errors.push("TenantName is missing.");
    }

    // Check TenantType, LeaseStarting and LeaseEnding
    if (obj.hasOwnProperty("TenantType") && obj.TenantType === "Primary") {
      if (!obj.hasOwnProperty("LeaseStarting") || obj.LeaseStarting === "") {
        errors.push("Lease Starting is missing.");
      }

      if (!obj.hasOwnProperty("LeaseEnding") || obj.LeaseEnding === "") {
        errors.push("Lease Ending is missing.");
      }

      if (obj.LeaseEnding < obj.LeaseStarting) {
        errors.push("Lease Ending cannot be before Lease Starting.");
      }
    }

    // Check ManagerMobile, MobileNumber, EmergencyContactMobile, SecondaryTenant'sMobileNumber, Unitratepersq, UtilityRate, PreviousUsage, CommonSpace(ifany), ServiceChargeRate
    const fields = [
      "ManagerMobile",
      "MobileNumber",
      "EmergencyContactMobile",
      "SecondaryTenant'sMobileNumber",
    ];

    fields.forEach((field) => {
      if (obj.hasOwnProperty(field) && isNaN(Number(obj[field]))) {
        errors.push(`${field} must be a number.`);
      }
    });

    return errors;
  }

  const handleClick = async () => {
    setLoading(true);
    console.log(data);
    console.log(tenantData);
    let errs = [];

    errs = await validateTenant(tenantData);
    if (!errs.length == 0) {
      console.log(errs);
      setError(errs);
      setLoading(false);
      return;
    }

    const tenantId = await addTenant(
      currentProperty,
      currentFloor,
      currentUnit,
      tenantData
    );
    if (imageFile) {
      await UploadImage(imageFile, `Tenants/${tenantId}/`);
    }
    files.map(async (f) => {
      if (f != undefined) {
        await UploadFile(f, `Tenants/${tenantId}/files`);
      }
    });

    setLoading(false);
    setDone(true);
    // await updateUnit(currentProperty, currentFloor, currentUnit, {
    //   ...data,
    //   currentTenant: tenantData.TenantName,
    //   currentTenantId: tenantId,
    //   currentTenantType: tenantData["TenantType"],
    //   currentBusinessName: tenantData["BusinessName"],
    // });

    await AddLog(
      `${user.Name} has requested a Move In of: ${tenantData.BusinessName}`
    );
    await addRequest({
      mainData: {
        ...data,
        currentTenant: tenantData.TenantName,
        currentTenantId: tenantId,
        currentTenantType: tenantData["TenantType"],
        currentBusinessName: tenantData["BusinessName"],
      },
      location: {
        currentProperty: currentProperty,
        currentFloor: currentFloor,
        currentUnit: currentUnit,
      },
      tenantData: {
        ...tenantData,
      },
      BusinessName: tenantData["BusinessName"],
      type: "Move In",
      typeId: "moveIn",
      RequestedBy: user["Name"],
      time: serverTimestamp(),
      status: "Pending",
    });
    setTimeout(() => {
      navigate("/home");
    }, [3000]);
  };

  const handleData = (e, label) => {
    const obj = {};
    obj[label.replace(/\s+/g, "")] = e.target.value;
    setData((prev) => {
      return { ...prev, ...obj };
    });
  };

  const handleTenantData = (e, label) => {
    const obj = {};
    obj[label.replace(/\s+/g, "")] = e.target.value;
    setTenantData((prev) => {
      return { ...prev, ...obj };
    });
  };

  const fetchUnitData = async () => {
    const unit = await getUnit(currentProperty, currentFloor, currentUnit);
    const prop = await getPropertyName(currentProperty);
    const floor = await getFloorName(currentProperty, currentFloor);

    setCurrentPropertyName(prop);
    setCurrentFloorName(floor);

    setUnitData(unit);
    if (unit["Rent"] == true) {
      setRent(true);
    }
    if (unit["Utility"] == true) {
      setUtility(true);
    }
    if (unit["ServiceCharge"] == true) {
      setSc(true);
    }

    if (unit["SecondaryTenant"] == true) {
      setSecondary(true);
    }
  };

  useEffect(() => {
    fetchUnitData();
  }, [currentUnit]);

  const handleChange = async (e, label) => {
    // console.log(label);
    if (label == "Rent") {
      setRent(e.target.checked);
      let obj = {};
      obj["Rent"] = e.target.checked;
      setData((prev) => {
        return { ...prev, ...obj };
      });
    } else if (label == "Utility") {
      setUtility(e.target.checked);
      let obj = {};
      obj["Utility"] = e.target.checked;
      setData((prev) => {
        return { ...prev, ...obj };
      });
    } else if (label == "Service Charge") {
      setSc(e.target.checked);
      let obj = {};
      obj["ServiceCharge"] = e.target.checked;
      setData((prev) => {
        return { ...prev, ...obj };
      });
    } else if (label == "Secondary Tenant") {
      setSecondary(e.target.checked);
      let obj = {};
      obj["SecondaryTenant"] = e.target.checked;
      setData((prev) => {
        return { ...prev, ...obj };
      });
    }
  };

  useEffect(() => {
    console.log(rent);
  }, [rent]);

  useEffect(() => {
    console.log(imageFile);
  }, [imageFile]);

  const fileHandler2 = async (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles([...files, ...newFiles]);
  };

  const handleDeleteFile = async (index) => {
    setFiles((prev) => {
      delete prev[index];
      return [...prev];
    });
  };

  return (
    <>
      <PageTitle title="Move In" image={imageFileURL || ""} />
      {!currentUnit && (
        <UnitSelector
          setCurrentProperty={setCurrentProperty}
          setCurrentFloor={setCurrentFloor}
          setCurrentUnit={setCurrentUnit}
          onlyVacant={true}
        />
      )}

      {unitData &&
        currentUnit &&
        fieldData.map((f) => {
          return (
            <FieldContainer>
              {f.map((x) => {
                return (
                  <FieldSection>
                    <div
                      className="flex-row color-primary"
                      style={{ marginBottom: "0.5rem" }}
                    >
                      {x.fieldIcon}
                      {(!x.activation ||
                        tenantData["TenantType"] == "Position") && (
                        <label>{x.fieldTitle}</label>
                      )}
                    </div>
                    {x["fieldItems"].map((y) => {
                      if (
                        !y.activation ||
                        (y.activation == "Rent" && rent) ||
                        (y.activation == "Utility" && utility) ||
                        (y.activation == "SC" && sc) ||
                        (y.activation == "Secondary" && secondary) ||
                        (y.activation == "Position" &&
                          tenantData["TenantType"] == "Primary") ||
                        (y.activation == "Primary" &&
                          tenantData["TenantType"] == "Position")
                      ) {
                        if (y.type == "text") {
                          return (
                            <TextInputWithLabel
                              label={y.label}
                              notEditable={
                                y.placeholder == undefined ? true : false
                              }
                              value={
                                y.label == "Property"
                                  ? currentPropertyName
                                  : y.label == "Floor"
                                  ? currentFloorName
                                  : y.origin == "unit"
                                  ? unitData[y.label.replace(/\s+/g, "")]
                                  : ""
                              }
                              placeholder={y.placeholder}
                              handleChange={
                                y.origin == "unit"
                                  ? handleData
                                  : y.origin == "tenant"
                                  ? handleTenantData
                                  : null
                              }
                            />
                          );
                        } else if (y.type == "checkbox") {
                          return (
                            <CheckboxInputWithLabel
                              label={y.label}
                              handleChange={handleChange}
                              notEditable={false}
                              placeholder={"bleh"}
                              value={
                                y.label == "Rent" && rent == true
                                  ? rent
                                  : y.label == "Utility" && utility == true
                                  ? utility
                                  : y.label == "ServiceCharge" && sc == true
                                  ? sc
                                  : false
                              }
                            />
                          );
                        } else if (y.type == "date") {
                          return (
                            <DateInputWithLabel
                              label={y.label}
                              notEditable={
                                y.placeholder == undefined ? true : false
                              }
                              placeholder={y.placeholder}
                              value={unitData[y.label.replace(/\s+/g, "")]}
                              handleChange={
                                y.origin == "unit"
                                  ? handleData
                                  : y.origin == "tenant"
                                  ? handleTenantData
                                  : null
                              }
                            />
                          );
                        } else if (y.type == "dropdown") {
                          return (
                            <DropdownWithLabel
                              label={y.label}
                              items={y.items}
                              value={tenantData["TenantType"]}
                              defaultVal={tenantData["TenantType"]}
                              handleChange={handleTenantData}
                              notEditable={false}
                            />
                          );
                        }
                      }
                    })}
                  </FieldSection>
                );
              })}
            </FieldContainer>
          );
        })}
      <br></br>

      {imageFile && (
        <div style={{ marginBottom: "1rem" }}>{imageFile.name}</div>
      )}
      {!loading && !done && currentUnit && (
        <UploadButton
          text={`${imageFile ? "Change Image" : "Add Image"}`}
          single={true}
          fileHandler={fileHandler}
          imageOnly={true}
        />
      )}
      {files.map((f, index) => {
        return (
          <div className="flex-row">
            {f && (
              <>
                <BlueItem text={f.name} />
                <DeleteButton name={index} handleDelete={handleDeleteFile} />
                <br></br>
              </>
            )}
          </div>
        );
      })}
      {files.length > 0 && <br></br>}
      {!loading && !done && currentUnit && (
        <UploadButton
          text={"Add Files"}
          fileHandler={fileHandler2}
          imageOnly={false}
        />
      )}
      <br></br>
      {error.map((e) => {
        return <ErrorMsg text={e} />;
      })}
      <br></br>
      {!loading && !done && currentUnit && (
        <PrimaryButton text="Send Request" handleClick={() => handleClick()} />
      )}
      {loading && <Loading />}
      {done && <SuccessAnimation text="Request Sent" />}

      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
}

export default MoveIn;
