import classes from "../styles/Loading.module.css";

export const Loading = () => {
  return (
    <>
      <div className={classes.loader}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </>
  );
};
