import { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import { TextAreaInput } from "../../components/TextAreaInput";
import BlueItem from "../../components/BlueItem";
import DeleteButton from "../../components/DeleteButton";
import UploadButton from "../../components/UploadButton";
import { UserAuth } from "../../AuthContext";
import PrimaryButton from "../../components/PrimaryButton";
import {
  GetFiles,
  UploadFile,
  addRequest,
  getProperty,
  getRequest,
  updateRequest,
} from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { serverTimestamp } from "firebase/firestore";
import PrimaryDeleteButton from "../../components/PrimaryDeleteButton";
import DeleteDialog from "../../components/DeleteDialog";
import { Loading } from "../../components/Loading";
import SuccessAnimation from "../../components/SuccessAnimation";

const RecievePropertyComplain = () => {
  const [files, setFiles] = useState([]);
  const [data, setData] = useState();
  const [propName, setPropName] = useState();
  const [reqData, setReqData] = useState();
  const [done, setDone] = useState(false);
  const [toggleDelete, setToggleDelete] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { bn, user, rolesStream } = UserAuth();
  const { requestId } = useParams();

  const handleData = (e, label) => {
    setData(e.target.value);
  };
  const fileHandler = async (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles([...files, ...newFiles]);
  };
  const handleDeleteFile = async (index) => {
    setFiles((prev) => {
      delete prev[index];
      return [...prev];
    });
  };

  const sendComplain = async () => {
    setLoading(true);
    await updateRequest(requestId, {
      status: "Approved",
      RecievedBy: user.Name,
    });
    setLoading(false);
    setDone(true);
    setTimeout(() => {
      navigate("/home");
    }, [3000]);
  };

  const handleDelete = async () => {
    setLoading(true);
    await updateRequest(requestId, {
      status: "Declined",
      RecievedBy: user.Name,
    });
    setLoading(false);
    setDone(true);
    setTimeout(() => {
      navigate("/home");
    }, [3000]);
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  const getFiles = async () => {
    const files = await GetFiles(`Complains/${requestId}`);
    console.log(files);
    setFiles(files);
  };

  const getReq = async () => {
    const req = await getRequest(requestId);
    console.log(req);
    setData(req.Complain);
    setReqData(req);
    const prop = await getProperty(req.PropertyId);
    setPropName(prop.PropertyName);
  };

  useEffect(() => {
    getFiles();
    getReq();
  }, [requestId]);

  return (
    <>
      <PageTitle title="Property Complain" />
      <div className="flex-column">
        {data && (
          <>
            <TextAreaInput
              label={"Property"}
              //   placeholder={"Enter Complain"}
              //   label_bn={f.label_bn}
              //   placeholder_bn={f.placeholder_bn}
              notEditable={true}
              value={propName}
            ></TextAreaInput>
            <TextAreaInput
              label={"Complain"}
              //   placeholder={"Enter Complain"}
              //   label_bn={f.label_bn}
              //   placeholder_bn={f.placeholder_bn}
              notEditable={true}
              value={data}
            ></TextAreaInput>
          </>
        )}
        <br></br>
        {Object.keys(files).length > 0 &&
          Object.keys(files).map((f) => {
            return (
              <div style={{ alignItems: "center" }} className="flex-row">
                <BlueItem link={files[f]} text={f} />
              </div>
            );
          })}
        <br></br>
        {reqData &&
          reqData.status == "Pending" &&
          !loading &&
          !done &&
          rolesStream[user.Role][`RecievePropertyComplain`] && (
            <div className="flex-row">
              <PrimaryButton
                text="Approve"
                handleClick={sendComplain}
                green={true}
              />
              <PrimaryDeleteButton
                text={"Decline"}
                handleClick={() => {
                  setToggleDelete(true);
                }}
              />
            </div>
          )}
      </div>
      {toggleDelete && (
        <DeleteDialog
          text="Are you sure you want to decline the complain?"
          onApproval={() => {
            setToggleDelete(false);
            handleDelete();
          }}
          onDecline={() => {
            setToggleDelete(false);
          }}
        />
      )}
      {loading && <Loading />}

      {done && <SuccessAnimation text="Complain Recieved" />}
    </>
  );
};

export default RecievePropertyComplain;
