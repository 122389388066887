import { useState } from "react";
import { UserAuth } from "../AuthContext";
import PageTitle from "../components/PageTitle";
import classes from "../styles/Settings.module.css";
import TextLabel from "../components/TextLabel";
import UploadButton from "../components/UploadButton";
import { useEffect } from "react";
import { GetImage, UploadFile, UploadImage, db } from "../firebase";
import { Loading } from "../components/Loading";
import PrimaryButton from "../components/PrimaryButton";
import { TextInputWithLabel } from "../components/TextInputWithLabel";
import ErrorMsg from "../components/ErrorMsg";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import SuccessAnimation from "../components/SuccessAnimation";

const Settings = () => {
  const { user, rolesStream } = UserAuth();
  const [image, setImage] = useState();
  const [done, setDone] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState([]);
  const [changeMode, setChangeMode] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [fieldData] = useState([
    {
      label: "Name",
      label_bn: "নাম",
    },
    {
      label: "Username",
      label_bn: "নাম",
    },
    {
      label: "Designation",
      label_bn: "পদবী",
    },
    {
      label: "Mobile",
      label_bn: "মোবাইল",
    },
    {
      label: "Email",
      label_bn: "ইমেইল",
    },
    {
      label: "Role",
      label_bn: "রোল",
    },
  ]);

  const fileHandler = async (event) => {
    setLoading(true);
    const newFile = event.target.files[0]; // Get only the first file
    await UploadImage(newFile, `Users/${user.id}/`);

    setInterval(async () => {
      await fetchImage();
    }, 2000);

    setLoading(false);
  };

  const fetchImage = async () => {
    const x = await GetImage(`Users/${user.id}`);
    setImage(x);
  };

  useEffect(() => {
    fetchImage();
  }, [user]);

  const handleChange = async (e, label) => {
    const obj = {};
    obj[label] = e.target.value;
    setData((prev) => {
      return { ...prev, ...obj };
    });
  };

  const ChangeHandle = async () => {
    const docRef = await getDoc(doc(db, "Users", user.id));
    if (docRef.data().Password == data[`Old Password`]) {
      await updateDoc(doc(db, "Users", user.id), {
        Password: data[`New Password`],
      });
      setChangeMode(false);
      setDone(true);
    } else {
      setError([`Wrong Password`]);
    }
  };

  return (
    <>
      <PageTitle title="Settings" />
      {rolesStream && user && (
        <div className="flex-column">
          <img
            className={classes.settingsDP}
            src={
              image ||
              "https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
            }
          />
          {!loading && (
            <UploadButton
              text="Change Image"
              single={true}
              fileHandler={fileHandler}
              imageOnly={true}
            />
          )}
          {loading && <Loading />}
          <br></br>
          {fieldData.map((f) => {
            if (f.label != "Role") {
              return (
                <div>
                  <TextLabel label={f.label} value={user[f.label]} />
                </div>
              );
            } else {
              return (
                <div>
                  <TextLabel
                    label={f.label}
                    value={rolesStream[user[f.label]].Name}
                  />
                </div>
              );
            }
          })}
          <br></br>

          <div className="flex-row"></div>

          {!changeMode && (
            <PrimaryButton
              text={"Change Password"}
              handleClick={() => setChangeMode(true)}
            />
          )}
          <br></br>
          {changeMode && (
            <div>
              <TextInputWithLabel
                pass={true}
                label="Old Password"
                placeholder="Enter Password"
                handleChange={handleChange}
              />
              <TextInputWithLabel
                pass={true}
                label="New Password"
                placeholder="Enter Password"
                handleChange={handleChange}
              />
            </div>
          )}

          {/* {!changeMode && (
            <PrimaryButton
              text={isEditing ? "Save" : "Edit"}
              handleClick={() => setIsEditing(!isEditing)}
            />
          )} */}
          <br></br>
          {error.map((e) => {
            return <ErrorMsg text={e} />;
          })}
          {changeMode && (
            <PrimaryButton text={"Save"} handleClick={() => ChangeHandle()} />
          )}
          {done && <SuccessAnimation text="Password Changed" />}
        </div>
      )}
    </>
  );
};

export default Settings;
