import classes from "../styles/BlueItem.module.css";

const BlueItem = ({ text, link }) => {
  const truncatedText = text.length > 32 ? text.substring(0, 32) + "…" : text;

  return (
    <a className={classes.blueItem} href={link} target="_blank">
      {truncatedText}
    </a>
  );
};

export default BlueItem;
