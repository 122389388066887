import React from "react";
import classes from "../styles/UploadButton.module.css";

function UploadButton({ text, fileHandler, single, imageOnly }) {
  return (
    <div>
      <label
        htmlFor={single ? "imageUpload" : "fileUpload"}
        className={classes.pButton}
      >
        {text}
        <input
          multiple={!single ? true : false}
          id={single ? "imageUpload" : "fileUpload"}
          type={"file"}
          accept={imageOnly ? "image/*" : "*"} // Accept only image files
          onChange={fileHandler}
        />
      </label>
      <br></br>
      <br></br>
    </div>
  );
}

export default UploadButton;
