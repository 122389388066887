import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import Dashboard from "./pages/Dashboard";
import AddFloor from "./pages/floor/AddFloor";
import AddProperty from "./pages/property/AddProperty";
import FloorDetails from "./pages/floor/FloorDetails";
import ViewFloors from "./pages/floor/ViewFloors";
import ViewProperties from "./pages/property/ViewProperties";
import Navbar from "./components/Navbar";
import Login from "./pages/Login";
import SideButtons from "./components/SideButtons";
import { useEffect, useRef, useState } from "react";
import PropertyDetails from "./pages/property/PropertyDetails";
import ViewUnits from "./pages/unit/ViewUnits";
import AddUnit from "./pages/unit/AddUnit";
import UnitDetails from "./pages/unit/UnitDetails";
import QRScanner from "./pages/QRScanner";
import AddUser from "./pages/user/AddUser";
import { AuthContextProvider } from "./AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import AddRole from "./pages/user/AddRole";
import ViewRoles from "./pages/user/ViewRoles";
import RoleDetails from "./pages/user/RoleDetails";
import Inactive from "./pages/Inactive";
import { AnimatePresence } from "framer-motion";
import ViewUsers from "./pages/user/ViewUsers";
import UserDetails from "./pages/user/UserDetails";
import Settings from "./pages/Settings";
import ActivityLog from "./pages/ActivityLog";
import NotificationDropdown from "./components/NotificationDropdown";
import MoveIn from "./pages/request/MoveIn";
import RentEntry from "./pages/entry/RentEntry";
import ServiceChargeEntry from "./pages/entry/ServiceChargeEntry";
import UtilityEntry from "./pages/entry/UtilityEntry";
import RentStatement from "./pages/finances/statements/RentStatement";
import EntryDetails from "./pages/entry/EntryDetails";
import RentCollected from "./pages/reports/RentCollected";
import RentRecieveable from "./pages/finances/accountsRecieveable/RentRecieveable";
import RentSheet from "./pages/finances/balanceSheet/RentSheet";
import RecieveMoveIn from "./pages/request/RecieveMoveIn";
import UtilityCollected from "./pages/reports/UtilityCollected";
import ServiceChargeCollected from "./pages/reports/ServiceChargeCollected";
import LeaseEnding from "./pages/reports/LeaseEnding";
import VacantUnits from "./pages/reports/VacantUnits";
import MeterReadingHistory from "./pages/reports/MeterReadingHistory";
import UtilityRecieveable from "./pages/finances/accountsRecieveable/UtilityRecieveable";
import ServiceChargeRecieveable from "./pages/finances/accountsRecieveable/ServiceChargeRecieveable";
import UtilitySheet from "./pages/finances/balanceSheet/UtilitySheet";
import UtilityStatement from "./pages/finances/statements/UtilityStatement";
import ServiceChargeStatement from "./pages/finances/statements/ServiceChargeStatement";
import Statistics from "./pages/Statistics";
import MoveOut from "./pages/request/MoveOut";
import FloorView from "./pages/floor/FloorView";
import ViewBlocks from "./pages/unit/ViewBlocks";
import RecieveMoveOut from "./pages/request/RecieveMoveOut";
import OtherComplain from "./pages/request/OtherComplain";
import RecieveOtherComplain from "./pages/request/RecieveOtherComplain";
import ViewAllRequests from "./pages/request/ViewAllRequests";
import MyRequests from "./pages/request/ViewMyRequests";
import PropertyComplain from "./pages/request/PropertyComplain";
import RecievePropertyComplain from "./pages/request/RecievePropertyComplain";
import RecieveTenantComplain from "./pages/request/RecieveTenantComplain";
import TenantComplain from "./pages/request/TenantComplain";
import UpcomingCancellations from "./pages/request/UpcomingCancellations";
import ChangeSecondaryTenant from "./pages/request/ChangeSecondaryTenant";
import RecieveChangeSecondaryTenant from "./pages/request/RecieveChangeSecondaryTenant";
import ChangePositionTenant from "./pages/request/ChangePositionTenant";
import RecieveChangePositionTenant from "./pages/request/RecieveChangePositionTenant";
import Forgot from "./pages/Forgot";
import ServiceChargeSheet from "./pages/finances/balanceSheet/ServiceChargeSheet";
import PastTenant from "./pages/unit/PastTenant";
import TenantEntryHistory from "./pages/entry/TenantEntryHistory";

function App() {
  const location = useLocation();
  const [navToggled, setNavToggled] = useState(false);
  const [notifToggled, setNotifToggled] = useState(false);
  const navigate = useNavigate();
  const notifRef = useRef();

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/home");
    }
  }, [location.pathname, navigate]);

  const handleClick = async (e) => {
    // console.log(notifRe
    if (!notifRef.current.contains(e.target)) {
      setNotifToggled(false);
    }
  };

  return (
    <>
      <AuthContextProvider>
        {notifToggled && (
          <NotificationDropdown
            setIsDropdownVisible={setNotifToggled}
            isDropdownVisible={!notifToggled}
          />
        )}
        <div onClick={handleClick} className="mainContainer">
          {!location.pathname.includes("login") &&
            !location.pathname.includes("forgotPassword") &&
            !location.pathname.includes("qr") &&
            !location.pathname.includes("inactive") && (
              <div onClick={() => setNotifToggled(false)}>
                <Navbar
                  navToggled={navToggled}
                  setNavToggled={setNavToggled}
                  setNotifToggled={setNotifToggled}
                />
              </div>
            )}
          <div className="flex-column">
            {!location.pathname.includes("login") &&
              !location.pathname.includes("forgotPassword") &&
              !location.pathname.includes("qr") &&
              !location.pathname.includes("inactive") && (
                <div className={"logoPhone"}>
                  <p
                    onClick={() => {
                      navigate("/home");
                    }}
                  >
                    rent<span>milloh</span>
                  </p>
                </div>
              )}

            {(!navToggled || location.pathname.includes("login")) && (
              <div
                className="pageContent"
                onClick={() => setNotifToggled(false)}
              >
                <AnimatePresence>
                  <Routes>
                    {/* Login */}
                    <Route exact path="/login" element={<Login />} />

                    <Route exact path="/forgotPassword" element={<Forgot />} />

                    {/* Inactive */}
                    <Route exact path="/inactive" element={<Inactive />} />

                    {/* Settings */}
                    <Route
                      exact
                      path="/settings"
                      element={
                        <ProtectedRoute>
                          <Settings />
                        </ProtectedRoute>
                      }
                    />

                    {/* Statistics */}
                    <Route
                      exact
                      path="/statistics"
                      element={
                        <ProtectedRoute>
                          <Statistics />
                        </ProtectedRoute>
                      }
                    />

                    {/* Logs */}
                    <Route
                      exact
                      path="/logs"
                      element={
                        <ProtectedRoute>
                          <ActivityLog />
                        </ProtectedRoute>
                      }
                    />

                    {/* Dashboard */}
                    <Route
                      exact
                      path="/home"
                      element={
                        <ProtectedRoute>
                          <Dashboard />
                        </ProtectedRoute>
                      }
                    />

                    {/* Entries */}
                    <Route
                      exact
                      path="/entries/:entryId"
                      element={
                        <ProtectedRoute>
                          <EntryDetails />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/entryHistory/:tenantId"
                      element={
                        <ProtectedRoute>
                          <TenantEntryHistory />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/entries/rent/add"
                      element={
                        <ProtectedRoute>
                          <RentEntry />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/entries/utility/add"
                      element={
                        <ProtectedRoute>
                          <UtilityEntry />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/entries/serviceCharge/add"
                      element={
                        <ProtectedRoute>
                          <ServiceChargeEntry />
                        </ProtectedRoute>
                      }
                    />

                    {/* QRScanner */}
                    <Route
                      exact
                      path="/qr"
                      element={
                        <ProtectedRoute>
                          <QRScanner />
                        </ProtectedRoute>
                      }
                    />

                    {/* Property */}
                    <Route
                      exact
                      path="/properties/"
                      element={
                        <ProtectedRoute>
                          <ViewProperties />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/properties/add"
                      element={
                        <ProtectedRoute>
                          <AddProperty />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/properties/:propertyId"
                      element={
                        <ProtectedRoute>
                          <PropertyDetails />
                        </ProtectedRoute>
                      }
                    />

                    {/* Floor */}
                    <Route
                      exact
                      path="/properties/:propertyId/floors/"
                      element={
                        <ProtectedRoute>
                          <ViewFloors />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/floorView"
                      element={
                        <ProtectedRoute>
                          <FloorView />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/properties/:propertyId/floors/add"
                      element={
                        <ProtectedRoute>
                          <AddFloor />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/properties/:propertyId/floors/:floorId"
                      element={
                        <ProtectedRoute>
                          <FloorDetails />
                        </ProtectedRoute>
                      }
                    />

                    {/* Unit */}
                    <Route
                      exact
                      path="/properties/:propertyId/floors/:floorId/units"
                      element={
                        <ProtectedRoute>
                          <ViewUnits />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/properties/:propertyId/floors/:floorId/units/add"
                      element={
                        <ProtectedRoute>
                          <AddUnit />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/properties/:propertyId/floors/:floorId/units/:unitId"
                      element={
                        <ProtectedRoute>
                          <UnitDetails />
                        </ProtectedRoute>
                      }
                    />

                    {/* Reports */}
                    <Route
                      exact
                      path="/reports/rentCollected"
                      element={
                        <ProtectedRoute>
                          <RentCollected />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/reports/utilityCollected"
                      element={
                        <ProtectedRoute>
                          <UtilityCollected />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/reports/serviceChargeCollected"
                      element={
                        <ProtectedRoute>
                          <ServiceChargeCollected />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/reports/leaseEnding"
                      element={
                        <ProtectedRoute>
                          <LeaseEnding />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/reports/vacantUnits"
                      element={
                        <ProtectedRoute>
                          <VacantUnits />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/reports/meterReading"
                      element={
                        <ProtectedRoute>
                          <MeterReadingHistory />
                        </ProtectedRoute>
                      }
                    />

                    {/* Requests */}
                    <Route
                      exact
                      path="/requests/all"
                      element={
                        <ProtectedRoute>
                          <ViewAllRequests />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/requests/upcomingCancellations"
                      element={
                        <ProtectedRoute>
                          <UpcomingCancellations />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/requests/my"
                      element={
                        <ProtectedRoute>
                          <MyRequests />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/requests/moveIn"
                      element={
                        <ProtectedRoute>
                          <MoveIn />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/requests/changePositionTenant"
                      element={
                        <ProtectedRoute>
                          <ChangePositionTenant />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/requests/changePositionTenant/:requestId"
                      element={
                        <ProtectedRoute>
                          <RecieveChangePositionTenant />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/requests/changeSecondaryTenant"
                      element={
                        <ProtectedRoute>
                          <ChangeSecondaryTenant />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/requests/changeSecondaryTenant/:requestId"
                      element={
                        <ProtectedRoute>
                          <RecieveChangeSecondaryTenant />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/requests/moveOut"
                      element={
                        <ProtectedRoute>
                          <MoveOut />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/requests/otherComplain"
                      element={
                        <ProtectedRoute>
                          <OtherComplain />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/requests/otherComplain/:requestId"
                      element={
                        <ProtectedRoute>
                          <RecieveOtherComplain />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/requests/propertyComplain"
                      element={
                        <ProtectedRoute>
                          <PropertyComplain />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/requests/propertyComplain/:requestId"
                      element={
                        <ProtectedRoute>
                          <RecievePropertyComplain />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/requests/tenantComplain"
                      element={
                        <ProtectedRoute>
                          <TenantComplain />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/requests/tenantComplain/:requestId"
                      element={
                        <ProtectedRoute>
                          <RecieveTenantComplain />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/requests/moveIn/:requestId"
                      element={
                        <ProtectedRoute>
                          <RecieveMoveIn />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/requests/moveOut/:requestId"
                      element={
                        <ProtectedRoute>
                          <RecieveMoveOut />
                        </ProtectedRoute>
                      }
                    />

                    {/* User */}
                    <Route
                      exact
                      path="/properties/:propertyId/floors/:floorId/units/b/:block"
                      element={
                        <ProtectedRoute>
                          <ViewUnits />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/users/add"
                      element={
                        <ProtectedRoute>
                          <AddUser />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/users/"
                      element={
                        <ProtectedRoute>
                          <ViewUsers />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/users/:userId"
                      element={
                        <ProtectedRoute>
                          <UserDetails />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/users/roles/"
                      element={
                        <ProtectedRoute>
                          <ViewRoles />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/users/roles/:roleId"
                      element={
                        <ProtectedRoute>
                          <RoleDetails />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/users/roles/add"
                      element={
                        <ProtectedRoute>
                          <AddRole />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/properties/:propertyId/floors/:floorId/units/:unitId/tenants/:tenantId"
                      element={
                        <ProtectedRoute>
                          <PastTenant />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/properties/:propertyId/floors/:floorId/units/:unitId"
                      element={
                        <ProtectedRoute>
                          <UnitDetails />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/properties/:propertyId/floors/:floorId/blocks/"
                      element={
                        <ProtectedRoute>
                          <ViewBlocks />
                        </ProtectedRoute>
                      }
                    />

                    {/* Accounts Recieveable */}
                    <Route
                      exact
                      path="/finances/rent"
                      element={
                        <ProtectedRoute>
                          <RentRecieveable />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/finances/utility"
                      element={
                        <ProtectedRoute>
                          <UtilityRecieveable />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/finances/serviceCharge"
                      element={
                        <ProtectedRoute>
                          <ServiceChargeRecieveable />
                        </ProtectedRoute>
                      }
                    />

                    {/* Balance Sheet */}
                    <Route
                      exact
                      path="/finances/rentCollected"
                      element={
                        <ProtectedRoute>
                          <RentSheet />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/finances/utilityCollected"
                      element={
                        <ProtectedRoute>
                          <UtilitySheet />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      exact
                      path="/finances/serviceChargeCollected"
                      element={
                        <ProtectedRoute>
                          <ServiceChargeSheet />
                        </ProtectedRoute>
                      }
                    />

                    {/* Statements */}
                    <Route
                      exact
                      path="/finances/rentStatement"
                      element={
                        <ProtectedRoute>
                          <RentStatement />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/finances/utilityStatement"
                      element={
                        <ProtectedRoute>
                          <UtilityStatement />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      exact
                      path="/finances/serviceChargeStatement"
                      element={
                        <ProtectedRoute>
                          <ServiceChargeStatement />
                        </ProtectedRoute>
                      }
                    />
                  </Routes>
                </AnimatePresence>
              </div>
            )}
          </div>
          {!location.pathname.includes("login") &&
            !location.pathname.includes("forgotPassword") &&
            !location.pathname.includes("qr") &&
            !location.pathname.includes("inactive") && (
              <div ref={notifRef}>
                <SideButtons
                  navToggled={navToggled}
                  setNavToggled={setNavToggled}
                  notifToggled={notifToggled}
                  setNotifToggled={setNotifToggled}
                ></SideButtons>
              </div>
            )}
        </div>
      </AuthContextProvider>
    </>
  );
}

export default App;
