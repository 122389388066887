import classes from "../styles/Table.module.css";

const Table = ({ children }) => {
  console.log(children);
  return (
    <>
      <div className={classes.tableContainer}>{children}</div>
    </>
  );
};

export default Table;
