import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import PrimaryButton from "../../components/PrimaryButton";
import { TextInputWithLabel } from "../../components/TextInputWithLabel";
import { db, getFloor, updateFloor } from "../../firebase";
import { deleteDoc, doc } from "firebase/firestore";
import DeleteButton from "../../components/DeleteButton";
import DeleteDialog from "../../components/DeleteDialog";
import PrimaryDeleteButton from "../../components/PrimaryDeleteButton";

function FloorDetails() {
  const { propertyId, floorId } = useParams();
  const [floorData, setFloorData] = useState({});
  const [toggleDelete, setToggleDelete] = useState(false);
  const [isEditing, setIsEditing] = useState();
  const navigate = useNavigate();
  const [fieldData] = useState([
    {
      label: "Floor Name",
      placeholder: "Enter Property Name",
      label_bn: "প্রপার্টির নাম",
      placeholder_bn: "প্রপার্টির নাম লিখুন",
    },
    {
      label: "Area",
      placeholder: "Enter Area",
      label_bn: "প্রপার্টির আকার",
      placeholder_bn: "প্রপার্টির আকার লিখুন",
    },
  ]);

  const handleUpdate = async () => {
    if (isEditing) {
      setIsEditing(false);
      await updateFloor(propertyId, floorId, floorData);
      console.log("edited");
    } else {
      setIsEditing(true);
    }
  };

  const handleChange = async (e, label) => {
    let obj = {};
    obj[label.replace(/\s+/g, "")] = e.target.value;
    setFloorData((prev) => {
      return { ...prev, ...obj };
    });
  };

  const handleDelete = async () => {
    await deleteDoc(doc(db, `Properties/${propertyId}/Floors`, floorId));
    navigate(`/properties/${propertyId}/floors`);
  };

  const fetchFloor = async () => {
    const floor = await getFloor(propertyId, floorId);
    console.log(floor);
    setFloorData(floor);
  };

  useEffect(() => {
    console.log(propertyId);
    console.log(floorId);
    fetchFloor();
  }, [propertyId, floorId]);

  return (
    <>
      <PageTitle title={"Floor Details"} />
      {Object.keys(floorData).length > 0 && (
        <div className="flex-column">
          {fieldData.map((p) => {
            return (
              <React.Fragment key={p}>
                {/* <label>{p.label}</label>
                <label>{propertyData[p.label.replace(/\s+/g, "")]}</label> */}
                <TextInputWithLabel
                  handleChange={handleChange}
                  label={p.label}
                  placeholder={p.placeholder}
                  notEditable={!isEditing}
                  value={floorData[p.label.replace(/\s+/g, "")]}
                />
              </React.Fragment>
            );
          })}
          <br></br>
          <div className="flex-row">
            <PrimaryButton
              text={isEditing ? "Save" : "Edit"}
              handleClick={() => {
                handleUpdate();
              }}
            />
            <PrimaryDeleteButton
              text="Delete"
              handleClick={() => setToggleDelete(true)}
            />
          </div>

          {toggleDelete && (
            <DeleteDialog
              text="Are you sure you want to delete this floor?"
              onApproval={() => {
                setToggleDelete(false);
                handleDelete();
              }}
              onDecline={() => setToggleDelete(false)}
            />
          )}
        </div>
      )}
    </>
  );
}

export default FloorDetails;
