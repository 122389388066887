import classes from "../styles/PrimaryDeleteButton.module.css";

const PrimaryDeleteButton = ({ text, handleClick }) => {
  return (
    <div>
      <button className={classes.pButton} onClick={handleClick}>
        {text}
      </button>
    </div>
  );
};

export default PrimaryDeleteButton;
