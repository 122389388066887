import classes from "../styles/TextInputWithDropdown.module.css";

const TextInputWithDropdown = ({
  label,
  placeholder,
  handleChange,
  notEditable,
  value,
  hideBlock,
  setSearchState,
  name,
}) => {
  return (
    <>
      <div className={`${classes.inputContainer} flex-column hidePrint`}>
        <div
          className="flex-row"
          style={{ justifyContent: "space-between", width: "10rem" }}
        >
          <label>
            {label}
            <span>{value && notEditable ? `: ${value}` : null}</span>
          </label>
          <select onChange={(e) => setSearchState(e.target.value)}>
            {!hideBlock && <option value={"Block"}>Block</option>}
            <option value={"UnitNo"}>Unit No</option>
            {name == true && <option value={"UnitName"}>Unit Name</option>}
          </select>
        </div>

        {!notEditable && placeholder && (
          <input
            defaultValue={value}
            placeholder={placeholder}
            onChange={(e) => handleChange(e, label)}
          ></input>
        )}
      </div>
    </>
  );
};

export default TextInputWithDropdown;
