import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "../styles/Navbar.module.css";

import HomeIcon from "@mui/icons-material/Home";
import {
  Apartment,
  AutoStories,
  BarChart,
  Comment,
  Edit,
  People,
} from "@mui/icons-material";
import { UserAuth } from "../AuthContext";
import SidebarUsersSection from "./SidebarUsersSection";
import { GetImage } from "../firebase";

function Navbar({ navToggled, setNavToggled, setNotifToggled }) {
  const { user, bn, rolesStream } = UserAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const navItems = [
    {
      Name: "Dashboard",
      Name_bn: "ড্যাশবোর্ড",
      Link: "/home",
      Icon: <HomeIcon />,
      ActivationName: "home",
    },
    {
      Name: "Entries",
      Name_bn: "এন্ট্রি",
      Icon: <Edit />,
      subItems: [
        {
          Name: "Rent Collection",
          Name_bn: "ভাড়া সংগ্রহ",
          Link: "/entries/rent/add",
          Role: "RentCollection",
        },
        {
          Name: "Utility Collection",
          Name_bn: "ইউটিলিটি সংগ্রহ",
          Link: "/entries/utility/add",
          Role: "UtilityCollection",
        },
        {
          Name: "Service Charge Collection",
          Name_bn: "সেবা চার্জ সংগ্রহ",
          Link: "/entries/serviceCharge/add",
          Role: "ServiceChargeCollection",
        },
      ],
      ActivationName: "entries",
    },
    {
      Name: "Properties",
      Name_bn: "প্রপার্টি",
      Icon: <Apartment />,
      subItems: [
        {
          Name: "Add Property",
          Name_bn: "প্রপার্টি যোগ করুন",
          Link: "/properties/add",
          Role: "AddProperty",
        },
        {
          Name: "View Properties",
          Name_bn: "প্রপার্টি দেখুন",
          Link: "/properties",
          Role: "ViewProperty",
        },
      ],
      ActivationName: "properties",
    },
    {
      Name: "Users",
      Name_bn: "ব্যবহারকারীরা",
      Icon: <People />,
      subItems: [
        {
          Name: "Add User",
          Name_bn: "ব্যবহারকারী যোগ করুন",
          Link: "/users/add",
          Role: "AddUser",
        },
        {
          Name: "View Users",
          Name_bn: "ব্যবহারকারীদের দেখুন",
          Link: "/users",
          Role: "ViewUsers",
        },
        {
          Name: "Manage Roles",
          Name_bn: "রোলগুলি পরিচালনা করুন",
          Link: "/users/roles",
          Role: "ManageRoles",
        },
      ],
      ActivationName: "users",
    },
    {
      Name: "Requests",
      Name_bn: "অনুরোধ",
      Icon: <Comment />,
      subItems: [
        {
          Name: "Move In",
          Name_bn: "প্রবেশ",
          Link: "/requests/moveIn",
          Role: "MoveInApplication",
        },
        {
          Name: "Move Out",
          Name_bn: "প্রস্থান",
          Link: "/requests/moveOut",
          Role: "MoveOutApplication",
        },
        {
          Name: "Change Secondary Tenant",
          Name_bn: "সেকেন্ডারি টেনেন্ট পরিবর্তন",
          Link: "/requests/changeSecondaryTenant",
          Role: "ChangeSecondaryTenantApplication",
        },
        {
          Name: "Change Position Tenant",
          Name_bn: "পোজিশন টেনেন্ট পরিবর্তন",
          Link: "/requests/changePositionTenant",
          Role: "ChangePositionTenantApplication",
        },
        {
          Name: "Tenant Complain",
          Name_bn: "টেনেন্টের অভিযোগ",
          Link: "/requests/tenantComplain",
          Role: "TenantComplain",
        },
        {
          Name: "Property Complain",
          Name_bn: "প্রপার্টির অভিযোগ",
          Link: "/requests/propertyComplain",
          Role: "PropertyComplain",
        },
        {
          Name: "Other Complain",
          Name_bn: "অন্যান্য অভিযোগ",
          Link: "/requests/otherComplain",
          Role: "OtherComplain",
        },
        {
          Name: "Upcoming Cancellations",
          Name_bn: "আসন্ন বাতিলকরণ",
          Link: "/requests/upcomingCancellations",
          Role: "UpcomingCancellations",
        },
      ],
      ActivationName: "requests",
    },
    {
      Name: "Reports",
      Name_bn: "রিপোর্ট",
      Icon: <AutoStories />,
      subItems: [
        {
          Name: "Rental Reports",
          Name_bn: "ভাড়া রিপোর্ট",
          subItems: [
            {
              Name: "Rent Collected",
              Name_bn: "সংগৃহিত ভাড়া",
              Link: "/reports/rentCollected",
              Role: "RentEntryHistory",
            },
            {
              Name: "Lease Ending",
              Name_bn: "লিজ শেষ",
              Link: "/reports/leaseEnding",
              Role: "LeaseEnding",
            },
            {
              Name: "Vacant Units",
              Name_bn: "ফাঁকা ইউনিট",
              Link: "/reports/vacantUnits",
              Role: "VacantUnits",
            },
          ],
        },
        {
          Name: "Utility Reports",
          Name_bn: "ইউটিলিটি রিপোর্ট",
          subItems: [
            {
              Name: "Meter Reading History",
              Name_bn: "মিটার পঠনের ইতিহাস",
              Link: "/reports/meterReading",
              Role: "MeterReadingHistory",
            },
            {
              Name: "Utility Bill Collection Status",
              Name_bn: "ইউটিলিটি বিল সংগ্রহের অবস্থা",
              Link: "/reports/utilityCollected",
              Role: "UtilityEntryHistory",
            },
          ],
        },
        {
          Name: "Service Charge Reports",
          Name_bn: "সেবা চার্জ রিপোর্ট",
          subItems: [
            {
              Name: "Service Charge Status",
              Name_bn: "সেবা চার্জের অবস্থা",
              Link: "/reports/serviceChargeCollected",
              Role: "SCEntryHistory",
            },
          ],
        },
      ],

      ActivationName: "reports",
    },
    {
      Name: "Finances",
      Name_bn: "আর্থিক",
      Icon: <BarChart />,
      subItems: [
        {
          Name: "Account Receiveable",
          Name_bn: "অ্যাকাউন্ট প্রাপ্য",
          subItems: [
            {
              Name: "Rent",
              Name_bn: "ভাড়া",
              Link: "/finances/rent",
              Role: "Rent",
            },
            {
              Name: "Utility",
              Name_bn: "ইউটিলিটি",
              Link: "/finances/utility",
              Role: "Utility",
            },
            {
              Name: "Service Charge",
              Name_bn: "সেবা চার্জ",
              Link: "/finances/serviceCharge",
              Role: "ServiceCharge",
            },
          ],
        },
        {
          Name: "Balance Sheet",
          Name_bn: "ব্যালেন্স শীট",
          subItems: [
            {
              Name: "Rent Collected",
              Name_bn: "সংগৃহিত ভাড়া",
              Link: "/finances/rentCollected",
              Role: "RentCollected",
            },
            {
              Name: "Utility Collected",
              Name_bn: "ইউটিলিটি সংগ্রহ",
              Link: "/finances/utilityCollected",
              Role: "UtilityCollected",
            },
            {
              Name: "Service Charge Collected",
              Name_bn: "সেবা চার্জ সংগ্রহ",
              Link: "/finances/serviceChargeCollected",
              Role: "ServiceChargeCollected",
            },
          ],
        },
        {
          Name: "Statements",
          Name_bn: "বিবৃতি",
          subItems: [
            {
              Name: "Rent Statement",
              Name_bn: "ভাড়ার বিবৃতি",
              Link: "/finances/rentStatement",
              Role: "RentStatements",
            },
            {
              Name: "Utility Statement",
              Name_bn: "ইউটিলিটি বিবৃতি",
              Link: "/finances/utilityStatement",
              Role: "UtilityStatements",
            },
            {
              Name: "Service Charge Statement",
              Name_bn: "সেবা চার্জের বিবৃতি",
              Link: "/finances/serviceChargeStatement",
              Role: "ServiceChargeStatements",
            },
          ],
        },
      ],
      ActivationName: "finances",
    },
  ];

  const [dpUrl, setDpUrl] = useState();

  const fetchImage = async () => {
    const x = await GetImage(`Users/${user.id}`);
    setDpUrl(x);
  };

  useEffect(() => {
    fetchImage();
  }, []);

  return (
    <div
      className={`${classes.navContainer} ${
        navToggled && classes.navToggled
      } hidePrint`}
    >
      <div onClick={() => navigate("/")} className={classes.logo}>
        <p>
          rent<span>milloh</span>
        </p>
      </div>
      <div>
        {rolesStream &&
          navItems.map((item) => {
            return (
              <NavItem
                setNavToggled={() => setNavToggled()}
                key={item.Name}
                item={item}
              ></NavItem>
            );
          })}
      </div>
      {user && (
        <SidebarUsersSection
          setNotifToggled={() => setNotifToggled()}
          dpUrl={dpUrl}
          Name={user.Name}
          Designation={user.Designation}
        />
      )}
    </div>
  );
}

const hasAtLeastOneSubItemWithRole = (subItems, userRole, rolesStream) => {
  return subItems.some((subItem) => {
    if (rolesStream[userRole][subItem.Role] === true) {
      return true;
    }

    if (subItem.subItems) {
      return hasAtLeastOneSubItemWithRole(
        subItem.subItems,
        userRole,
        rolesStream
      );
    }

    return false;
  });
};

const NavItem = ({ item, setNavToggled }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [hidden, setHidden] = useState(false);
  const { bn, rolesStream, user } = UserAuth();

  const showItem =
    !item.subItems ||
    hasAtLeastOneSubItemWithRole(item.subItems, user.Role, rolesStream);

  useEffect(() => {
    setHidden(false);
  }, [location]);

  return (
    <>
      <div>
        {showItem && (
          <div
            className={`${classes.item} ${
              location.pathname.includes(item.ActivationName) &&
              `${classes.active}`
            }`}
            onClick={() => {
              item.Link !== undefined && navigate(item.Link);
              // setNavToggled(false);
              setHidden(!hidden);
            }}
          >
            {item.Icon}
            {bn ? item.Name : item.Name_bn}
          </div>
        )}

        <div className={classes.backdrop}>
          {item.subItems !== undefined &&
            item.subItems.map((subItem) => {
              console.log(subItem.subItems);

              let showItem2 = true;
              if (subItem.subItems != undefined) {
                showItem2 =
                  !item.subItems ||
                  hasAtLeastOneSubItemWithRole(
                    subItem.subItems,
                    user.Role,
                    rolesStream
                  );
              }

              if (
                (rolesStream[user.Role][subItem.Role] == true ||
                  subItem.subItems) &&
                showItem2
              ) {
                return (
                  <React.Fragment key={subItem.Name}>
                    {hidden && (
                      <div
                        className={classes.subItem}
                        onClick={() => {
                          subItem.Link !== undefined && navigate(subItem.Link);
                          subItem.Link !== undefined && setNavToggled(false);
                        }}
                      >
                        {!subItem.subItems && (
                          <p
                            className={subItem.subItems && classes.subItemTitle}
                          >
                            {bn ? subItem.Name : subItem.Name_bn}
                          </p>
                        )}
                        {subItem.subItems && (
                          <NavSubItems
                            setNavToggled={() => setNavToggled()}
                            Name={bn ? subItem.Name : subItem.Name_bn}
                            Items={subItem.subItems}
                          />
                        )}
                      </div>
                    )}
                  </React.Fragment>
                );
              }
            })}
        </div>
      </div>
    </>
  );
};

const NavSubItems = ({ Items, Name, setNavToggled }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [hidden, setHidden] = useState(false);
  const { bn, rolesStream, user } = UserAuth();

  useEffect(() => {
    setHidden(false);
  }, [location]);

  return (
    <>
      <div>
        <p
          className={classes.subSubItemTitle}
          onClick={() => {
            setHidden(!hidden);
          }}
        >
          {Name}
        </p>

        <div className={hidden && classes.subSubItemContainer}>
          {Items.map((item) => {
            if (rolesStream[user.Role][item.Role] == true) {
              return (
                <div>
                  {hidden && (
                    <div
                      className={classes.subSubItem}
                      key={item.Name}
                      onClick={() => {
                        item.Link !== undefined && navigate(item.Link);
                        setNavToggled(false);
                      }}
                    >
                      <p className={item.subItems && classes.subItemTitle}>
                        {bn ? item.Name : item.Name_bn}
                      </p>
                    </div>
                  )}
                </div>
              );
            }
          })}
        </div>
      </div>
    </>
  );
};

export default Navbar;
