import React, { useEffect, useState } from "react";
import { getProperties } from "../../firebase";
import PageTitle from "../../components/PageTitle";
import { useNavigate } from "react-router-dom";
import { TableHeader } from "../../components/TableHeader";
import { TableItem } from "../../components/TableItem";
import PrimaryButton from "../../components/PrimaryButton";
import { Loading } from "../../components/Loading";
import TableEdit from "../../components/TableEdit";
import { UserAuth } from "../../AuthContext";

function ViewProperties() {
  const [props, setProps] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user, rolesStream, bn } = UserAuth();
  const navigate = useNavigate();

  function hasMatchingParent(id, arrayOfObjects) {
    for (let i = 0; i < arrayOfObjects.length; i++) {
      if (arrayOfObjects[i].parent === id) {
        return true;
      }
    }
    return false;
  }

  const fetchProperties = async () => {
    const properties = await getProperties();
    properties.map((p, index) => {
      if (
        hasMatchingParent(p.id, user.PropertyAccess) == false &&
        rolesStream[user.Role].Name != "Admin" &&
        rolesStream[user.Role].Name != "SuperAdmin"
      ) {
        delete properties[index];
      }
    });
    console.log(properties);
    setProps(properties);
    setLoading(false);
  };

  useEffect(() => {
    fetchProperties();
  }, [rolesStream]);

  useEffect(() => {
    if (rolesStream && user) {
      if (rolesStream[user.Role][`ViewProperty`] != true) {
        navigate(`/home`);
      }
    }
  }, [rolesStream, user]);

  return (
    <>
      <PageTitle
        title={bn ? "Properties" : "প্রপার্টিজ "}
        subTitle={bn ? "All Properties" : `সব প্রপার্টি`}
      />

      {rolesStream && user && rolesStream[user.Role][`AddProperty`] && (
        <PrimaryButton
          text={bn ? "Add Property" : `অ্যাড প্রপার্টি`}
          handleClick={() => navigate("/properties/add")}
        />
      )}
      <br></br>

      <TableHeader
        headings={[
          [bn ? "Property Name" : `সম্পত্তির নাম`, 7],
          [bn ? "Floors" : `তলা`, 3],
        ]}
      />
      {loading && <Loading />}
      {props.map((p) => {
        return (
          <div style={{ display: "flex" }} key={p.id}>
            <TableItem
              handleClick={() => {
                if (
                  rolesStream &&
                  user &&
                  rolesStream[user.Role][`ViewFloors`]
                ) {
                  navigate(`/properties/${p.id}/floors`);
                }
              }}
              rowData={[
                [p.PropertyName, 7],
                [p.floorCount, 3],
              ]}
            />
            <TableEdit handleClick={() => navigate(`/properties/${p.id}`)} />
          </div>
        );
      })}
    </>
  );
}

export default ViewProperties;
