import React from "react";
import PageTitle from "../../components/PageTitle";
import { TextInputWithLabel } from "../../components/TextInputWithLabel";
import { useState, useEffect } from "react";
import PrimaryButton from "../../components/PrimaryButton";
import { Loading } from "../../components/Loading";
import UploadButton from "../../components/UploadButton";
import BlueItem from "../../components/BlueItem";
import DeleteButton from "../../components/DeleteButton";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { AddLog, db, getFloors, getProperties } from "../../firebase";
import { UserAuth } from "../../AuthContext";
import DropdownWithCheckboxes from "../../components/DropdownWithCheckboxes";
import DropdownWithLabel from "../../components/DropdownWithLabel";
import { getRoles } from "../../firebase";
import ErrorMsg from "../../components/ErrorMsg";
import { useNavigate } from "react-router-dom";
import SuccessAnimation from "../../components/SuccessAnimation";

const AddUser = () => {
  const [data, setData] = useState({ Status: "Active" });
  const { user } = UserAuth();
  const [done, setDone] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [fieldData] = useState([
    {
      label: "Name",
      placeholder: "Enter Name",
      label_bn: "নাম",
      placeholder_bn: "নাম লিখুন",
      type: "text",
    },
    {
      label: "Username",
      placeholder: "Enter Username",
      label_bn: "নাম",
      placeholder_bn: "নাম লিখুন",
      type: "text",
    },
    {
      label: "Designation",
      placeholder: "Enter Designation",
      label_bn: "পদবী",
      placeholder_bn: "পদবী লিখুন",
      type: "text",
    },
    {
      label: "Mobile",
      placeholder: "Enter Mobile",
      label_bn: "মোবাইল",
      placeholder_bn: "মোবাইল নম্বর লিখুন",
      type: "text",
    },
    {
      label: "Email",
      placeholder: "Enter Email",
      label_bn: "ইমেইল",
      placeholder_bn: "ইমেইল লিখুন",
      type: "text",
    },
    {
      label: "Password",
      placeholder: "Enter Password",
      label_bn: "পাসওয়ার্ড",
      placeholder_bn: "পাসওয়ার্ড লিখুন",
      type: "pass",
    },
    {
      label: "Confirm",
      placeholder: "Confirm Password",
      label_bn: "পাসওয়ার্ড",
      placeholder_bn: "পাসওয়ার্ড লিখুন",
      type: "pass",
    },
    {
      label: "Status",
      type: "dropdown",
      items: [
        { Name: "Active", Value: "Active" },
        { Name: "Inactive", Value: "Inactive" },
      ],
    },
    {
      label: "Role",
      type: "dropdown-dynamic",
    },
  ]);

  const [items, setItems] = useState([]);

  async function checkProperties(user) {
    let errors = [];

    // Name validation
    if (!user.hasOwnProperty("Name")) {
      errors.push("Name is missing.");
    } else if (typeof user.Name !== "string") {
      errors.push("Name must be a string.");
    }

    // Email validation
    if (!user.hasOwnProperty("Email")) {
      errors.push("Email is missing.");
    } else if (typeof user.Email !== "string") {
      errors.push("Email must be a string.");
    } else if (
      !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(user.Email)
    ) {
      errors.push("Email is not valid.");
    } else {
      // Check if the same email already exists in the database
      const emailUsers = [];
      const emailRef = await getDocs(
        query(collection(db, "Users"), where("Email", "==", user["Email"]))
      );
      emailRef.forEach((d) => {
        console.log(d.data());
        emailUsers.push(d.data());
      });
      if (emailUsers.length != 0) {
        console.log(emailUsers);
        errors.push("Same Email Exists");
      }
    }

    if (user.hasOwnProperty("Mobile") && isNaN(Number(user[`Mobile`]))) {
      errors.push(`Mobile must be number only.`);
    }

    // Username validation
    if (!user.hasOwnProperty("Username")) {
      errors.push("Username is missing.");
    } else if (typeof user.Username !== "string") {
      errors.push("Username must be a string.");
    } else {
      // Check if the username already exists in the database.
      const users = [];
      const docRef = await getDocs(
        query(
          collection(db, "Users"),
          where("Username", "==", user["Username"])
        )
      );
      docRef.forEach((d) => {
        console.log(d.data());
        users.push(d.data());
      });
      if (users.length != 0) {
        console.log(users);
        errors.push("Same Username Exists");
      }
    }

    // Password validation
    if (!user.hasOwnProperty("Password")) {
      errors.push("Password is missing.");
    } else if (typeof user.Password !== "string") {
      errors.push("Password must be a string.");
    } else if (user.Password != user.Confirm) {
      errors.push(`Passwords do not match`);
    }

    // Role validation
    if (!user.hasOwnProperty("Role")) {
      errors.push("Role is missing.");
    } else if (typeof user.Role !== "string") {
      errors.push("Role must be a string.");
    } else if (user.Role === "Select") {
      errors.push("Role cannot be 'Select'.");
    }

    return errors;
  }

  const handleData = (e, label) => {
    const obj = {};
    obj[label.replace(/\s+/g, "")] = e.target.value;
    setData((prev) => {
      return { ...prev, ...obj };
    });
  };

  const handleChange = async (e, label) => {
    let obj = {};
    obj[label.replace(/\s+/g, "")] = e.target.value;
    setData((prev) => {
      return { ...prev, ...obj };
    });
  };

  const addUser = async () => {
    setLoading(true);
    const errs = await checkProperties(data);
    if (!errs.length == 0) {
      console.log(errs);
      setError(errs);
      setLoading(false);
      return;
    }
    setError([]);

    await addDoc(collection(db, "Users"), {
      ...data,
      Status: "Active",
      LastLogin: "Never",
    });

    await AddLog(`${user.Name} has added a new User: ${data.Name}`);
    setLoading(false);
    setDone(true);
    setTimeout(() => {
      navigate("/users");
    }, [3000]);
  };

  const fetchProperties = async () => {
    const arr = [];

    const properties = await getProperties();
    properties.map(async (p) => {
      let obj = {};
      const floors = await getFloors(p.id);
      const floorArr = [];
      obj["value"] = p.id;
      obj["label"] = p.PropertyName;
      floors.map((f) => {
        floorArr.push({ value: f.id, label: f.FloorName });
      });
      obj["children"] = floorArr;

      arr.push(obj);
    });
    setItems(arr);
  };

  const getRoleItems = (p) => {
    if (p.label === "Role") {
      return rolesData;
    } else {
      return p.items;
    }
  };

  const fetchRoles = async () => {
    const allRoles = await getRoles();
    const roleData = [];
    roleData.push({ Name: "Select", Value: "Select" });
    allRoles.map((r) => {
      roleData.push({ Name: r.Name, Value: r.id });
    });
    console.log(roleData);
    setRolesData(roleData);
  };

  useEffect(() => {
    fetchProperties();
    fetchRoles();
  }, []);

  useEffect(() => {
    console.log(data);
  }, [data]);

  const handlePropertyAccess = async (x) => {
    setData((prev) => {
      return { ...prev, PropertyAccess: x };
    });
  };

  return (
    <>
      <PageTitle title={"Add User"} />
      <div className="flex-column">
        {/* {fieldData.map((f) => {
          return (
            <TextInputWithLabel
              key={f.label}
              label={f.label}
              placeholder={f.placeholder}
              handleChange={handleData}
            ></TextInputWithLabel>
          );
        })} */}

        {rolesData.length > 0 &&
          fieldData.map((p) => {
            if (p.type == "text") {
              return (
                <React.Fragment key={p}>
                  <TextInputWithLabel
                    handleChange={handleData}
                    label={p.label}
                    placeholder={p.placeholder}
                  />
                </React.Fragment>
              );
            } else if (p.type == "dropdown") {
              return (
                <React.Fragment key={p}>
                  <DropdownWithLabel
                    handleChange={handleChange}
                    label={p.label}
                    items={p.items}
                  />
                </React.Fragment>
              );
            } else if (p.type == "dropdown-dynamic") {
              return (
                <React.Fragment key={p}>
                  <DropdownWithLabel
                    handleChange={handleChange}
                    label={p.label}
                    items={getRoleItems(p)}
                  />
                </React.Fragment>
              );
            } else if (p.type == "pass") {
              return (
                <React.Fragment key={p}>
                  <TextInputWithLabel
                    pass={true}
                    handleChange={handleData}
                    label={p.label}
                    placeholder={p.placeholder}
                  />
                </React.Fragment>
              );
            }
          })}
        <DropdownWithCheckboxes
          mainLabel="Property Access"
          items={items}
          handleChange={handlePropertyAccess}
        />

        <br></br>

        {error.map((e) => {
          return <ErrorMsg text={e} />;
        })}
        <br></br>
        {loading && <Loading />}
        {!loading && !done && (
          <PrimaryButton
            text={"Add User"}
            handleClick={addUser}
          ></PrimaryButton>
        )}
        {done && <SuccessAnimation text={"User Added"} />}
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
};

export default AddUser;
