import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserAuth } from "./AuthContext";
import FullScreenLoading from "./components/FullScreenLoading";
import PageAnimation from "./components/PageAnimation";

const ProtectedRoute = ({ children }) => {
  const { user, loading } = UserAuth();
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (!loading) {
      setIsAuthChecked(true);
    }
  }, [loading]);

  if (!isAuthChecked) {
    return <FullScreenLoading />;
  }

  if (!user) {
    return <Navigate to="/login" />;
  } else if (user && user.Status === "Inactive") {
    return <Navigate to="/inactive" />;
  } else {
    return <PageAnimation key={location.pathname}>{children}</PageAnimation>;
  }
};

export default ProtectedRoute;
