import { useEffect, useState } from "react";
import PageTitle from "../../../components/PageTitle";
import { useNavigate } from "react-router-dom";
import {
  GetEntries,
  GetEntriesOfFloor,
  GetEntriesOfProperty,
  getFloors,
  getProperties,
} from "../../../firebase";
import { TableHeader } from "../../../components/TableHeader";
import { Loading } from "../../../components/Loading";
import { TableItem } from "../../../components/TableItem";
import Dropdown from "../../../components/Dropdown";
import UnitsContainer from "../../../components/UnitsContainer";
import { DateInputWithLabel } from "../../../components/DateInputWithLabel";
import ErrorMsg from "../../../components/ErrorMsg";
import UnitTitleStatement from "../../../components/UnitTitleStatement";

const RentStatement = () => {
  const [entries, setEntries] = useState([]);
  const [properties, setProperties] = useState([]);
  const [floors, setFloors] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentProperty, setCurrentProperty] = useState();
  const [currentFloor, setCurrentFloor] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [date, setDate] = useState({
    "Start Date": getCurrentDateAndMonth(),
    "End Date": getCurrentDateAndMonth(),
  });

  const fetchEntries = async () => {
    let entries = [];
    let totall = 0;
    if (currentProperty == "allProperties" && currentFloor == "allFloors") {
      console.log("all");
      entries = await GetEntries(
        new Date(date["Start Date"]),
        new Date(date["End Date"]),
        `Rent`
      );
      entries.map((e) => {
        totall += e.AmountPaid;
      });
    } else if (
      currentFloor == "allFloors" &&
      currentProperty != "allProperties"
    ) {
      console.log("All floors of specific property");
      entries = await GetEntriesOfProperty(
        currentProperty,
        new Date(date["Start Date"]),
        new Date(date["End Date"]),
        `Rent`
      );
      entries.map((e) => {
        totall += e.AmountPaid;
      });
    } else if (
      currentProperty != "allProperties" &&
      currentFloor != "allFloors"
    ) {
      entries = await GetEntriesOfFloor(
        currentProperty,
        currentFloor,
        new Date(date["Start Date"]),
        new Date(date["End Date"]),
        `Rent`
      );
      entries.map((e) => {
        totall += e.AmountPaid;
      });
    }
    console.log(entries);
    setEntries(sortArrayByEntryTime(entries));
    setLoading(false);
    setTotal(totall);
  };

  function sortArrayByEntryTime(array) {
    return array.sort((a, b) => {
      const aTime = a.entryTime?.toDate().getTime();
      const bTime = b.entryTime?.toDate().getTime();

      if (aTime < bTime) {
        return -1;
      }
      if (aTime > bTime) {
        return 1;
      }
      return 0;
    });
  }

  function getCurrentDateAndMonth() {
    const currentDate = formatDate(new Date());
    return currentDate;
  }
  function convertFirebaseTimestampToDate(timestamp) {
    // Convert the Firebase Timestamp to a JavaScript Date object
    const date = timestamp.toDate();

    // Format the date as YYYY-MM-DD
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const handleChangeProperty = async (id) => {
    setLoading(true);
    setCurrentProperty(id);
  };

  const handleChangeFloor = async (id) => {
    setLoading(true);
    setCurrentFloor(id);
  };

  function formatTaka(amount) {
    const takaSymbol = "৳";
    const formattedAmount = new Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 2,
    }).format(amount);
    return `${takaSymbol}${formattedAmount}`;
  }

  const fetchProperties = async () => {
    const docRef = await getProperties();
    const propertyData = [];
    docRef.forEach((d) => {
      propertyData.push({ text: d.PropertyName, value: d.id });
    });
    propertyData.push({ text: "All Properties", value: "allProperties" });
    setProperties(propertyData);
    setCurrentProperty("allProperties");
  };

  const fetchFloors = async (propertyId) => {
    const floorData = [];

    if (propertyId != "allProperties") {
      const docRef = await getFloors(propertyId);
      docRef.forEach((d) => {
        floorData.push({ text: d.FloorName, value: d.id });
      });
    }

    floorData.push({ text: "All Floors", value: "allFloors" });
    setFloors(floorData);
    setCurrentFloor("allFloors");
    setLoading(false);
  };

  useEffect(() => {
    console.log(`Current Promperty: ${currentProperty}`);
    if (currentProperty == "allProperties") {
      setCurrentFloor("allFloors");
    }
    if (currentProperty != undefined) {
      fetchFloors(currentProperty);
    }
  }, [currentProperty]);

  useEffect(() => {
    console.log(`Current Floor: ${currentFloor}`);
    fetchEntries();
  }, [currentFloor, date]);

  useEffect(() => {
    fetchEntries();
    fetchProperties();
  }, []);

  const handleChange = (e, label) => {
    setLoading(true);
    let obj = {};
    obj[label] = e.target.value;
    setDate((prev) => {
      return { ...prev, ...obj };
    });
  };
  function findTextById(arr, id) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].value === id) {
        return arr[i].text;
      }
    }
    return null;
  }

  function convertDateFormat(dateStr) {
    // Create a date object from the string
    let date = new Date(dateStr);

    // Define the month names
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract the month and year from the date
    let month = monthNames[date.getUTCMonth()];
    let year = date.getUTCFullYear();

    // Return the formatted date
    return `${month}, ${year}`;
  }

  function formatDate(date) {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
  }

  return (
    <>
      <PageTitle
        title="Rent Statement"
        subTitle={`of ${
          currentProperty == "allProperties"
            ? "All Properties"
            : findTextById(properties, currentProperty)
        }, ${
          currentFloor == "allFloors"
            ? "All Floors"
            : findTextById(floors, currentFloor)
        } of ${date["Start Date"]} to ${date["End Date"]}`}
      />
      <div className="flex-wrap">
        {currentProperty && properties.length > 0 && (
          <Dropdown
            items={properties}
            label="Property"
            handleChange={handleChangeProperty}
            defaultVal={`allProperties`}
          />
        )}

        {currentFloor &&
          currentProperty != "allProperties" &&
          floors.length > 0 && (
            <Dropdown
              items={floors}
              label="Floor"
              handleChange={handleChangeFloor}
              // defaultVal={floors[0]}
            />
          )}
        <DateInputWithLabel
          small={true}
          label="Start Date"
          placeholder={"Start Date"}
          handleChange={handleChange}
          type="fullDate"
        />
        <DateInputWithLabel
          small={true}
          label="End Date"
          placeholder={"End Date"}
          handleChange={handleChange}
          type="fullDate"
        />
      </div>
      <br></br>

      <div
        style={{
          color: "rgb(0, 136, 194)",
          fontWeight: "bold",
          fontSize: "1.5rem",
        }}
      >{`Total: ${formatTaka(total)}`}</div>

      <br></br>
      {date["Start Date"] > date["End Date"] && (
        <ErrorMsg text="Start Date cannot be greater than End Date" />
      )}
      <br></br>
      <TableHeader
        headings={[
          ["Entry Date", 5],
          ["Unit Info", 8],
          ["Tenant", 5],
          ["Collected By", 4],
          ["Amount", 3],
          ["Type", 2],
          ["Payment Month", 4],
        ]}
      />
      {loading && <Loading />}
      <UnitsContainer>
        {entries.map((p) => {
          return (
            <div style={{ display: "flex" }} key={p.id}>
              <TableItem
                handleClick={() => {
                  navigate(`/entries/${p.id}`);
                }}
                rowData={[
                  [convertFirebaseTimestampToDate(p.entryTime), 5],
                  [
                    <UnitTitleStatement
                      UnitName={p.BusinessName}
                      Property={p.Property}
                      Floor={p.Floor}
                    />,
                    8,
                  ],
                  [p.TenantName, 5],
                  [p.CollectedBy, 4],
                  [p.AmountPaid, 3],
                  [p.paymentType, 2],
                  [convertDateFormat(p.Month), 4],
                ]}
              />
            </div>
          );
        })}
      </UnitsContainer>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
};

export default RentStatement;
