import {
  EventNote,
  LocationCity,
  LocationOn,
  People,
  PeopleOutline,
} from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../../components/PageTitle";
import { TextInputWithLabel } from "../../components/TextInputWithLabel";
import { CheckboxInputWithLabel } from "../../components/CheckboxInputWithLabel";
import FieldSection from "../../components/FieldSection";
import FieldContainer from "../../components/FieldContainer";
import {
  UploadFile,
  UploadImage,
  addRequest,
  addTenant,
  addUnit,
  getFloor,
  getFloorName,
  getProperty,
  getPropertyName,
  getTenant,
  getUnit,
  updateUnit,
} from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { DateInputWithLabel } from "../../components/DateInputWithLabel";
import PrimaryButton from "../../components/PrimaryButton";
import UnitSelector from "../../components/UnitSelector";
import Dropdown from "../../components/Dropdown";
import DropdownWithLabel from "../../components/DropdownWithLabel";
import UploadButton from "../../components/UploadButton";
import DeleteButton from "../../components/DeleteButton";
import BlueItem from "../../components/BlueItem";
import { UserAuth } from "../../AuthContext";
import { serverTimestamp } from "firebase/firestore";
import { Loading } from "../../components/Loading";
import SuccessAnimation from "../../components/SuccessAnimation";
import ErrorMsg from "../../components/ErrorMsg";

function ChangeSecondaryTenant() {
  const [loading, setLoading] = useState();
  const [done, setDone] = useState();
  const { user } = UserAuth();
  const [data, setData] = useState();
  const [error, setError] = useState([]);
  const [tenantDataTemp, setTenantDataTemp] = useState();
  const [currentFloor, setCurrentFloor] = useState();
  const [currentProperty, setCurrentProperty] = useState();
  const [currentFloorName, setCurrentFloorName] = useState();
  const [currentPropertyName, setCurrentPropertyName] = useState();
  const [currentUnit, setCurrentUnit] = useState();
  const { propertyId, floorId } = useParams();
  const navigate = useNavigate();
  const [rent, setRent] = useState();
  const [utility, setUtility] = useState();
  const [sc, setSc] = useState();
  const [unitData, setUnitData] = useState();
  const [tenantData, setTenantData] = useState({});
  const [fieldData] = useState([
    [
      {
        fieldTitle: "Location",
        fieldIcon: <LocationOn />,
        fieldItems: [
          {
            label: "Property",
            type: "text",
          },
          {
            label: "Floor",
            type: "text",
          },
          {
            label: "Block",
            type: "text",
            origin: "unit",
          },
          {
            label: "Unit No",
            type: "text",
            origin: "unit",
          },
          {
            label: "Unit Space",
            type: "text",
            origin: "unit",
          },
        ],
      },
    ],
    [
      //   {
      //     fieldTitle: "Tenant Details",
      //     fieldIcon: <People />,
      //     fieldItems: [
      //       {
      //         label: "Tenant Type",
      //         type: "dropdown",
      //         items: [
      //           { Value: "Primary", Name: "Primary" },
      //           { Value: "Position", Name: "Position" },
      //         ],
      //         origin: "tenant",
      //       },
      //       {
      //         label: "Business Name",
      //         placeholder: "Enter Business Name",
      //         type: "text",
      //         origin: "tenant",
      //       },
      //       {
      //         label: "Tenant Name",
      //         placeholder: "Enter Tenant Name",
      //         type: "text",
      //         origin: "tenant",
      //       },
      //       {
      //         label: "Father's Name",
      //         placeholder: "Enter Father's Name",
      //         type: "text",
      //         origin: "tenant",
      //       },
      //       {
      //         label: "Home Address",
      //         placeholder: "Enter Home Address",
      //         type: "text",
      //         origin: "tenant",
      //       },
      //       {
      //         label: "Mobile Number",
      //         placeholder: "Enter Mobile Number",
      //         type: "text",
      //         origin: "tenant",
      //       },
      //       {
      //         label: "National ID",
      //         placeholder: "Enter National ID",
      //         type: "text",
      //         origin: "tenant",
      //       },
      //       {
      //         label: "Passport Number",
      //         placeholder: "Enter Passport Number",
      //         type: "text",
      //         origin: "tenant",
      //       },
      //       {
      //         label: "Manager Name",
      //         placeholder: "Enter Manager Name",
      //         type: "text",
      //         origin: "tenant",
      //       },
      //       {
      //         label: "Manager Mobile",
      //         placeholder: "Enter Manager Mobile",
      //         type: "text",
      //         origin: "tenant",
      //       },
      //       {
      //         label: "Lease Starting",
      //         placeholder: "Enter Lease Starting",
      //         type: "date",
      //         origin: "tenant",
      //         activation: "Position",
      //       },
      //       {
      //         label: "Lease Ending",
      //         placeholder: "Enter Lease Ending",
      //         type: "date",
      //         origin: "tenant",
      //         activation: "Position",
      //       },
      //       {
      //         label: "Security Deposit",
      //         placeholder: "Enter Security Deposit",
      //         type: "text",
      //         origin: "tenant",
      //         activation: "Position",
      //       },
      //     ],
      //   },
      {
        fieldTitle: "Secondary Tenant Details",
        fieldIcon: <PeopleOutline />,
        fieldItems: [
          {
            label: "Secondary Tenant Name",
            placeholder: "Enter Tenant Name",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Secondary Tenant's Father's Name",
            placeholder: "Enter Father's Name",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Secondary Tenant's Home Address",
            placeholder: "Enter Home Address",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Secondary Tenant's Mobile Number",
            placeholder: "Enter Mobile Number",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Secondary Tenant's National ID",
            placeholder: "Enter National ID",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Secondary Tenant's Passport Number",
            placeholder: "Enter Passport Number",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Secondary Tenant Rent",
            placeholder: "Enter Secondary Tenant Rent",
            type: "text",
            origin: "tenant",
          },
        ],
      },
    ],
    // [
    //   {
    //     fieldTitle: "Emergency Contact",
    //     fieldIcon: <PeopleOutline />,
    //     fieldItems: [
    //       {
    //         label: "Emergency Contact Name",
    //         placeholder: "Emergency Contact Name",
    //         type: "text",
    //         origin: "tenant",
    //       },
    //       {
    //         label: "Emergency Contact Relation",
    //         placeholder: "Enter Emergency Contact Relation",
    //         type: "text",
    //         origin: "tenant",
    //       },
    //       {
    //         label: "Emergency Contact Address",
    //         placeholder: "Enter Emergency Contact Address",
    //         type: "text",
    //         origin: "tenant",
    //       },
    //       {
    //         label: "Emergency Contact Mobile",
    //         placeholder: "Enter Emergency Contact Mobile",
    //         type: "text",
    //         origin: "tenant",
    //       },
    //     ],
    //   },
    // ],
  ]);
  const [imageFile, setImageFile] = useState();
  const [imageFileURL, setImageFileURL] = useState();
  const [files, setFiles] = useState([]);

  const fileHandler = async (event) => {
    const newFile = event.target.files[0]; // Get only the first file
    setImageFile(newFile);

    const reader = new FileReader();

    reader.onload = function (e) {
      setImageFileURL(e.target.result);
    };

    reader.readAsDataURL(newFile);
  };

  const handleClick = async () => {
    console.log(data);
    console.log(tenantData);
    let errs = [];

    errs = await validateTenant(tenantData);
    if (!errs.length == 0) {
      console.log(errs);
      setError(errs);
      setLoading(false);
      return;
    }

    // const tenantId = await addTenant(
    //   currentProperty,
    //   currentFloor,
    //   currentUnit,
    //   tenantData
    // );
    // if (imageFile) {
    //   await UploadImage(imageFile, `Tenants/${tenantId}/`);
    // }
    // files.map(async (f) => {
    //   if (f != undefined) {
    //     await UploadFile(f, `Tenants/${tenantId}/files`);
    //   }
    // });

    // await updateUnit(currentProperty, currentFloor, currentUnit, {
    //   ...data,
    //   currentTenant: tenantData.TenantName,
    //   currentTenantId: tenantId,
    //   currentTenantType: tenantData["TenantType"],
    //   currentBusinessName: tenantData["BusinessName"],
    // });
    setLoading(true);
    await addRequest({
      location: {
        currentProperty: currentProperty,
        currentFloor: currentFloor,
        currentUnit: currentUnit,
      },
      tenantData: {
        ...tenantData,
      },
      BusinessName: tenantDataTemp["BusinessName"],
      type: "Change Secondary Tenant",
      typeId: "changeSecondaryTenant",
      RequestedBy: user["Name"],
      time: serverTimestamp(),
      status: "Pending",
    });

    setLoading(false);
    setDone(true);
    setTimeout(() => {
      navigate("/home");
    }, [3000]);
  };

  const handleData = (e, label) => {
    const obj = {};
    obj[label.replace(/\s+/g, "")] = e.target.value;
    setData((prev) => {
      return { ...prev, ...obj };
    });
  };

  const handleTenantData = (e, label) => {
    const obj = {};
    obj[label.replace(/\s+/g, "")] = e.target.value;
    setTenantData((prev) => {
      return { ...prev, ...obj };
    });
  };

  const fetchUnitData = async () => {
    const unit = await getUnit(currentProperty, currentFloor, currentUnit);
    const prop = await getPropertyName(currentProperty);
    const floor = await getFloorName(currentProperty, currentFloor);
    const tenant = await getTenant(
      currentProperty,
      currentFloor,
      currentUnit,
      unit["currentTenantId"]
    );
    setTenantDataTemp(tenant);

    setCurrentPropertyName(prop);
    setCurrentFloorName(floor);

    setUnitData(unit);
    if (unit["Rent"] == true) {
      setRent(true);
    }
    if (unit["Utility"] == true) {
      setUtility(true);
    }
    if (unit["ServiceCharge"] == true) {
      setSc(true);
    }
  };

  useEffect(() => {
    fetchUnitData();
  }, [currentUnit]);

  async function validateTenant(obj) {
    let errors = [];

    // Check TenantName
    if (!obj.hasOwnProperty("SecondaryTenantName") || obj.TenantName === "") {
      errors.push("Secondary Tenant Name is missing.");
    }

    // Check ManagerMobile, MobileNumber, EmergencyContactMobile, SecondaryTenant'sMobileNumber, Unitratepersq, UtilityRate, PreviousUsage, CommonSpace(ifany), ServiceChargeRate
    const fields = ["SecondaryTenant'sMobileNumber", "SecondaryTenantRent"];

    fields.forEach((field) => {
      if (obj.hasOwnProperty(field) && isNaN(Number(obj[field]))) {
        errors.push(`${field} must be a number.`);
      }
    });

    return errors;
  }

  const handleChange = async (e, label) => {
    // console.log(label);
    if (label == "Rent") {
      setRent(e.target.checked);
      let obj = {};
      obj["Rent"] = e.target.checked;
      setData((prev) => {
        return { ...prev, ...obj };
      });
    } else if (label == "Utility") {
      setUtility(e.target.checked);
      let obj = {};
      obj["Utility"] = e.target.checked;
      setData((prev) => {
        return { ...prev, ...obj };
      });
    } else if (label == "Service Charge") {
      setSc(e.target.checked);
      let obj = {};
      obj["ServiceCharge"] = e.target.checked;
      setData((prev) => {
        return { ...prev, ...obj };
      });
    }
  };

  useEffect(() => {
    console.log(rent);
  }, [rent]);

  useEffect(() => {
    console.log(imageFile);
  }, [imageFile]);

  const fileHandler2 = async (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles([...files, ...newFiles]);
  };

  const handleDeleteFile = async (index) => {
    setFiles((prev) => {
      delete prev[index];
      return [...prev];
    });
  };

  return (
    <>
      <PageTitle title="Change Secondary" image={imageFileURL || ""} />
      {!currentUnit && (
        <UnitSelector
          setCurrentProperty={setCurrentProperty}
          setCurrentFloor={setCurrentFloor}
          setCurrentUnit={setCurrentUnit}
          onlyPosition={true}
          onlyPrimary={true}
        />
      )}

      {unitData &&
        currentUnit &&
        fieldData.map((f) => {
          return (
            <FieldContainer>
              {f.map((x) => {
                return (
                  <FieldSection>
                    <div
                      className="flex-row color-primary"
                      style={{ marginBottom: "0.5rem" }}
                    >
                      {x.fieldIcon}
                      <label>{x.fieldTitle}</label>
                    </div>
                    {x["fieldItems"].map((y) => {
                      if (
                        !y.activation ||
                        (y.activation == "Rent" && rent) ||
                        (y.activation == "Utility" && utility) ||
                        (y.activation == "SC" && sc) ||
                        (y.activation == "Position" &&
                          tenantData["TenantType"] == "Primary")
                      ) {
                        if (y.type == "text") {
                          return (
                            <TextInputWithLabel
                              label={y.label}
                              notEditable={
                                y.placeholder == undefined ? true : false
                              }
                              value={
                                y.label == "Property"
                                  ? currentPropertyName
                                  : y.label == "Floor"
                                  ? currentFloorName
                                  : y.origin == "unit"
                                  ? unitData[y.label.replace(/\s+/g, "")]
                                  : ""
                              }
                              placeholder={y.placeholder}
                              handleChange={
                                y.origin == "unit"
                                  ? handleData
                                  : y.origin == "tenant"
                                  ? handleTenantData
                                  : null
                              }
                            />
                          );
                        } else if (y.type == "checkbox") {
                          return (
                            <CheckboxInputWithLabel
                              label={y.label}
                              handleChange={handleChange}
                              notEditable={false}
                              placeholder={"bleh"}
                              value={
                                y.label == "Rent" && rent == true
                                  ? rent
                                  : y.label == "Utility" && utility == true
                                  ? utility
                                  : y.label == "ServiceCharge" && sc == true
                                  ? sc
                                  : false
                              }
                            />
                          );
                        } else if (y.type == "date") {
                          return (
                            <DateInputWithLabel
                              label={y.label}
                              notEditable={
                                y.placeholder == undefined ? true : false
                              }
                              placeholder={y.placeholder}
                              value={unitData[y.label.replace(/\s+/g, "")]}
                              handleChange={
                                y.origin == "unit"
                                  ? handleData
                                  : y.origin == "tenant"
                                  ? handleTenantData
                                  : null
                              }
                            />
                          );
                        } else if (y.type == "dropdown") {
                          return (
                            <DropdownWithLabel
                              label={y.label}
                              items={y.items}
                              value={tenantData["TenantType"]}
                              defaultVal={tenantData["TenantType"]}
                              handleChange={handleTenantData}
                              notEditable={false}
                            />
                          );
                        }
                      }
                    })}
                  </FieldSection>
                );
              })}
            </FieldContainer>
          );
        })}
      {/* <br></br> */}

      {imageFile && (
        <div style={{ marginBottom: "1rem" }}>{imageFile.name}</div>
      )}
      {/* {currentUnit && (
        <UploadButton
          text={`${imageFile ? "Change Image" : "Add Image"}`}
          single={true}
          fileHandler={fileHandler}
          imageOnly={true}
        />
      )}
      {files.map((f, index) => {
        return (
          <div className="flex-row">
            {f && (
              <>
                <BlueItem text={f.name} />
                <DeleteButton name={index} handleDelete={handleDeleteFile} />
                <br></br>
              </>
            )}
          </div>
        );
      })}
      {files.length > 0 && <br></br>}
      {currentUnit && (
        <UploadButton
          text={"Add Files"}
          fileHandler={fileHandler2}
          imageOnly={false}
        />
      )} */}

      {loading && <Loading />}
      {done && <SuccessAnimation text="Request Sent" />}

      <br></br>
      {error.map((e) => {
        return <ErrorMsg text={e} />;
      })}
      <br></br>
      {!loading && !done && currentUnit && (
        <PrimaryButton text="Send Request" handleClick={() => handleClick()} />
      )}

      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
}

export default ChangeSecondaryTenant;
