import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import PrimaryButton from "../../components/PrimaryButton";
import { TextInputWithLabel } from "../../components/TextInputWithLabel";
import { db, getFloor, updateFloor } from "../../firebase";
import { addDoc, collection, doc, getDocs } from "firebase/firestore";
import SuccessAnimation from "../../components/SuccessAnimation";
import { Loading } from "../../components/Loading";
import ErrorMsg from "../../components/ErrorMsg";

function AddFloor() {
  const { propertyId, floorId } = useParams();
  const [floorData, setFloorData] = useState({});
  const [isEditing, setIsEditing] = useState();
  const [error, setError] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [fieldData] = useState([
    {
      label: "Floor Name",
      placeholder: "Enter Floor Name",
      label_bn: "প্রপার্টির নাম",
      placeholder_bn: "প্রপার্টির নাম লিখুন",
    },
    {
      label: "Area",
      placeholder: "Enter Area",
      label_bn: "প্রপার্টির আকার",
      placeholder_bn: "প্রপার্টির আকার লিখুন",
    },
  ]);

  // const handleUpdate = async () => {
  //   if (isEditing) {
  //     setIsEditing(false);
  //     await updateFloor(propertyId, floorId, floorData);
  //     console.log("edited");
  //   } else {
  //     setIsEditing(true);
  //   }
  // };

  const handleChange = async (e, label) => {
    let obj = {};
    obj[label.replace(/\s+/g, "")] = e.target.value;
    setFloorData((prev) => {
      return { ...prev, ...obj };
    });
  };

  async function checkProperties(obj) {
    let errors = [];

    if (!obj.hasOwnProperty("FloorName")) {
      errors.push("Floor Name is missing.");
    }

    if (isNaN(Number(obj.Area))) {
      errors.push("Floor Area must be a number");
    }

    return errors;
  }

  const handleClick = async () => {
    setLoading(true);

    const errs = await checkProperties(floorData);
    if (!errs.length == 0) {
      console.log(errs);
      setError(errs);
      setLoading(false);
      return;
    }
    setError([]);

    const docRef = await getDocs(
      collection(db, `Properties/${propertyId}/Floors`)
    );
    const fl = [];
    docRef.forEach((d) => {
      fl.push(d.data());
    });
    await addDoc(collection(db, `Properties/${propertyId}/Floors`), {
      ...floorData,
      Order: fl.length + 1,
    });
    setLoading(false);
    setDone(true);
    setTimeout(() => {
      navigate(`/properties/${propertyId}/floors`);
    }, [3000]);
  };

  // const fetchFloor = async () => {
  //   const floor = await getFloor(propertyId, floorId);
  //   console.log(floor);
  //   setFloorData(floor);
  // };

  useEffect(() => {
    console.log(propertyId);
    console.log(floorId);
    // fetchFloor();
  }, [propertyId, floorId]);

  return (
    <>
      <PageTitle title={"Add Floor"} />
      {
        <div className="flex-column">
          {fieldData.map((p) => {
            return (
              <React.Fragment key={p}>
                {/* <label>{p.label}</label>
                <label>{propertyData[p.label.replace(/\s+/g, "")]}</label> */}
                <TextInputWithLabel
                  handleChange={handleChange}
                  label={p.label}
                  placeholder={p.placeholder}
                  notEditable={false}
                  value={floorData[p.label.replace(/\s+/g, "")]}
                />
              </React.Fragment>
            );
          })}
          <br></br>
          {error.map((e) => {
            return <ErrorMsg text={e} />;
          })}
          {!loading && !done && (
            <PrimaryButton
              text={`Add Floor`}
              handleClick={() => {
                handleClick();
              }}
            />
          )}
          {loading && <Loading />}
          {done && <SuccessAnimation text="Added" />}
        </div>
      }
    </>
  );
}

export default AddFloor;
