import { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import classes from "../../styles/FloorView.module.css";
import { Rnd } from "react-rnd";
import { useNavigate } from "react-router-dom";
import stairIcon from "../../assets/icons/Stairs.svg";
import liftIcon from "../../assets/icons/Lift.svg";
import washroomIcon from "../../assets/icons/washroom.svg";
import deleteIcon from "../../assets/icons/dlt.svg";
import corridorIcon from "../../assets/icons/corridor.svg";
import {
  addFloorItem,
  deleteFloorItem,
  getFloorItems,
  getFloors,
  getProperties,
  getUnits,
  updateFloorItem,
  updateUnit,
} from "../../firebase";
import Dropdown from "../../components/Dropdown";
import TextInputWithDropdown from "../../components/TextInputWithDropdown";
import PrimaryButton from "../../components/PrimaryButton";
import { UserAuth } from "../../AuthContext";

const FloorView = () => {
  const [pos, setPos] = useState({});
  const navigate = useNavigate();
  const [resizing, setResizing] = useState(false);
  const [search, setSearch] = useState("");
  const [searchState, setSearchState] = useState("Block");
  const [currentFloor, setCurrentFloor] = useState();
  const [currentProp, setCurrentProp] = useState();
  const [properties, setProperties] = useState([]);
  const [floors, setFloors] = useState([]);
  const [object, setObject] = useState();
  const [floorObjects] = useState([
    { text: "Lift", value: "lift" },
    { text: "Stair", value: "stair" },
    { text: "Corridor", value: "corridor" },
    { text: "Washroom", value: "washroom" },
  ]);
  const { user, rolesStream } = UserAuth();

  const updateShopFloorData = async (id, x, y, width, height, name) => {
    try {
      if (
        name != "lift" &&
        name != "stair" &&
        name != "corridor" &&
        name != "washroom" &&
        name != "openSpace"
      ) {
        const obj = {};
        obj["x"] = x;
        obj["y"] = y;
        obj["width"] = width;
        obj["height"] = height;
        await updateUnit(currentProp, currentFloor, id, {
          floorData: obj,
        });
        fetchUnits(currentProp, currentFloor);
      } else {
        const obj = {};
        obj["x"] = x;
        obj["y"] = y;
        obj["width"] = width;
        obj["height"] = height;
        console.log(obj);
        await updateFloorItem(currentProp, currentFloor, id, {
          floorData: obj,
        });
        // await updateDoc(doc(db, "floorItems", id), {
        //   floorData: obj,
        // });
        fetchUnits(currentProp, currentFloor);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addObject = async (item) => {
    await addFloorItem(currentProp, currentFloor, item);
    // setPos({});
    fetchUnits(currentProp, currentFloor);
  };

  const deleteFloorObject = async (id) => {
    console.log(id);
    await deleteFloorItem(currentProp, currentFloor, id);
    fetchUnits(currentProp, currentFloor);
  };

  const handleSearchChange = (e, label) => {
    setSearch(e.target.value);
  };

  const fetchProperties = async () => {
    const docRef = await getProperties();
    const propertyData = [];
    docRef.forEach((d) => {
      propertyData.push({ text: d.PropertyName, value: d.id });
    });
    setCurrentProp(propertyData[0].value);
    setProperties(propertyData);
  };

  const fetchFloors = async (propertyId) => {
    const docRef = await getFloors(propertyId);
    console.log(docRef);
    const floorData = [];
    docRef.forEach((d) => {
      floorData.push({ text: d.FloorName, value: d.id });
    });
    setFloors(floorData);
    setCurrentFloor(floorData[0].value);
  };

  const fetchUnits = async (propertyId, floorId) => {
    const units = await getUnits(propertyId, floorId);
    const objectsObj = await getFloorItems(propertyId, floorId);
    let unitsObj = {};
    units.forEach((u) => {
      unitsObj[u.id] = {
        shopNo: u.UnitNo,
        shopName: u.currentBusinessName,
        block: u.Block,
        TenantType: u.currentTenantType,
        unassigned: u.unassigned,
        x: u.floorData.x,
        y: u.floorData.y,
        width: u.floorData.width,
        height: u.floorData.height,
        id: u.id,
      };
    });
    objectsObj.forEach((o) => {
      if (o.floorData != undefined) {
        unitsObj[o.id] = {
          x: o.floorData["x"],
          y: o.floorData["y"],
          width: o.floorData["width"],
          height: o.floorData["height"],
          id: o.id,
          shopName: o.type,
        };
      } else {
        unitsObj[o.id] = {
          x: 0,
          y: 0,
          width: 100,
          height: 100,
          id: o.id,
          shopName: o.type,
        };
      }
    });
    setPos(unitsObj);
  };

  const assign = async (id) => {
    await updateUnit(currentProp, currentFloor, id, {
      unassigned: false,
    });
    // setPos({});
    fetchUnits(currentProp, currentFloor);
  };

  const handleChangeProperty = async (id) => {
    setCurrentProp(id);
  };
  const handleChangeFloor = async (id) => {
    setCurrentFloor(id);
  };

  const handleChangeObject = async (id) => {
    setObject(id);
  };

  useEffect(() => {
    fetchProperties();
  }, []);

  useEffect(() => {
    fetchFloors(currentProp);
  }, [currentProp]);
  useEffect(() => {
    fetchUnits(currentProp, currentFloor);
  }, [currentFloor]);

  useEffect(() => {
    console.log(pos);
  }, [search]);

  return (
    <>
      <PageTitle title="Floor View" />
      <div>
        <div className="flex-row">
          {properties.length > 0 && (
            <Dropdown
              items={properties}
              label="Property"
              handleChange={handleChangeProperty}
            />
          )}

          {floors.length > 0 && (
            <Dropdown
              items={floors}
              label="Floor"
              handleChange={handleChangeFloor}
            />
          )}

          <TextInputWithDropdown
            label="Search"
            placeholder="Search"
            setSearchState={setSearchState}
            handleChange={handleSearchChange}
            name={true}
          />
        </div>
        <br></br>
        {rolesStream && user && rolesStream[user.Role][`FloorViewEdit`] && (
          <PrimaryButton
            text={!resizing ? "Edit" : "Save"}
            handleClick={() => setResizing(!resizing)}
          />
        )}
        <br></br>
        {resizing && (
          <>
            <div className="flex-row">
              <Dropdown
                items={floorObjects}
                label="Object"
                handleChange={handleChangeObject}
              />
              <button
                onClick={() => {
                  addObject(object);
                }}
                className={classes.floorViewObjButton}
              >
                Add Object
              </button>
            </div>

            <br></br>
          </>
        )}
        {resizing && (
          <p
            style={{
              color: "#929292",
              marginBottom: "0.5rem",
            }}
          >
            Unassigned Units:
          </p>
        )}
        {resizing && (
          <div
            className="flex-row"
            style={{
              overflow: "auto",
              width: "72.5vw",
              height: "120px",
              border: "1px solid #C5C5C5",
              borderRadius: "0.8rem",
            }}
          >
            {Object.keys(pos).map((p) => {
              if (
                pos[p].unassigned == true &&
                pos[p].shopName != "stair" &&
                pos[p].shopName != "lift" &&
                pos[p].shopName != "washroom" &&
                pos[p].shopName != "corridor" &&
                pos[p].shopName != "openSpace"
              ) {
                return (
                  <div className="flex-column">
                    <div className={classes.unassignedShop}>
                      <div>#{pos[p].shopNo}</div>
                      <div>Block: {pos[p].block}</div>
                      <div>{pos[p].shopName}</div>
                    </div>
                    <button
                      onClick={() => assign(pos[p].id)}
                      className={classes.unassignedButton}
                    >
                      Add
                    </button>
                  </div>
                );
              }
            })}
          </div>
        )}
        {resizing && <br></br>}
        <div
          style={{
            overflow: "auto",
            width: "72.5vw",
            height: "500px",
            border: "1px solid #C5C5C5",
            borderRadius: "0.8rem",
          }}
        >
          <div className={classes.mainView} style={{ position: "relative" }}>
            <>
              {Object.keys(pos).map((p) => {
                if (!pos[p].unassigned) {
                  console.log("yes");
                  return (
                    <div>
                      <Rnd
                        style={{
                          borderColor: `${
                            pos[p].TenantType != undefined &&
                            pos[p].TenantType != "Vacant"
                              ? "#0088C2"
                              : "#C5C5C5"
                          }`,
                          backgroundColor: `${
                            pos[p].TenantType != undefined &&
                            pos[p].TenantType != "Vacant"
                              ? "#edf9ff"
                              : "#ffffff"
                          }`,
                        }}
                        onClick={() => {
                          if (
                            !resizing &&
                            pos[p].shopName != "stair" &&
                            pos[p].shopName != "lift" &&
                            pos[p].shopName != "washroom" &&
                            pos[p].shopName != "corridor" &&
                            pos[p].shopName != "openSpace"
                          ) {
                            navigate(
                              `/properties/${currentProp}/floors/${currentFloor}/units/${pos[p].id}`
                            );
                          }
                        }}
                        className={`${classes.shop} ${
                          pos[p].shopName == "stair" ||
                          pos[p].shopName == "lift" ||
                          pos[p].shopName == "washroom" ||
                          pos[p].shopName == "corridor" ||
                          pos[p].shopName == "openSpace"
                            ? classes.above
                            : ``
                        } ${
                          (search != "" &&
                            search != undefined &&
                            searchState == "UnitNo" &&
                            pos[p].shopNo == search) ||
                          (searchState == "Block" && pos[p].block == search) ||
                          (searchState == "UnitName" &&
                            search.length > 0 &&
                            pos[p].shopName
                              ?.toString()
                              .startsWith(search?.toString()))
                            ? classes.moreAbove
                            : ``
                        }`}
                        disableDragging={!resizing}
                        enableResizing={resizing}
                        dragGrid={[10, 10]}
                        resizeGrid={[10, 10]}
                        bounds={`.${classes.mainView}`}
                        position={{ x: pos[p].x, y: pos[p].y }}
                        default={{
                          x: pos[p].x,
                          y: pos[p].y,
                          width: pos[p].width,
                          height: pos[p].height,
                        }}
                        minWidth={50}
                        minHeight={50}
                        onDragStop={(e, d) => {
                          const obj = {};
                          const subObj = pos[p];

                          subObj["x"] = d.x;
                          subObj["y"] = d.y;
                          obj[p] = subObj;
                          console.log(obj);
                          console.log(pos[p].width);
                          updateShopFloorData(
                            pos[p].id,
                            d.x,
                            d.y,
                            pos[p].width,
                            pos[p].height,
                            pos[p].shopName
                          );
                          setPos((prev) => {
                            return { ...prev, ...obj };
                          });
                        }}
                        onResizeStop={(e, direction, ref, delta, position) => {
                          const obj = {};
                          const subObj = pos[p];

                          subObj["width"] = ref.style.width;
                          subObj["height"] = ref.style.height;
                          obj[p] = { ...subObj, ...position };
                          console.log(obj);

                          updateShopFloorData(
                            pos[p].id,
                            pos[p].x,
                            pos[p].y,
                            ref.style.width,
                            ref.style.height,
                            pos[p].shopName
                          );
                          setPos((prev) => {
                            return { ...prev, ...obj };
                          });
                        }}
                      >
                        <div
                          className={`${classes.shopDet} ${
                            ((search != "" &&
                              search != undefined &&
                              searchState == "UnitNo" &&
                              pos[p].shopNo == search) ||
                              (searchState == "Block" &&
                                pos[p].block == search) ||
                              (searchState == "UnitName" &&
                                search.length > 0 &&
                                pos[p].shopName
                                  ?.toString()
                                  .startsWith(search?.toString()))) &&
                            `${classes.green}`
                          }`}
                        >
                          {pos[p].shopName != "lift" &&
                            pos[p].shopName != "stair" &&
                            pos[p].shopName != "washroom" &&
                            pos[p].shopName != "corridor" &&
                            pos[p].shopName != "openSpace" &&
                            `#${pos[p].shopNo}`}
                          {pos[p].shopName == "openSpace" && (
                            <div>Open Space</div>
                          )}
                          <br></br>

                          {pos[p].shopName != "lift" &&
                            pos[p].shopName != "stair" &&
                            pos[p].shopName != "washroom" &&
                            pos[p].shopName != "corridor" &&
                            pos[p].shopName != "openSpace" &&
                            `Block: ${pos[p].block}`}
                          <br></br>
                          {pos[p].shopName == "lift" && (
                            <img
                              style={{ height: "1.5rem" }}
                              src={liftIcon}
                            ></img>
                          )}
                          {pos[p].shopName == "stair" && (
                            <img
                              style={{ height: "1.5rem" }}
                              src={stairIcon}
                            ></img>
                          )}
                          {pos[p].shopName == "corridor" && (
                            <img
                              style={{ height: "1.5rem" }}
                              src={corridorIcon}
                            ></img>
                          )}
                          {pos[p].shopName == "washroom" && (
                            <img
                              style={{ height: "1.5rem", opacity: "0.4" }}
                              src={washroomIcon}
                            ></img>
                          )}

                          {pos[p].shopName != "lift" &&
                            pos[p].shopName != "stair" &&
                            pos[p].shopName != "washroom" &&
                            pos[p].shopName != "corridor" &&
                            pos[p].shopName != "openSpace" &&
                            `${pos[p].shopName}`}

                          {(pos[p].shopName == "lift" ||
                            pos[p].shopName == "stair" ||
                            pos[p].shopName == "washroom" ||
                            pos[p].shopName == "corridor" ||
                            pos[p].shopName == "openSpace") &&
                            resizing && (
                              <button
                                className={`${classes.dltIcon}`}
                                onClick={() => deleteFloorObject(pos[p].id)}
                              >
                                <img src={deleteIcon}></img>
                              </button>
                            )}
                        </div>
                      </Rnd>
                    </div>
                  );
                }
              })}
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default FloorView;
