import classes from "../styles/TableHeader.module.css";

export const TableHeader = ({ headings }) => {
  return (
    <div className={classes.headerContainer}>
      {headings.map((h) => {
        return (
          <>
            <label style={{ width: `${h[1]}rem` }}>{h[0]}</label>
            <div className={classes.divider}></div>
          </>
        );
      })}
    </div>
  );
};
