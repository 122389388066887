import classes from "../styles/FieldContainer.module.css";

const FieldContainer = ({ children }) => {
  return (
    <>
      <div className={classes.fieldContainer}>{children}</div>
    </>
  );
};

export default FieldContainer;
