import { useState } from "react";
import { getFloors, getProperties, getUnits } from "../firebase";
import { useEffect } from "react";
import classes from "../styles/UnitSelector.module.css";
import { UserAuth } from "../AuthContext";

const UnitSelector = ({
  setCurrentProperty,
  setCurrentFloor,
  onlyVacant,
  onlyPrimary,
  onlyPosition,
  setCurrentUnit,
}) => {
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState();
  const [floors, setFloors] = useState();
  const [selectedFloor, setSelectedFloor] = useState();
  const [units, setUnits] = useState();
  const [selectedUnit, setSelectedUnit] = useState();
  const { user, rolesStream, bn } = UserAuth();

  function hasMatchingParent(id, arrayOfObjects) {
    for (let i = 0; i < arrayOfObjects.length; i++) {
      if (arrayOfObjects[i].parent === id) {
        return true;
      }
    }
    return false;
  }

  function hasMatchingFloor(id, arrayOfObjects) {
    for (let i = 0; i < arrayOfObjects.length; i++) {
      if (arrayOfObjects[i].floor === id) {
        return true;
      }
    }
    return false;
  }

  const fetchProperties = async () => {
    const properties = await getProperties();
    properties.map((p, index) => {
      if (
        hasMatchingParent(p.id, user.PropertyAccess) == false &&
        rolesStream[user.Role].Name != "Admin" &&
        rolesStream[user.Role].Name != "SuperAdmin"
      ) {
        delete properties[index];
      }
    });
    setProperties(properties);
  };

  const fetchFloors = async () => {
    const floors = await getFloors(selectedProperty);
    floors.map((p, index) => {
      if (
        hasMatchingFloor(p.id, user.PropertyAccess) == false &&
        rolesStream[user.Role].Name != "Admin" &&
        rolesStream[user.Role].Name != "SuperAdmin"
      ) {
        delete floors[index];
      }
    });
    setFloors(floors);
  };

  const fetchUnits = async () => {
    const units = await getUnits(selectedProperty, selectedFloor);

    setUnits(units);
  };

  useEffect(() => {
    fetchProperties();
  }, [rolesStream]);

  useEffect(() => {
    fetchFloors();
  }, [selectedProperty]);

  useEffect(() => {
    fetchUnits();
  }, [selectedFloor]);

  return (
    <>
      {!selectedProperty && (
        <div>
          <div>
            {bn ? `Please Select your Property` : `প্রপার্টি সিলেক্ট করুন`}
          </div>
          <br></br>
          {properties.map((p) => {
            return (
              <div
                className={classes.item}
                onClick={() => {
                  setSelectedProperty(p.id);
                  setCurrentProperty(p.id);
                }}
              >
                {p.PropertyName}
              </div>
            );
          })}
        </div>
      )}

      {!selectedFloor && selectedProperty && (
        <div>
          <div>{bn ? `Please Select your Floor` : `ফ্লর সিলেক্ট করুন `}</div>
          <br></br>
          <div
            className={classes.back}
            onClick={() => {
              setSelectedProperty(null);
              setCurrentProperty(null);
            }}
          >
            {bn ? `Back` : `ব্যাক`}
          </div>
          {floors.map((p) => {
            return (
              <div
                className={classes.item}
                onClick={() => {
                  setSelectedFloor(p.id);
                  setCurrentFloor(p.id);
                }}
              >
                {p.FloorName}
              </div>
            );
          })}
        </div>
      )}

      {!selectedUnit && selectedFloor && selectedProperty && (
        <div>
          <div>{bn ? `Please Select your Unit` : `ইউনিট সিলেক্ট করুন `}</div>
          <br></br>
          <div
            className={classes.back}
            onClick={() => {
              setSelectedFloor(null);
              setCurrentFloor(null);
            }}
          >
            {bn ? `Back` : `ব্যাক`}
          </div>
          {units.map((p) => {
            if (
              (!onlyPosition && !onlyVacant && !onlyPrimary) ||
              (onlyPosition && p.currentTenantType == "Position") ||
              (onlyPrimary && p.currentTenantType == "Primary") ||
              (onlyVacant && p.currentTenantType == "Vacant")
            ) {
              return (
                <div
                  className={classes.item}
                  onClick={() => {
                    setSelectedUnit(p.id);
                    setCurrentUnit(p.id);
                  }}
                >
                  {`Block: ${p.Block} Unit:${p.UnitNo}`}
                </div>
              );
            }
          })}
        </div>
      )}
    </>
  );
};

export default UnitSelector;
