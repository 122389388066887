import { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import { TextAreaInput } from "../../components/TextAreaInput";
import BlueItem from "../../components/BlueItem";
import DeleteButton from "../../components/DeleteButton";
import UploadButton from "../../components/UploadButton";
import { UserAuth } from "../../AuthContext";
import PrimaryButton from "../../components/PrimaryButton";
import { UploadFile, addRequest } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { serverTimestamp } from "firebase/firestore";
import { Loading } from "../../components/Loading";
import SuccessAnimation from "../../components/SuccessAnimation";

const OtherComplain = () => {
  const [files, setFiles] = useState([]);
  const [data, setData] = useState();
  const [done, setDone] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { bn, user } = UserAuth();

  const handleData = (e, label) => {
    setData(e.target.value);
  };
  const fileHandler = async (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles([...files, ...newFiles]);
  };
  const handleDeleteFile = async (index) => {
    setFiles((prev) => {
      delete prev[index];
      return [...prev];
    });
  };

  const sendComplain = async () => {
    setLoading(true);
    const requestId = await addRequest({
      Complain: data,
      type: "Other Complain",
      typeId: "otherComplain",
      RequestedBy: user["Name"],
      time: serverTimestamp(),
      status: "Pending",
    });
    console.log(requestId);

    // await AddLog(`${user.Name} has added a new Property: ${data.PropertyName}`);
    files.map(async (f) => {
      if (f != undefined) {
        await UploadFile(f, `Complains/${requestId}`);
      }
    });
    setLoading(false);
    setDone(true);
    setTimeout(() => {
      navigate("/home");
    }, [3000]);
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <>
      <PageTitle title="Other Complain" />
      <div className="flex-column">
        <TextAreaInput
          label={"Complain"}
          placeholder={"Enter Complain"}
          //   label_bn={f.label_bn}
          //   placeholder_bn={f.placeholder_bn}
          handleChange={handleData}
        ></TextAreaInput>
        <br></br>
        {files.map((f, index) => {
          return (
            <div className="flex-row">
              {f && (
                <>
                  <BlueItem text={f.name} />
                  <DeleteButton name={index} handleDelete={handleDeleteFile} />
                  <br></br>
                </>
              )}
            </div>
          );
        })}
        <br></br>
        {!done && !loading && (
          <>
            <UploadButton
              text={bn ? "Add Files" : "অ্যাড ফাইল"}
              fileHandler={fileHandler}
            />

            <PrimaryButton text="Send" handleClick={sendComplain} />
          </>
        )}
      </div>
      {loading && <Loading />}
      {done && <SuccessAnimation text="Complain Sent" />}
    </>
  );
};

export default OtherComplain;
