import { UserAuth } from "../AuthContext";
import classes from "../styles/TextLabel.module.css";

const TextLabel = ({ label, value, label_bn }) => {
  const { bn } = UserAuth();
  return (
    <>
      <div className={`${classes.inputContainer} flex-column`}>
        <label>
          {bn ? label : label_bn}
          <span>{value ? `: ${value}` : null}</span>
        </label>
      </div>
    </>
  );
};

export default TextLabel;
