import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../AuthContext";
import PrimaryButton from "../components/PrimaryButton";
import classes from "../styles/Login.module.css";
import { Loading } from "../components/Loading";
import ErrorMsg from "../components/ErrorMsg";
import emailjs from "@emailjs/browser";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase";

function Forgot() {
  const { Login, setUser } = UserAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [sent, setSent] = useState();
  const [done, setDone] = useState(false);
  const [id, setId] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState();
  const [otp, setOtp] = useState();
  const [err, setErr] = useState("");

  const [loading, setLoading] = useState();

  const handleLogin = async () => {
    setLoading(true);
    const docRef = await getDocs(
      query(collection(db, "Users"), where("Email", "==", username))
    );
    const userArr = [];
    docRef.forEach((d) => {
      userArr.push(d.data());
      setId(d.id);
      console.log(d.data());
    });
    if (userArr.length == 0) {
      setLoading(false);
      setErr("Email doesn't exist");
    } else {
      setErr("");
      await sendEmail();
      setLoading(false);
    }
  };

  const sendEmail = async () => {
    const otp = Math.floor(Math.random() * 8000) + 1000;
    setOtp(otp);
    emailjs
      .send(
        "service_oavecmj",
        "template_doi48kh",
        {
          to_email: username, // replace with the recipient email address
          from_name: "RentMilloh",
          from_email: "rentmillohapp@gmail.com",
          message: `${otp}`,
        },
        "IEcI_EdwGEPpGLmm5"
      )
      .then(
        (result) => {
          setSent(true);
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const handleNewPass = async () => {
    setLoading(true);
    if (newPassword == confirmNewPassword) {
      await updateDoc(doc(db, "Users", id), {
        Password: newPassword,
      });
      navigate("/login");
    } else {
      setErr("Passwords do not match!");
    }
  };

  useEffect(() => {
    if (otp == password && otp != undefined) {
      console.log("yes");
      setDone(true);
    }
  }, [password]);

  return (
    <div className={classes.container}>
      <p>
        rent<span>milloh</span>
      </p>
      {!sent && (
        <input
          type="text"
          placeholder="Email"
          onChange={(e) => setUsername(e.target.value)}
          onKeyDown={(e) => {
            if (e.key == "Enter") {
              handleLogin();
            }
          }}
        ></input>
      )}
      {sent && done == false && (
        <input
          type="text"
          placeholder="OTP"
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key == "Enter") {
              handleLogin();
            }
          }}
        ></input>
      )}
      {done && (
        <>
          <input
            type="password"
            placeholder="New Password"
            onChange={(e) => setNewPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                handleNewPass();
              }
            }}
          ></input>
          <input
            type="password"
            placeholder="Confirm New Password"
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                handleNewPass();
              }
            }}
          ></input>
        </>
      )}
      <br></br>
      {err.length != 0 && <ErrorMsg text={err} />}
      {loading && <Loading />}
      {!loading && !sent && (
        <PrimaryButton text={"Send Code"} handleClick={handleLogin} />
      )}
      {done == true && (
        <PrimaryButton text={"Change Password"} handleClick={handleNewPass} />
      )}
      {!loading && <Link to={"/login"}>Back to Login</Link>}
    </div>
  );
}

export default Forgot;
