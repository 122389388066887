import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import PrimaryButton from "../../components/PrimaryButton";
import {
  getFloor,
  getFloors,
  getProperties,
  getProperty,
  getUnits,
} from "../../firebase";
import { TableItem } from "../../components/TableItem";
import { TableHeader } from "../../components/TableHeader";
import UnitsContainer from "../../components/UnitsContainer";
import Dropdown from "../../components/Dropdown";
import { Loading } from "../../components/Loading";
import Table from "../../components/Table";
import UnitTitle from "../../components/UnitTitle";
import TextInputWithDropdown from "../../components/TextInputWithDropdown";
import { UserAuth } from "../../AuthContext";

function ViewBlocks() {
  const navigate = useNavigate();
  const { propertyId, floorId } = useParams();
  const [units, setUnits] = useState([]);
  const [floors, setFloors] = useState([]);
  const [properties, setProperties] = useState([]);
  const [currentProperty, setCurrentProperty] = useState();
  const [currentFloor, setCurrentFloor] = useState();
  const [searchData, setSearchData] = useState("");
  const [searchState, setSearchState] = useState("Block");
  const [loading, setLoading] = useState(true);
  const { bn } = UserAuth();

  const fetchProperties = async () => {
    const docRef = await getProperties();
    const propertyData = [];
    docRef.forEach((d) => {
      propertyData.push({ text: d.PropertyName, value: d.id });
    });
    propertyData.push({ text: "All Properties", value: "allProperties" });
    setProperties(propertyData);
  };

  const fetchFloors = async (propertyId) => {
    const docRef = await getFloors(propertyId);
    console.log(docRef);
    const floorData = [];
    docRef.forEach((d) => {
      floorData.push({ text: d.FloorName, value: d.id });
    });
    floorData.push({ text: "All Floors", value: "allFloors" });
    setFloors(floorData);
  };

  const fetchSpecificFloorUnits = async (propertyId, floorId) => {
    let blocksObj = {};
    const [prop, floor, unitsArr] = await Promise.all([
      getProperty(propertyId),
      getFloor(propertyId, floorId),
      getUnits(propertyId, floorId),
    ]);

    if (unitsArr.length == 0) {
      navigate(`/properties/${propertyId}/floors/${floorId}/units/b/noBlocks`);
    }

    unitsArr.map((u) => {
      blocksObj[u.Block] = "";
    });

    let sortedBlock = {};
    Object.keys(blocksObj)
      .sort()
      .forEach(function (key) {
        sortedBlock[key] = blocksObj[key];
      });

    const propertyArr = [
      {
        PropertyName: prop.PropertyName,
        id: prop.id,
        Floors: [
          {
            FloorName: floor.FloorName,
            id: floor.id,
            Units: unitsArr,
            Blocks: sortedBlock,
          },
        ],
      },
    ];

    setUnits(propertyArr);
    setLoading(false);
  };

  const fetchSpecificPropertyUnits = async (propertyId) => {
    let blocksObj = {};
    const [prop, floors] = await Promise.all([
      getProperty(propertyId),
      getFloors(propertyId),
    ]);

    const floorArrPromises = floors.map(async (f) => {
      const unitsArr = await getUnits(propertyId, f.id);
      if (unitsArr.length == 0) {
        navigate(
          `/properties/${propertyId}/floors/${floorId}/units/b/noBlocks`
        );
      }
      unitsArr.map((u) => {
        blocksObj[u.Block] = "";
      });

      let sortedBlock = {};
      Object.keys(blocksObj)
        .sort()
        .forEach(function (key) {
          sortedBlock[key] = blocksObj[key];
        });
      return {
        FloorName: f.FloorName,
        id: f.id,
        Units: unitsArr,
        Blocks: sortedBlock,
      };
    });

    const floorsArr = await Promise.all(floorArrPromises);

    const propertyArr = [
      {
        PropertyName: prop.PropertyName,
        id: prop.id,
        Floors: floorsArr,
      },
    ];

    setUnits(propertyArr);
    setLoading(false);
  };

  const fetchAllUnits = async () => {
    let blocksObj = {};
    const props = await getProperties();
    const propertyArrPromises = props.map(async (p) => {
      const floors = await getFloors(p.id);
      const floorArrPromises = floors.map(async (f) => {
        const unitsArr = await getUnits(p.id, f.id);
        if (unitsArr.length == 0) {
          navigate(
            `/properties/${propertyId}/floors/${floorId}/units/b/noBlocks`
          );
        }
        unitsArr.map((u) => {
          blocksObj[u.Block] = "";
        });

        let sortedBlock = {};
        Object.keys(blocksObj)
          .sort()
          .forEach(function (key) {
            sortedBlock[key] = blocksObj[key];
          });
        return {
          FloorName: f.FloorName,
          id: f.id,
          Units: unitsArr,
          Blocks: sortedBlock,
        };
      });
      const floorsArr = await Promise.all(floorArrPromises);
      return {
        PropertyName: p.PropertyName,
        id: p.id,
        Floors: floorsArr,
      };
    });

    const propertyArr = await Promise.all(propertyArrPromises);
    setUnits(propertyArr);
    setLoading(false);
  };

  const handleChangeProperty = async (id) => {
    setLoading(true);
    navigate(`/properties/${id}/floors/allFloors/blocks`);
  };

  const handleChangeFloor = async (id) => {
    setLoading(true);
    navigate(`/properties/${propertyId}/floors/${id}/blocks`);
  };

  useEffect(() => {
    if (propertyId == "allProperties" && floorId == "allFloors") {
      fetchAllUnits();
    } else if (floorId == "allFloors" && propertyId != "allProperties") {
      console.log("All floors of specific property");
      fetchSpecificPropertyUnits(propertyId);
    } else if (propertyId != "allProperties" && floorId != "allFloors") {
      fetchSpecificFloorUnits(propertyId, floorId);
    }

    setCurrentProperty(propertyId);
    setCurrentFloor(floorId);
    fetchProperties();
    fetchFloors(propertyId);
  }, [propertyId, floorId]);

  useEffect(() => {
    console.log(units);
  }, [units]);

  const handleSearchChange = (e, label) => {
    setSearchData(e.target.value);
  };

  return (
    <div>
      <PageTitle title={bn ? "Blocks" : `ব্লক`} />
      <br></br>
      <div className="flex-row">
        {currentProperty && properties.length > 0 && (
          <Dropdown
            items={properties}
            label="Property"
            handleChange={handleChangeProperty}
            defaultVal={propertyId}
          />
        )}

        {currentFloor && floors.length > 0 && (
          <Dropdown
            items={floors}
            label="Floor"
            handleChange={handleChangeFloor}
            defaultVal={floorId}
          />
        )}
        {/* <TextInputWithDropdown
          label="Search"
          placeholder="Search"
          setSearchState={setSearchState}
          handleChange={handleSearchChange}
        /> */}
      </div>

      <br></br>
      {units && (
        <Table>
          <TableHeader headings={[[bn ? "Block" : `ব্লক`, 7]]} />
          {loading && <Loading />}

          {units.map((p) => {
            if (p.Floors) {
              return p.Floors.map((f) => {
                if (f.Units.length > 0) {
                  return (
                    <>
                      <UnitsContainer
                        Property={p.PropertyName}
                        Floor={f.FloorName}
                      >
                        {Object.keys(f.Blocks).map((b) => {
                          return (
                            <TableItem
                              handleClick={() => {
                                navigate(
                                  `/properties/${propertyId}/floors/${floorId}/units/b/${b}`
                                );
                              }}
                              rowData={[[<div>{`${b}`}</div>, 7]]}
                            />
                          );
                        })}
                        <br></br>
                      </UnitsContainer>
                    </>
                  );
                }
              });
            }
          })}
        </Table>
      )}
    </div>
  );
}

export default ViewBlocks;
