import {
  EventNote,
  LocationCity,
  LocationOn,
  People,
  PeopleOutline,
} from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../../components/PageTitle";
import { TextInputWithLabel } from "../../components/TextInputWithLabel";
import { CheckboxInputWithLabel } from "../../components/CheckboxInputWithLabel";
import FieldSection from "../../components/FieldSection";
import FieldContainer from "../../components/FieldContainer";
import {
  GetFiles,
  GetImage,
  UploadFile,
  UploadImage,
  addRequest,
  addTenant,
  addUnit,
  getFloor,
  getFloorName,
  getProperty,
  getPropertyName,
  getRequest,
  getTenant,
  getUnit,
  updateRequest,
  updateTenant,
  updateUnit,
} from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { DateInputWithLabel } from "../../components/DateInputWithLabel";
import PrimaryButton from "../../components/PrimaryButton";
import UnitSelector from "../../components/UnitSelector";
import Dropdown from "../../components/Dropdown";
import DropdownWithLabel from "../../components/DropdownWithLabel";
import UploadButton from "../../components/UploadButton";
import DeleteButton from "../../components/DeleteButton";
import BlueItem from "../../components/BlueItem";
import { UserAuth } from "../../AuthContext";
import { serverTimestamp } from "firebase/firestore";
import TextLabel from "../../components/TextLabel";
import { Loading } from "../../components/Loading";
import SuccessAnimation from "../../components/SuccessAnimation";
import PrimaryDeleteButton from "../../components/PrimaryDeleteButton";
import DeleteDialog from "../../components/DeleteDialog";
import ErrorMsg from "../../components/ErrorMsg";

function RecieveMoveOut() {
  const [loading, setLoading] = useState();
  const [done, setDone] = useState();
  const [toggleDelete, setToggleDelete] = useState();
  const navigate = useNavigate();
  const { user, rolesStream } = UserAuth();
  const [image, setImage] = useState();
  const [error, setError] = useState([]);
  const [data, setData] = useState();
  const [currentFloor, setCurrentFloor] = useState();
  const [currentProperty, setCurrentProperty] = useState();
  const [currentFloorName, setCurrentFloorName] = useState();
  const [currentPropertyName, setCurrentPropertyName] = useState();
  const [currentUnit, setCurrentUnit] = useState();
  const { requestId } = useParams();
  const [lease, setLease] = useState();
  const [rent, setRent] = useState();
  const [utility, setUtility] = useState();
  const [sc, setSc] = useState();
  const [unitData, setUnitData] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [tenantData, setTenantData] = useState({ TenantType: "Primary" });
  const [fieldData] = useState([
    [
      {
        fieldTitle: "Location",
        fieldIcon: <LocationOn />,
        fieldItems: [
          {
            label: "Property",
            type: "text",
          },
          {
            label: "Floor",
            type: "text",
          },
          {
            label: "Block",
            type: "text",
            origin: "unit",
          },
          {
            label: "Unit No",
            type: "text",
            origin: "unit",
          },
          {
            label: "Unit Space",
            type: "text",
            origin: "unit",
          },
        ],
      },
      {
        fieldTitle: "Entry Info",
        fieldIcon: <EventNote />,
        fieldItems: [
          {
            label: "Rent",
            type: "checkbox",
            origin: "unit",
          },
          {
            label: "Rent Start Date",
            placeholder: "Enter Rent Start Date",
            type: "date",
            activation: "Rent",
            origin: "unit",
          },
          {
            label: "Unit rate per sq",
            placeholder: "Enter Unit rate per sq",
            type: "text",
            activation: "Rent",
            origin: "unit",
          },
          {
            label: "Utility",
            type: "checkbox",
            origin: "unit",
          },
          {
            label: "Utility Start Date",
            placeholder: "Enter Utility Start Date",
            type: "date",
            activation: "Utility",
            origin: "unit",
          },
          {
            label: "Utility Rate",
            placeholder: "Enter Utility Rate",
            type: "text",
            activation: "Utility",
            origin: "unit",
          },
          {
            label: "Previous Usage",
            placeholder: "Enter Previous Usage",
            type: "text",
            activation: "Utility",
            origin: "unit",
          },
          {
            label: "Service Charge",
            type: "checkbox",
            origin: "unit",
          },
          {
            label: "SC Start Date",
            placeholder: "Enter SC Start Date",
            type: "date",
            activation: "SC",
            origin: "unit",
          },
          {
            label: "Common Space (if any)",
            placeholder: "Enter Common Space",
            type: "text",
            activation: "SC",
            origin: "unit",
          },
          {
            label: "Service Charge Rate",
            placeholder: "Enter Service Charge Rate",
            type: "text",
            activation: "SC",
            origin: "unit",
          },
          {
            type: "Button",
          },
        ],
      },
    ],
    [
      {
        fieldTitle: "Tenant Details",
        fieldIcon: <People />,
        fieldItems: [
          {
            label: "Tenant Type",
            type: "dropdown",
            items: [
              { Value: "Primary", Name: "Primary" },
              { Value: "Position", Name: "Position" },
            ],
            origin: "tenant",
          },
          {
            label: "Business Name",
            placeholder: "Enter Business Name",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Tenant Name",
            placeholder: "Enter Tenant Name",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Father's Name",
            placeholder: "Enter Father's Name",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Home Address",
            placeholder: "Enter Home Address",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Mobile Number",
            placeholder: "Enter Mobile Number",
            type: "text",
            origin: "tenant",
          },
          {
            label: "National ID",
            placeholder: "Enter National ID",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Passport Number",
            placeholder: "Enter Passport Number",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Manager Name",
            placeholder: "Enter Manager Name",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Manager Mobile",
            placeholder: "Enter Manager Mobile",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Security Deposit",
            placeholder: "Enter Security Deposit",
            type: "text",
            origin: "tenant",
            activation: "Position",
          },
        ],
      },
      {
        fieldTitle: "Secondary Tenant Details",
        fieldIcon: <PeopleOutline />,
        fieldItems: [
          {
            label: "Secondary Tenant Name",
            placeholder: "Enter Tenant Name",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Secondary Tenant's Father's Name",
            placeholder: "Enter Father's Name",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Secondary Tenant's Home Address",
            placeholder: "Enter Home Address",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Secondary Tenant's Mobile Number",
            placeholder: "Enter Mobile Number",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Secondary Tenant's National ID",
            placeholder: "Enter National ID",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Secondary Tenant's Passport Number",
            placeholder: "Enter Passport Number",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Secondary Tenant Rent",
            placeholder: "Enter Secondary Tenant Rent",
            type: "text",
            origin: "tenant",
          },
        ],
      },
    ],
    [
      {
        fieldTitle: "Emergency Contact",
        fieldIcon: <PeopleOutline />,
        fieldItems: [
          {
            label: "Emergency Contact Name",
            placeholder: "Emergency Contact Name",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Emergency Contact Relation",
            placeholder: "Enter Emergency Contact Relation",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Emergency Contact Address",
            placeholder: "Enter Emergency Contact Address",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Emergency Contact Mobile",
            placeholder: "Enter Emergency Contact Mobile",
            type: "text",
            origin: "tenant",
          },
        ],
      },
    ],
  ]);
  const [imageFile, setImageFile] = useState();
  const [imageFileURL, setImageFileURL] = useState();
  const [files, setFiles] = useState([]);
  const [requestData, setRequestData] = useState();

  const fileHandler = async (event) => {
    const newFile = event.target.files[0]; // Get only the first file
    setImageFile(newFile);

    const reader = new FileReader();

    reader.onload = function (e) {
      setImageFileURL(e.target.result);
    };

    reader.readAsDataURL(newFile);
  };

  const handleClick = async () => {
    setLoading(true);
    console.log(data);
    console.log(tenantData);

    await updateUnit(currentProperty, currentFloor, currentUnit, {
      cancellingSoon: lease,
    });

    await updateTenant(
      currentProperty,
      currentFloor,
      currentUnit,
      unitData["currentTenantId"],
      {
        LeaseEnding: lease,
      }
    );

    await updateRequest(requestId, {
      RecievedBy: user.Name,
      status: "Approved",
    });
    setLoading(false);
    setDone(true);
    setTimeout(() => {
      navigate("/home");
    }, [3000]);
  };

  const handleData = (e, label) => {
    const obj = {};
    obj[label.replace(/\s+/g, "")] = e.target.value;
    setUnitData((prev) => {
      return { ...prev, ...obj };
    });
  };

  const handleLeaseChange = (e, label) => {
    setLease(e.target.value);
  };

  const handleTenantData = (e, label) => {
    const obj = {};
    obj[label.replace(/\s+/g, "")] = e.target.value;
    setTenantData((prev) => {
      return { ...prev, ...obj };
    });
  };

  const fetchUnitData = async () => {
    const unit = await getUnit(currentProperty, currentFloor, currentUnit);
    const prop = await getPropertyName(currentProperty);
    const floor = await getFloorName(currentProperty, currentFloor);
    const tenant = await getTenant(
      currentProperty,
      currentFloor,
      currentUnit,
      unit["currentTenantId"]
    );
    console.log(tenant);
    setTenantData(tenant);

    setCurrentPropertyName(prop);
    setCurrentFloorName(floor);

    setUnitData(unit);
    setUnitData((prev) => {
      return { ...prev, ...requestData.mainData };
    });
    if (unit["Rent"] == true) {
      setRent(true);
    }
    if (unit["Utility"] == true) {
      setUtility(true);
    }
    if (unit["ServiceCharge"] == true) {
      setSc(true);
    }
  };

  const fetchRequest = async () => {
    const req = await getRequest(requestId);
    console.log(req);
    setCurrentProperty(req.location.currentProperty);
    setCurrentFloor(req.location.currentFloor);
    setCurrentUnit(req.location.currentUnit);
    setRequestData(req);
    setLease(req.mainData.leaseEnding);
  };

  useEffect(() => {
    fetchUnitData();
  }, [currentUnit]);

  useEffect(() => {
    fetchRequest();
    getFiles();
  }, [requestId]);

  const handleChange = async (e, label) => {
    // console.log(label);
    if (label == "Rent") {
      setRent(e.target.checked);
      let obj = {};
      obj["Rent"] = e.target.checked;
      setData((prev) => {
        return { ...prev, ...obj };
      });
    } else if (label == "Utility") {
      setUtility(e.target.checked);
      let obj = {};
      obj["Utility"] = e.target.checked;
      setData((prev) => {
        return { ...prev, ...obj };
      });
    } else if (label == "Service Charge") {
      setSc(e.target.checked);
      let obj = {};
      obj["ServiceCharge"] = e.target.checked;
      setData((prev) => {
        return { ...prev, ...obj };
      });
    }
  };

  const handleEdit = async () => {
    await updateRequest(requestId, {
      mainData: {
        leaseEnding: lease,
      },
    });
  };

  useEffect(() => {
    console.log(rent);
  }, [rent]);

  useEffect(() => {
    console.log(imageFile);
  }, [imageFile]);

  useEffect(() => {
    fetchImage();
  }, [tenantData]);

  const getFiles = async () => {
    const files = await GetFiles(
      `Tenants/${requestData.mainData.currentTenantId}/files`
    );
    console.log("files");
    setFiles(files);
  };

  const fetchImage = async () => {
    const x = await GetImage(`Tenants/${tenantData["id"]}`);
    setImage(x);
  };

  const fileHandler2 = async (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles([...files, ...newFiles]);
  };

  const handleDeleteFile = async (index) => {
    setFiles((prev) => {
      delete prev[index];
      return [...prev];
    });
  };

  const handleDelete = async () => {
    setLoading(true);
    await updateRequest(requestId, {
      status: "Declined",
      RecievedBy: user.Name,
    });
    setLoading(false);
    setDone(true);
    setTimeout(() => {
      navigate("/home");
    }, [3000]);
  };

  return (
    <>
      <PageTitle title="Move Out" image={image || ""} />
      <br></br>
      <div className="forceBold">
        <TextLabel
          label="Current Lease Starting"
          value={tenantData.LeaseStarting}
        />
        <TextLabel
          label="Current Lease Ending"
          value={tenantData.LeaseEnding}
        />
        {lease && (
          <DateInputWithLabel
            label="Requested Lease Ending"
            value={lease}
            placeholder={"sd"}
            handleChange={handleLeaseChange}
            notEditable={!isEditing}
          />
        )}
      </div>
      <br></br>
      {requestData &&
        requestData.status == "Pending" &&
        rolesStream[user.Role][`RecieveChangeSecondaryTenantApplication`] && (
          <PrimaryButton
            text={isEditing ? "Save" : "Change Lease Ending"}
            handleClick={() => {
              setIsEditing(!isEditing);
              if (isEditing == true) {
                handleEdit();
              }
            }}
          />
        )}
      <br></br>
      <br></br>

      {unitData &&
        currentUnit &&
        fieldData.map((f) => {
          return (
            <FieldContainer>
              {f.map((x) => {
                return (
                  <FieldSection>
                    <div
                      className="flex-row color-primary"
                      style={{ marginBottom: "0.5rem" }}
                    >
                      {x.fieldIcon}
                      <label>{x.fieldTitle}</label>
                    </div>
                    {x["fieldItems"].map((y) => {
                      if (
                        !y.activation ||
                        (y.activation == "Rent" && rent) ||
                        (y.activation == "Utility" && utility) ||
                        (y.activation == "SC" && sc) ||
                        (y.activation == "Position" &&
                          tenantData["TenantType"] == "Primary")
                      ) {
                        if (y.type == "text") {
                          return (
                            <TextInputWithLabel
                              label={y.label}
                              notEditable={true}
                              value={
                                y.label == "Property"
                                  ? currentPropertyName
                                  : y.label == "Floor"
                                  ? currentFloorName
                                  : y.origin == "unit"
                                  ? unitData[y.label.replace(/\s+/g, "")]
                                  : y.origin == "tenant"
                                  ? `${
                                      tenantData[y.label.replace(/\s+/g, "")]
                                        ? tenantData[
                                            y.label.replace(/\s+/g, "")
                                          ]
                                        : "N/A"
                                    }`
                                  : ""
                              }
                              placeholder={y.placeholder}
                              handleChange={
                                y.origin == "unit"
                                  ? handleData
                                  : y.origin == "tenant"
                                  ? handleTenantData
                                  : null
                              }
                            />
                          );
                        } else if (y.type == "checkbox") {
                          return (
                            <CheckboxInputWithLabel
                              label={y.label}
                              handleChange={handleChange}
                              notEditable={true}
                              placeholder={"bleh"}
                              value={
                                y.label == "Rent" && rent == true
                                  ? rent
                                  : y.label == "Utility" && utility == true
                                  ? utility
                                  : y.label == "ServiceCharge" && sc == true
                                  ? sc
                                  : false
                              }
                            />
                          );
                        } else if (y.type == "date") {
                          return (
                            <DateInputWithLabel
                              label={y.label}
                              notEditable={true}
                              placeholder={y.placeholder}
                              value={
                                y.origin == "unit"
                                  ? unitData[y.label.replace(/\s+/g, "")]
                                  : tenantData[y.label.replace(/\s+/g, "")]
                              }
                              handleChange={
                                y.origin == "unit"
                                  ? handleData
                                  : y.origin == "tenant"
                                  ? handleTenantData
                                  : null
                              }
                            />
                          );
                        } else if (y.type == "dropdown") {
                          return (
                            <DropdownWithLabel
                              label={y.label}
                              items={y.items}
                              notEditable={true}
                              value={tenantData["TenantType"]}
                              defaultVal={tenantData["TenantType"]}
                              handleChange={handleTenantData}
                            />
                          );
                        } else if (y.type == "Button") {
                          return (
                            <div
                              onClick={() => {
                                navigate(
                                  `/entryHistory/${unitData[`currentTenantId`]}`
                                );
                              }}
                            >
                              <br></br>
                              {unitData && unitData[`currentTenantId`] && (
                                <PrimaryButton text={"See History"} />
                              )}
                            </div>
                          );
                        }
                      }
                    })}
                  </FieldSection>
                );
              })}
            </FieldContainer>
          );
        })}
      <br></br>

      {imageFile && (
        <div style={{ marginBottom: "1rem" }}>{imageFile.name}</div>
      )}
      {/* {currentUnit && (
        <UploadButton
          text={`${imageFile ? "Change Image" : "Add Image"}`}
          single={true}
          fileHandler={fileHandler}
          imageOnly={true}
        />
      )}
      {files.map((f, index) => {
        return (
          <div className="flex-row">
            {f && (
              <>
                <BlueItem text={f.name} />
                <DeleteButton name={index} handleDelete={handleDeleteFile} />
                <br></br>
              </>
            )}
          </div>
        );
      })}
      {files.length > 0 && <br></br>}
      {currentUnit && (
        <UploadButton
          text={"Add Files"}
          fileHandler={fileHandler2}
          imageOnly={false}
        />
      )} */}
      {Object.keys(files).length > 0 &&
        Object.keys(files).map((f) => {
          return (
            <div style={{ alignItems: "center" }} className="flex-row">
              <BlueItem link={files[f]} text={f} />
              {/* {isEditing && <DeleteButton handleDelete={deleteFile} name={f} />} */}
            </div>
          );
        })}
      <br></br>
      {loading && <Loading />}
      {done && <SuccessAnimation text="Approved" />}

      <br></br>
      {error.map((e) => {
        return <ErrorMsg text={e} />;
      })}
      <br></br>
      {currentUnit &&
        requestData.status == "Pending" &&
        !loading &&
        !done &&
        rolesStream[user.Role][`RecieveMoveOutApplication`] && (
          <>
            <br></br>
            <div className="flex-row">
              <PrimaryButton
                text="Approve"
                handleClick={() => handleClick()}
                green={true}
              />
              {!isEditing && (
                <PrimaryDeleteButton
                  text={"Decline"}
                  handleClick={() => {
                    setToggleDelete(true);
                  }}
                />
              )}
            </div>
          </>
        )}
      {toggleDelete && (
        <DeleteDialog
          text="Are you sure you want to decline the request?"
          onApproval={() => {
            setToggleDelete(false);
            handleDelete();
          }}
          onDecline={() => {
            setToggleDelete(false);
          }}
        />
      )}

      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
}

export default RecieveMoveOut;
