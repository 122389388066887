import React from "react";
import classes from "../styles/TableItem.module.css";

export const TableItem = ({ rowData, handleClick }) => {
  return (
    <div onClick={() => handleClick()} className={classes.itemContainer}>
      {rowData.map((r) => {
        console.log(React.isValidElement(r[0]));
        return (
          <>
            <label style={{ width: `${r[1]}rem` }}>
              {React.isValidElement(r[0]) ? r[0] : <span>{r[0]}</span>}
            </label>
            <div className={classes.divider}></div>
          </>
        );
      })}
    </div>
  );
};
