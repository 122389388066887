import { useState } from "react";
import { getFloors, getProperties, getUnits } from "../firebase";
import { useEffect } from "react";
import classes from "../styles/UnitSelector.module.css";
import { UserAuth } from "../AuthContext";

const PropertySelector = ({ setCurrentProperty }) => {
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState();
  const { user, rolesStream, bn } = UserAuth();

  function hasMatchingParent(id, arrayOfObjects) {
    for (let i = 0; i < arrayOfObjects.length; i++) {
      if (arrayOfObjects[i].parent === id) {
        return true;
      }
    }
    return false;
  }

  const fetchProperties = async () => {
    const properties = await getProperties();
    properties.map((p, index) => {
      if (
        hasMatchingParent(p.id, user.PropertyAccess) == false &&
        rolesStream[user.Role].Name != "Admin" &&
        rolesStream[user.Role].Name != "SuperAdmin"
      ) {
        delete properties[index];
      }
    });
    setProperties(properties);
  };

  useEffect(() => {
    fetchProperties();
  }, [rolesStream]);

  return (
    <>
      {!selectedProperty && (
        <div>
          <div>
            {bn ? `Please Select your Property` : `প্রপার্টি সিলেক্ট করুন`}
          </div>
          <br></br>
          {properties.map((p) => {
            return (
              <div
                className={classes.item}
                onClick={() => {
                  setSelectedProperty(p.id);
                  setCurrentProperty(p.id);
                }}
              >
                {p.PropertyName}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default PropertySelector;
