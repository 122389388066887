import { People } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import MultiCheckbox from "../../components/MultiCheckbox";
import PageTitle from "../../components/PageTitle";
import PrimaryButton from "../../components/PrimaryButton";
import { TextInputWithLabel } from "../../components/TextInputWithLabel";
import { getRole, updateRole } from "../../firebase";
import classes from "../../styles/RolePages.module.css";

const RoleDetails = () => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [roleName, setRoleName] = useState();
  const { roleId } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [roleData, setRoleData] = useState();
  const [items, setItems] = useState([
    [
      {
        title: "User Control",
        subItems: [
          {
            label: "Add User",
            checked: false,
          },
          {
            label: "View Users",
            checked: false,
          },
          {
            label: "Edit User",
            checked: false,
          },
          {
            label: "Manage Roles",
            checked: false,
          },
          {
            label: "Status Control",
            checked: false,
          },
          {
            label: "Activity Log",
            checked: false,
          },
        ],
      },
      {
        title: "Entries",
        subItems: [
          {
            label: "Rent Collection",
            checked: false,
          },
          {
            label: "Utility Collection",
            checked: false,
          },
          {
            label: "Service Charge Collection",
            checked: false,
          },
          {
            label: "View Due",
            checked: false,
          },
        ],
      },
    ],
    [
      {
        title: "Properties",
        subItems: [
          {
            label: "Add Property",
            checked: false,
          },
          {
            label: "View Property",
            checked: false,
          },
          {
            label: "Edit Property Details",
            checked: false,
          },
        ],
      },
      {
        title: "Floors",
        subItems: [
          {
            label: "Add Floors",
            checked: false,
          },
          {
            label: "View Floors",
            checked: false,
          },
          {
            label: "Edit Floor Details",
            checked: false,
          },
          {
            label: "Floor View Access",
            checked: false,
          },
          {
            label: "Floor View Edit",
            checked: false,
          },
        ],
      },
      {
        title: "Units",
        subItems: [
          {
            label: "Add Units",
            checked: false,
          },
          {
            label: "View Units",
            checked: false,
          },
          {
            label: "View Unit Details",
            checked: false,
          },
          {
            label: "Security Deposit Access",
            checked: false,
          },
          {
            label: "View Unit History",
            checked: false,
          },
          {
            label: "Edit Unit Details",
            checked: false,
          },
          {
            label: "Edit Unit Master",
            checked: false,
          },
        ],
      },
    ],
    [
      {
        title: "Send Requests",
        subItems: [
          {
            label: "Move In Application",
            checked: false,
          },
          {
            label: "Change Secondary Tenant Application",
            checked: false,
          },
          {
            label: "Change Position Tenant Application",
            checked: false,
          },
          {
            label: "Move Out Application",
            checked: false,
          },
          {
            label: "Unit Change Request",
            checked: false,
          },
          {
            label: "Tenant Complain",
            checked: false,
          },
          {
            label: "Property Complain",
            checked: false,
          },
          {
            label: "Other Complain",
            checked: false,
          },
          {
            label: "Upcoming Cancellations",
            checked: false,
          },
        ],
      },
      {
        title: "Recieve Requests",
        subItems: [
          {
            label: "Recieve Move In Application",
            checked: false,
          },
          {
            label: "Recieve Change Secondary Tenant Application",
            checked: false,
          },
          {
            label: "Recieve Change Position Tenant Application",
            checked: false,
          },
          {
            label: "Recieve Move Out Application",
            checked: false,
          },
          {
            label: "Recieve Unit Change Request",
            checked: false,
          },
          {
            label: "Recieve Tenant Complain",
            checked: false,
          },
          {
            label: "Recieve Property Complain",
            checked: false,
          },
          {
            label: "Recieve Other Complain",
            checked: false,
          },
        ],
      },
    ],
    [
      {
        title: "Rental Reports",
        subItems: [
          {
            label: "Rent Entry History",
            checked: false,
          },
          {
            label: "Lease Ending",
            checked: false,
          },
          {
            label: "Vacant Units",
            checked: false,
          },
        ],
      },
      {
        title: "Utility Reports",
        subItems: [
          {
            label: "Meter Reading History",
            checked: false,
          },
          {
            label: "Utility Entry History",
            checked: false,
          },
        ],
      },
      {
        title: "S.C Reports",
        subItems: [
          {
            label: "SC Entry History",
            checked: false,
          },
        ],
      },
    ],
    [
      {
        title: "Account Receiveable",
        subItems: [
          {
            label: "Rent",
            checked: false,
          },
          {
            label: "Utility",
            checked: false,
          },
          {
            label: "Service Charge",
            checked: false,
          },
        ],
      },
      {
        title: "Balance Sheet",
        subItems: [
          {
            label: "Rent Collected",
            checked: false,
          },
          {
            label: "Utility Collected",
            checked: false,
          },
          {
            label: "Service Charge Collected",
            checked: false,
          },
        ],
      },
      {
        title: "Statements",
        subItems: [
          {
            label: "Rent Statements",
            checked: false,
          },
          {
            label: "Utility Statements",
            checked: false,
          },
          {
            label: "Service Charge Statements",
            checked: false,
          },
        ],
      },
    ],
  ]);

  const extractSubItems = (items) => {
    return items.reduce((acc, category) => {
      category.forEach((item) => {
        item.subItems.forEach((subItem) => {
          const key = subItem.label.replace(/\s+/g, "");
          acc[key] = subItem.checked;
        });
      });
      return acc;
    }, {});
  };

  const updateItems = (inputObj) => {
    const updatedItems = items.map((category) => {
      return category.map((item) => {
        const updatedSubItems = item.subItems.map((subItem) => {
          const key = subItem.label.replace(/\s+/g, "");
          if (inputObj.hasOwnProperty(key)) {
            return { ...subItem, checked: inputObj[key] };
          } else {
            return subItem;
          }
        });
        return { ...item, subItems: updatedSubItems };
      });
    });

    setItems(updatedItems);
  };

  const handleSave = async () => {
    if (isEditing) {
      console.log("x");
      await updateRole(roleId, { ...roleData, Name: roleName });
      setIsEditing(!isEditing);
    } else {
      setIsEditing(!isEditing);
    }
  };

  const handleData = (e, label) => {
    setRoleName(e.target.value);
  };

  const fetchRole = async () => {
    const role = await getRole(roleId);
    setRoleName(role.Name);
    updateItems(role);
    setRoleData(role);
  };

  useEffect(() => {
    fetchRole();
  }, [roleId]);

  useEffect(() => {
    console.log("items");
    setRoleData(extractSubItems(items));
  }, [items]);

  return (
    <>
      <PageTitle title={"Role Details"} />
      {roleData && (
        <TextInputWithLabel
          key="name"
          label={"Role Name"}
          placeholder={"Enter Role Name"}
          handleChange={handleData}
          value={roleName}
          notEditable={!isEditing}
        ></TextInputWithLabel>
      )}
      <br></br>
      {items &&
        items.map((subItem, parentIndex) => {
          return (
            <>
              <div className={classes.rolesContainer}>
                {subItem.map((i, index) => {
                  return (
                    <>
                      <MultiCheckbox
                        mainIndex={index}
                        parentIndex={parentIndex}
                        title={i.title}
                        notEditable={isEditing}
                        items={items}
                        setItems={setItems}
                        icon={<People />}
                      />
                      {index != subItem.length && <br></br>}
                    </>
                  );
                })}
              </div>
              <br></br>
              <br></br>
            </>
          );
        })}
      <PrimaryButton
        text={isEditing ? "Save" : "Edit"}
        handleClick={() => {
          handleSave();
        }}
      />

      <br></br>
      <br></br>
      {windowSize.current[0] < 768 && (
        <>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </>
      )}
    </>
  );
};

export default RoleDetails;
