import React, { useState } from "react";
import PageTitle from "../../components/PageTitle";
import { DateInputWithLabel } from "../../components/DateInputWithLabel";
import { CheckboxInputWithLabel } from "../../components/CheckboxInputWithLabel";
import { TextInputWithLabel } from "../../components/TextInputWithLabel";
import FieldContainer from "../../components/FieldContainer";
import {
  AttachFile,
  EventNote,
  LocationOn,
  People,
  PeopleOutline,
  QrCode,
} from "@mui/icons-material";
import FieldSection from "../../components/FieldSection";
import QRCode from "react-qr-code";
import { useNavigate, useParams } from "react-router-dom";
import {
  AddLog,
  DeleteFile,
  GetFiles,
  GetImage,
  UploadFile,
  UploadImage,
  db,
  getExistingUnitNo,
  getFloor,
  getProperty,
  getTenant,
  getUnit,
  getUnits,
  updateTenant,
  updateUnit,
} from "../../firebase";
import { useEffect } from "react";
import PrimaryButton from "../../components/PrimaryButton";
import Dropdown from "../../components/Dropdown";
import DropdownWithLabel from "../../components/DropdownWithLabel";
import BlueItem from "../../components/BlueItem";
import DeleteButton from "../../components/DeleteButton";
import UploadButton from "../../components/UploadButton";
import { UserAuth } from "../../AuthContext";
import ErrorMsg from "../../components/ErrorMsg";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";

function UnitDetails() {
  const { propertyId, floorId, unitId } = useParams();
  const [files, setFiles] = useState({});
  const [secondary, setSecondary] = useState();
  const [error, setError] = useState([]);
  const [pastTenants, setPastTenants] = useState([]);
  const [image, setImage] = useState();
  const navigate = useNavigate();
  const { bn, user, rolesStream } = UserAuth();
  const [fieldData] = useState([
    [
      {
        fieldTitle: "Location",
        fieldTitle_bn: "লোকেশন",
        fieldIcon: <LocationOn />,
        fieldItems: [
          {
            label: "Property",
            label_bn: "সম্পত্তি",
            type: "text",
            origin: "unit",
          },
          {
            label: "Floor",
            label_bn: "তলা",
            type: "text",
            origin: "unit",
          },
          {
            label: "Block",
            label_bn: "ব্লক",
            placeholder: "Enter Block",
            placeholder_bn: "ব্লক প্রবেশ করান",
            type: "text",
            origin: "unit",
          },
          {
            label: "Unit No",
            label_bn: "ইউনিট নং",
            placeholder: "Enter Unit No",
            placeholder_bn: "ইউনিট নং প্রবেশ করান",
            type: "text",
            origin: "unit",
          },
          {
            label: "Unit Space",
            label_bn: "ইউনিট স্পেস",
            placeholder: "Enter Unit Space",
            placeholder_bn: "ইউনিট স্পেস প্রবেশ করান",
            type: "text",
            origin: "unit",
          },
        ],
      },
      {
        fieldTitle: "Entry Info",
        fieldTitle_bn: "এন্ট্রি ইনফো",
        fieldIcon: <EventNote />,
        fieldItems: [
          {
            label: "Rent",
            label_bn: "ভাড়া",
            type: "checkbox",
            origin: "unit",
          },
          {
            label: "Rent Start Date",
            label_bn: "ভাড়া শুরুর তারিখ",
            placeholder: "Enter Rent Start Date",
            placeholder_bn: "ভাড়া শুরুর তারিখ প্রবেশ করান",
            type: "date",
            activation: "Rent",
            origin: "unit",
          },
          {
            label: "Unit rate per sq",
            label_bn: "প্রতি বর্গফুটে ইউনিট হার",
            placeholder: "Enter Unit rate per sq",
            placeholder_bn: "প্রতি বর্গফুটে ইউনিট হার প্রবেশ করান",
            type: "text",
            activation: "Rent",
            origin: "unit",
          },
          {
            label: "Utility",
            label_bn: "উপযোগিতা",
            type: "checkbox",
            origin: "unit",
          },
          {
            label: "Utility Start Date",
            label_bn: "ইউটিলিটি শুরুর তারিখ",
            placeholder: "Enter Utility Start Date",
            placeholder_bn: "ইউটিলিটি শুরুর তারিখ প্রবেশ করান",
            type: "date",
            activation: "Utility",
            origin: "unit",
          },
          {
            label: "Utility Rate",
            label_bn: "ইউটিলিটি হার",
            placeholder: "Enter Utility Rate",
            placeholder_bn: "ইউটিলিটি হার প্রবেশ করান",
            type: "text",
            activation: "Utility",
            origin: "unit",
          },
          {
            label: "Previous Usage",
            label_bn: "পূর্বের ব্যবহার",
            placeholder: "Enter Previous Usage",
            placeholder_bn: "পূর্বের ব্যবহার প্রবেশ করান",
            type: "text",
            activation: "Utility",
            origin: "unit",
          },
          {
            label: "Service Charge",
            label_bn: "সার্ভিস চার্জ",
            type: "checkbox",
            origin: "unit",
          },
          {
            label: "SC Start Date",
            label_bn: "এসসি শুরুর তারিখ",
            placeholder: "Enter S/C Start Date",
            placeholder_bn: "এসসি শুরুর তারিখ প্রবেশ করান",
            type: "date",
            activation: "SC",
            origin: "unit",
          },
          {
            label: "Common Space (if any)",
            label_bn: "সাধারণ স্থান (যদি থাকে)",
            placeholder: "Enter Common Space",
            placeholder_bn: "সাধারণ স্থান প্রবেশ করান",
            type: "text",
            activation: "SC",
            origin: "unit",
          },
          {
            label: "Service Charge Rate",
            label_bn: "সার্ভিস চার্জ হার",
            placeholder: "Enter Service Charge Rate",
            placeholder_bn: "সার্ভিস চার্জ হার প্রবেশ করান",
            type: "text",
            activation: "SC",
            origin: "unit",
          },
          {
            type: "Button",
          },
        ],
      },
    ],
    [
      {
        fieldTitle: "Tenant Details",
        fieldTitle_bn: "টেন্যান্ট তথ্য",
        fieldIcon: <People />,
        fieldItems: [
          {
            label: "Tenant Type",
            label_bn: "ভাড়াটিয়ার ধরণ",
            type: "dropdown",
            items: [
              { Value: "Primary", Name: "Primary", Name_bn: "প্রাথমিক" },
              { Value: "Position", Name: "Position", Name_bn: "পদ" },
            ],
            origin: "tenant",
          },
          {
            label: "Business Name",
            label_bn: "ব্যবসা নাম",
            placeholder: "Enter Business Name",
            placeholder_bn: "ব্যবসা নাম প্রবেশ করান",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Tenant Name",
            label_bn: "ভাড়াটিয়ার নাম",
            placeholder: "Enter Tenant Name",
            placeholder_bn: "ভাড়াটিয়ার নাম প্রবেশ করান",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Father's Name",
            label_bn: "পিতার নাম",
            placeholder: "Enter Father's Name",
            placeholder_bn: "পিতার নাম প্রবেশ করান",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Home Address",
            label_bn: "বাড়ির ঠিকানা",
            placeholder: "Enter Home Address",
            placeholder_bn: "বাড়ির ঠিকানা প্রবেশ করান",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Mobile Number",
            label_bn: "মোবাইল নম্বর",
            placeholder: "Enter Mobile Number",
            placeholder_bn: "মোবাইল নম্বর প্রবেশ করান",
            type: "text",
            origin: "tenant",
          },
          {
            label: "National ID",
            label_bn: "জাতীয় আইডি",
            placeholder: "Enter National ID",
            placeholder_bn: "জাতীয় আইডি প্রবেশ করান",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Passport Number",
            label_bn: "পাসপোর্ট নম্বর",
            placeholder: "Enter Passport Number",
            placeholder_bn: "পাসপোর্ট নম্বর প্রবেশ করান",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Manager Name",
            label_bn: "ম্যানেজারের নাম",
            placeholder: "Enter Manager Name",
            placeholder_bn: "ম্যানেজারের নাম প্রবেশ করান",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Manager Mobile",
            label_bn: "ম্যানেজার মোবাইল",
            placeholder: "Enter Manager Mobile",
            placeholder_bn: "ম্যানেজার মোবাইল প্রবেশ করান",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Lease Starting",
            label_bn: "লীজ শুরু",
            placeholder: "Enter Lease Starting",
            placeholder_bn: "লীজ শুরু প্রবেশ করান",
            type: "date",
            origin: "tenant",
            activation: "Position",
          },
          {
            label: "Lease Ending",
            label_bn: "লীজ শেষ",
            placeholder: "Enter Lease Ending",
            placeholder_bn: "লীজ শেষ প্রবেশ করান",
            type: "date",
            origin: "tenant",
            activation: "Position",
          },
          {
            label: "Security Deposit",
            placeholder: "Enter Security Deposit",
            type: "text",
            origin: "tenant",
            activation: "Position",
          },
        ],
      },
      {
        fieldTitle: "Secondary Tenant Details",
        fieldTitle_bn: "সেগেন্ডারি টেন্যান্ট",
        fieldIcon: <PeopleOutline />,
        fieldItems: [
          {
            label: "Secondary Tenant",
            label_bn: "গৌণ ভাড়াটিয়া",
            type: "checkbox",
            origin: "tenant",
          },
          {
            label: "Secondary Tenant Name",
            label_bn: "গৌণ ভাড়াটিয়ার নাম",
            placeholder: "Enter Tenant Name",
            placeholder_bn: "ভাড়াটিয়ার নাম প্রবেশ করান",
            type: "text",
            origin: "tenant",
            activation: "Secondary",
          },
          {
            label: "Secondary Tenant's Father's Name",
            label_bn: "গৌণ ভাড়াটিয়ার পিতার নাম",
            placeholder: "Enter Father's Name",
            placeholder_bn: "পিতার নাম প্রবেশ করান",
            type: "text",
            origin: "tenant",
            activation: "Secondary",
          },
          {
            label: "Secondary Tenant's Home Address",
            label_bn: "গৌণ ভাড়াটিয়ার বাড়ির ঠিকানা",
            placeholder: "Enter Home Address",
            placeholder_bn: "বাড়ির ঠিকানা প্রবেশ করান",
            type: "text",
            origin: "tenant",
            activation: "Secondary",
          },
          {
            label: "Secondary Tenant's Mobile Number",
            label_bn: "গৌণ ভাড়াটিয়ার মোবাইল নম্বর",
            placeholder: "Enter Mobile Number",
            placeholder_bn: "মোবাইল নম্বর প্রবেশ করান",
            type: "text",
            origin: "tenant",
            activation: "Secondary",
          },
          {
            label: "Secondary Tenant's National ID",
            label_bn: "গৌণ ভাড়াটিয়ার জাতীয় আইডি",
            placeholder: "Enter National ID",
            placeholder_bn: "জাতীয় আইডি প্রবেশ করান",
            type: "text",
            origin: "tenant",
            activation: "Secondary",
          },
          {
            label: "Secondary Tenant's Passport Number",
            label_bn: "গৌণ ভাড়াটিয়ার পাসপোর্ট নম্বর",
            placeholder: "Enter Passport Number",
            placeholder_bn: "পাসপোর্ট নম্বর প্রবেশ করান",
            type: "text",
            origin: "tenant",
            activation: "Secondary",
          },
          {
            label: "Secondary Tenant Rent",
            label_bn: "গৌণ ভাড়াটিয়ার ভাড়া",
            placeholder: "Enter Secondary Tenant Rent",
            placeholder_bn: "গৌণ ভাড়াটিয়ার ভাড়া প্রবেশ করান",
            type: "text",
            origin: "tenant",
            activation: "Secondary",
          },
        ],
      },
    ],
    [
      {
        fieldTitle: "Emergency Contact",
        fieldTitle_bn: "ইমারগেন্সি কন্টাক্ট",
        fieldIcon: <PeopleOutline />,
        fieldItems: [
          {
            label: "Emergency Contact Name",
            label_bn: "জরুরি যোগাযোগের নাম",
            placeholder: "Emergency Contact Name",
            placeholder_bn: "জরুরি যোগাযোগের নাম",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Emergency Contact Relation",
            label_bn: "জরুরি যোগাযোগের সম্পর্ক",
            placeholder: "Enter Emergency Contact Relation",
            placeholder_bn: "জরুরি যোগাযোগের সম্পর্ক প্রবেশ করান",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Emergency Contact Address",
            label_bn: "জরুরি যোগাযোগের ঠিকানা",
            placeholder: "Enter Emergency Contact Address",
            placeholder_bn: "জরুরি যোগাযোগের ঠিকানা প্রবেশ করান",
            type: "text",
            origin: "tenant",
          },
          {
            label: "Emergency Contact Mobile",
            label_bn: "জরুরি যোগাযোগের মোবাইল",
            placeholder: "Enter Emergency Contact Mobile",
            placeholder_bn: "জরুরি যোগাযোগের মোবাইল প্রবেশ করান",
            type: "text",
            origin: "tenant",
          },
        ],
      },
      {
        fieldTitle: "Nominee",
        activation: "Position",
        // fieldIcon: <PeopleOutline />,
        fieldItems: [
          {
            label: "Nominee Name",
            placeholder: "Nominee Name",
            type: "text",
            origin: "tenant",
            activation: "Primary",
          },
          {
            label: "Nominee Relation",
            placeholder: "Nominee Relation",
            type: "text",
            origin: "tenant",
            activation: "Primary",
          },
          {
            label: "Nominee Mobile",
            placeholder: "Enter Nominee Mobile",
            type: "text",
            origin: "tenant",
            activation: "Primary",
          },
        ],
      },
    ],
  ]);

  const [isEditing, setIsEditing] = useState();

  const [rent, setRent] = useState();
  const [utility, setUtility] = useState();
  const [sc, setSc] = useState();
  const [currentFloor, setCurrentFloor] = useState();
  const [currentProperty, setCurrentProperty] = useState();
  const [unitData, setUnitData] = useState({
    currentTenantType: "Vacant",
    "CommonSpace(ifany)": 0,
  });
  const [tenantData, setTenantData] = useState({});
  const [imageFile, setImageFile] = useState();
  const [imageFileURL, setImageFileURL] = useState();
  const [nextFiles, setNextFiles] = useState([]);

  const fetchProperty = async () => {
    const prop = await getProperty(propertyId);
    setCurrentProperty(prop.PropertyName);
  };

  const fetchFloor = async () => {
    const floor = await getFloor(propertyId, floorId);
    setCurrentFloor(floor.FloorName);
  };

  const fetchUnitData = async () => {
    const unit = await getUnit(propertyId, floorId, unitId);
    setUnitData(unit);
    console.log(unit);

    if (unit["Rent"] == true) {
      setRent(true);
    }
    if (unit["Utility"] == true) {
      setUtility(true);
    }
    if (unit["ServiceCharge"] == true) {
      setSc(true);
    }

    if (unit["SecondaryTenant"] == true) {
      setSecondary(true);
    }
  };

  async function validateTenant(obj) {
    let errors = [];

    // Check BusinessName
    if (
      (!obj.hasOwnProperty("BusinessName") || obj.BusinessName === "") &&
      obj.TenantType == "Vacant"
    ) {
      errors.push("BusinessName is missing.");
    }

    // Check TenantName
    if (
      (!obj.hasOwnProperty("TenantName") || obj.TenantName === "") &&
      obj.TenantType == "Vacant"
    ) {
      errors.push("TenantName is missing.");
    }

    // Check TenantType, LeaseStarting and LeaseEnding
    if (obj.hasOwnProperty("TenantType") && obj.TenantType === "Primary") {
      if (!obj.hasOwnProperty("LeaseStarting") || obj.LeaseStarting === "") {
        errors.push("Lease Starting is missing.");
      }

      if (!obj.hasOwnProperty("LeaseEnding") || obj.LeaseEnding === "") {
        errors.push("Lease Ending is missing.");
      }

      if (obj.LeaseEnding < obj.LeaseStarting) {
        errors.push("Lease Ending cannot be before Lease Starting.");
      }
    }

    // Check ManagerMobile, MobileNumber, EmergencyContactMobile, SecondaryTenant'sMobileNumber, Unitratepersq, UtilityRate, PreviousUsage, CommonSpace(ifany), ServiceChargeRate
    const fields = [
      "ManagerMobile",
      "MobileNumber",
      "EmergencyContactMobile",
      "SecondaryTenant'sMobileNumber",
    ];

    fields.forEach((field) => {
      if (obj.hasOwnProperty(field) && isNaN(Number(obj[field]))) {
        errors.push(`${field} must be a number.`);
      }
    });

    return errors;
  }

  async function validateUnit(obj) {
    let errors = [];

    const blockandunit = await getExistingUnitNo(
      propertyId,
      floorId,
      obj["Block"],
      obj["UnitNo"]
    );

    console.log(blockandunit);

    if (blockandunit.length != 0 && blockandunit[0].id != unitId) {
      console.log(blockandunit[0].id);
      errors.push("Same Block with UnitNo exists");
    }

    // Check ManagerMobile, MobileNumber, EmergencyContactMobile, SecondaryTenant'sMobileNumber, Unitratepersq, UtilityRate, PreviousUsage, CommonSpace(ifany), ServiceChargeRate
    const fields = [
      "Unitratepersq",
      "UtilityRate",
      "PreviousUsage",
      "CommonSpace",
      "ServiceChargeRate",
    ];

    fields.forEach((field) => {
      if (obj.hasOwnProperty(field) && isNaN(Number(obj[field]))) {
        errors.push(`${field} must be a number.`);
      }
    });

    // if (!isNaN(Number(obj.UnitNo)) && !isNaN(Number(obj.UnitSpace))) {

    // }

    return errors;
  }

  const handleUpdate = async () => {
    console.log(unitData);

    if (isEditing) {
      let errs = await validateUnit(unitData);
      if (!errs.length == 0) {
        console.log(errs);
        setError(errs);
        // setLoading(false);
        return;
      }
      errs = await validateTenant(tenantData);
      if (!errs.length == 0) {
        console.log(errs);
        setError(errs);
        // setLoading(false);
        return;
      }
      setError([]);
      setIsEditing(false);

      let rsd = "N/A";
      let usd = "N/A";
      let ssd = "N/A";

      if (unitData[`RentStartDate`] != undefined) {
        rsd = unitData[`RentStartDate`];
      }

      if (unitData[`UtilityStartDate`] != undefined) {
        usd = unitData[`UtilityStartDate`];
      }

      if (unitData[`SCStartDate`] != undefined) {
        ssd = unitData[`SCStartDate`];
      }

      await AddLog(
        `${user.Name} has updated Unit: ${unitData.UnitNo} of Block: ${unitData.Block}`
      );
      await updateUnit(propertyId, floorId, unitId, {
        "CommonSpace(ifany)": 0,
        ...unitData,
        rsd: rsd,
        usd: usd,
        ssd: ssd,
        UnitNo: parseInt(unitData[`UnitNo`]),
        currentTenantType: tenantData["TenantType"]
          ? tenantData["TenantType"]
          : "Vacant",
        currentTenant: tenantData["TenantName"]
          ? tenantData["TenantName"]
          : "N/A",
        currentBusinessName: tenantData["BusinessName"]
          ? tenantData["BusinessName"]
          : "",
      });

      if (unitData[`currentTenantId`] != "N/A") {
        await updateTenant(
          propertyId,
          floorId,
          unitId,
          unitData["currentTenantId"],
          tenantData
        );
      }

      if (imageFile) {
        await UploadImage(imageFile, `Tenants/${tenantData["id"]}/`);
      }

      nextFiles.map(async (f) => {
        if (f != undefined) {
          await UploadFile(f, `Tenants/${tenantData["id"]}/files`);
        }
      });
      setNextFiles([]);

      setTimeout(() => {
        getFiles();
        fetchImage();
      }, [2000]);
    } else {
      setIsEditing(true);
    }
  };

  const handleChange = async (e, label) => {
    // console.log(label);
    if (label == "Rent") {
      setRent(e.target.checked);
      let obj = {};
      obj["Rent"] = e.target.checked;
      setUnitData((prev) => {
        return { ...prev, ...obj };
      });
    } else if (label == "Utility") {
      setUtility(e.target.checked);
      let obj = {};
      obj["Utility"] = e.target.checked;
      setUnitData((prev) => {
        return { ...prev, ...obj };
      });
    } else if (label == "Service Charge") {
      setSc(e.target.checked);
      let obj = {};
      obj["ServiceCharge"] = e.target.checked;
      setUnitData((prev) => {
        return { ...prev, ...obj };
      });
    } else if (label == "Secondary Tenant") {
      setSecondary(e.target.checked);
      let obj = {};
      obj["SecondaryTenant"] = e.target.checked;
      setUnitData((prev) => {
        return { ...prev, ...obj };
      });
    }
  };

  const handleData = (e, label) => {
    const obj = {};
    obj[label.replace(/\s+/g, "")] = e.target.value;
    setUnitData((prev) => {
      return { ...prev, ...obj };
    });
  };

  const handleTenantData = (e, label) => {
    const obj = {};
    obj[label.replace(/\s+/g, "")] = e.target.value;
    setTenantData((prev) => {
      return { ...prev, ...obj };
    });
  };

  const fetchTenantData = async () => {
    const unit = await getTenant(
      propertyId,
      floorId,
      unitId,
      unitData["currentTenantId"]
    );
    setTenantData(unit);
    console.log(unit);
  };

  const fetchPastTenants = async () => {
    const pT = await getDocs(
      query(
        collection(
          db,
          `Properties/${propertyId}/Floors/${floorId}/Units/${unitId}/Tenants`
        ),
        where(`past`, `==`, true),
        orderBy(`addDate`, `desc`)
      )
    );
    const tens = [];
    pT.forEach((p) => {
      tens.push({ ...p.data(), id: p.id });
    });
    console.log(tens);
    setPastTenants(tens);
  };

  useEffect(() => {
    fetchProperty();
  }, [propertyId]);

  useEffect(() => {
    fetchFloor();
  }, [floorId]);

  useEffect(() => {
    fetchUnitData();
    fetchPastTenants();
  }, [unitId]);

  useEffect(() => {
    if (rolesStream && user && !rolesStream[user.Role][`ViewUnitDetails`]) {
      navigate(-1);
    }
  }, [rolesStream, user]);

  useEffect(() => {
    console.log(unitData);
    if (unitData["currentTenantId"] != undefined) {
      fetchTenantData();
    }
  }, [unitData]);

  useEffect(() => {
    fetchImage();
    getFiles();
    console.log(tenantData);
  }, [tenantData]);

  const fetchImage = async () => {
    const x = await GetImage(`Tenants/${tenantData["id"]}`);
    setImage(x);
  };

  const deleteFile = async (fileName) => {
    await DeleteFile(`Tenants/${tenantData["id"]}/files/${fileName}`);
    getFiles();
  };

  const deleteNextFile = async (index) => {
    setNextFiles((prev) => {
      delete prev[index];
      return [...prev];
    });
  };

  const getFiles = async () => {
    const files = await GetFiles(`Tenants/${tenantData["id"]}/files`);
    console.log("files");
    setFiles(files);
  };

  const fileHandler = async (event) => {
    const newFile = event.target.files[0]; // Get only the first file
    setImageFile(newFile);

    const reader = new FileReader();

    reader.onload = function (e) {
      setImageFileURL(e.target.result);
    };

    reader.readAsDataURL(newFile);
  };
  const fileHandler2 = async (event) => {
    const newFiles = Array.from(event.target.files);
    setNextFiles([...nextFiles, ...newFiles]);
  };

  return (
    <div>
      {unitData && (
        <PageTitle
          title={
            unitData["currentTenantId"] == "N/A"
              ? "Vacant Unit"
              : unitData["currentTenant"]
          }
          subTitle="Unit Details"
          image={image}
        />
      )}
      {unitData &&
        fieldData.map((f) => {
          return (
            <FieldContainer>
              {f.map((x) => {
                return (
                  <FieldSection>
                    <div
                      className="flex-row color-primary"
                      style={{ marginBottom: "0.5rem" }}
                    >
                      {x.fieldIcon}
                      {(!x.activation ||
                        tenantData["TenantType"] == "Position") && (
                        <label>{x.fieldTitle}</label>
                      )}
                    </div>
                    {x["fieldItems"].map((y) => {
                      if (
                        !y.activation ||
                        (y.activation == "Rent" && rent) ||
                        (y.activation == "Utility" && utility) ||
                        (y.activation == "SC" && sc) ||
                        (y.activation == "Secondary" && secondary) ||
                        (y.activation == "Position" &&
                          tenantData["TenantType"] == "Primary") ||
                        (y.activation == "Primary" &&
                          tenantData["TenantType"] == "Position")
                      ) {
                        if (y.type == "text") {
                          return (
                            <>
                              {(y.origin != "tenant" ||
                                unitData[`currentTenantType`] != "Vacant") &&
                                (y.label != "Security Deposit" ||
                                  rolesStream[user.Role][
                                    `SecurityDepositAccess`
                                  ]) && (
                                  <TextInputWithLabel
                                    label={y.label}
                                    label_bn={y.label_bn}
                                    placeholder_bn={y.placeholder_bn}
                                    notEditable={
                                      y.label == "Property" ||
                                      y.label == "Floor" ||
                                      ((x.fieldTitle == "Location" ||
                                        x.fieldTitle == "Entry Info") &&
                                        rolesStream &&
                                        user &&
                                        !rolesStream[user.Role][
                                          `EditUnitMaster`
                                        ])
                                        ? true
                                        : !isEditing
                                    }
                                    value={
                                      y.label == "Property"
                                        ? currentProperty
                                        : y.label == "Floor"
                                        ? currentFloor
                                        : `${
                                            y.origin == "unit"
                                              ? `${
                                                  unitData[
                                                    y.label.replace(/\s+/g, "")
                                                  ]
                                                }`
                                              : `${
                                                  tenantData[
                                                    y.label.replace(/\s+/g, "")
                                                  ]
                                                }`
                                          }`
                                    }
                                    placeholder={y.placeholder}
                                    handleChange={
                                      y.origin == "unit"
                                        ? handleData
                                        : y.origin == "tenant"
                                        ? handleTenantData
                                        : ""
                                    }
                                  />
                                )}
                            </>
                          );
                        } else if (y.type == "checkbox") {
                          return (
                            <CheckboxInputWithLabel
                              label={y.label}
                              label_bn={y.label_bn}
                              handleChange={handleChange}
                              notEditable={
                                y.label == "Property" ||
                                y.label == "Floor" ||
                                ((x.fieldTitle == "Location" ||
                                  x.fieldTitle == "Entry Info") &&
                                  rolesStream &&
                                  user &&
                                  !rolesStream[user.Role][`EditUnitMaster`])
                                  ? true
                                  : !isEditing
                              }
                              placeholder={"bleh"}
                              value={
                                y.label.replace(/\s+/g, "") == "Rent" &&
                                rent == true
                                  ? rent
                                  : y.label.replace(/\s+/g, "") == "Utility" &&
                                    utility == true
                                  ? utility
                                  : y.label.replace(/\s+/g, "") ==
                                      "ServiceCharge" && sc == true
                                  ? sc
                                  : y.label.replace(/\s+/g, "") ==
                                      "SecondaryTenant" && secondary == true
                                  ? secondary
                                  : false
                              }
                            />
                          );
                        } else if (y.type == "date") {
                          return (
                            <DateInputWithLabel
                              label={y.label}
                              label_bn={y.label_bn}
                              notEditable={
                                y.label == "Property" ||
                                y.label == "Floor" ||
                                ((x.fieldTitle == "Location" ||
                                  x.fieldTitle == "Entry Info") &&
                                  rolesStream &&
                                  user &&
                                  !rolesStream[user.Role][`EditUnitMaster`])
                                  ? true
                                  : !isEditing
                              }
                              value={`${
                                y.origin == "unit"
                                  ? `${unitData[y.label.replace(/\s+/g, "")]}`
                                  : `${tenantData[y.label.replace(/\s+/g, "")]}`
                              }`}
                              placeholder={y.placeholder}
                              handleChange={
                                y.origin == "unit"
                                  ? handleData
                                  : y.origin == "tenant"
                                  ? handleTenantData
                                  : ""
                              }
                            />
                          );
                        } else if (y.type == "dropdown") {
                          return (
                            <>
                              {(y.origin != "tenant" ||
                                unitData[`currentTenantType`] != "Vacant") && (
                                <DropdownWithLabel
                                  label={y.label}
                                  label_bn={y.label_bn}
                                  items={y.items}
                                  defaultVal={`${
                                    y.origin == "unit"
                                      ? `${
                                          unitData[y.label.replace(/\s+/g, "")]
                                        }`
                                      : `${
                                          tenantData[
                                            y.label.replace(/\s+/g, "")
                                          ]
                                        }`
                                  }`}
                                  value={`${
                                    y.origin == "unit"
                                      ? `${
                                          unitData[y.label.replace(/\s+/g, "")]
                                        }`
                                      : `${
                                          tenantData[
                                            y.label.replace(/\s+/g, "")
                                          ]
                                        }`
                                  }`}
                                  handleChange={
                                    y.origin == "unit"
                                      ? handleData
                                      : y.origin == "tenant"
                                      ? handleTenantData
                                      : ""
                                  }
                                  notEditable={!isEditing}
                                />
                              )}
                            </>
                          );
                        } else if (y.type == "Button") {
                          return (
                            <div
                              onClick={() => {
                                navigate(
                                  `/entryHistory/${unitData[`currentTenantId`]}`
                                );
                              }}
                            >
                              <br></br>
                              {unitData &&
                                unitData[`currentTenantId`] &&
                                user &&
                                rolesStream &&
                                rolesStream[user.Role][`ViewUnitHistory`] && (
                                  <PrimaryButton text={"See History"} />
                                )}
                            </div>
                          );
                        }
                      }
                    })}
                  </FieldSection>
                );
              })}
            </FieldContainer>
          );
        })}
      <br></br>
      <FieldContainer>
        <FieldSection>
          <div
            className="flex-row color-primary"
            style={{ marginBottom: "0.5rem" }}
          >
            <QrCode />
            <label>{bn ? `QR Code` : `কিউ আর কড`}</label>
          </div>
          <div
            style={{
              border: "1px solid lightgrey",
              borderRadius: "2rem",
              marginTop: "1rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <QRCode
              style={{ width: "12rem" }}
              value={`https://www.rentmilloh.com/properties/${propertyId}/floors/${floorId}/units/${unitId}/rent`}
            />
          </div>
        </FieldSection>
        <FieldSection>
          {(Object.keys(files).length > 0 || nextFiles.length > 0) && (
            <label
              style={{
                fontSize: "0.8rem",
                color: "#9a9a9a",
                display: "flex",
                alignItems: "center",
                marginBottom: "0.5rem",
              }}
            >
              <AttachFile /> Files:
            </label>
          )}
          {Object.keys(files).length > 0 &&
            Object.keys(files).map((f) => {
              return (
                <div style={{ alignItems: "center" }} className="flex-row">
                  <BlueItem link={files[f]} text={f} />
                  {isEditing && (
                    <DeleteButton handleDelete={deleteFile} name={f} />
                  )}
                </div>
              );
            })}
          {nextFiles.map((f, index) => {
            return (
              <div className="flex-row">
                {f && (
                  <>
                    <BlueItem text={f.name} />
                    {isEditing && (
                      <DeleteButton
                        name={index}
                        handleDelete={deleteNextFile}
                      />
                    )}
                    <br></br>
                  </>
                )}
              </div>
            );
          })}
        </FieldSection>
      </FieldContainer>
      {pastTenants.length > 0 && (
        <FieldContainer>
          <FieldSection>
            <div
              className="flex-column color-primary"
              style={{ marginBottom: "0.5rem" }}
            >
              <label>{bn ? `Past Tenants` : `কিউ আর কড`}</label>
              {pastTenants.map((p) => {
                return (
                  <div onClick={() => navigate(`tenants/${p.id}`)}>
                    <BlueItem text={p.BusinessName} />
                  </div>
                );
              })}
            </div>
          </FieldSection>
        </FieldContainer>
      )}

      {isEditing && (
        <>
          <UploadButton
            text={`${imageFile ? "Change Image" : "Add Image"}`}
            single={true}
            fileHandler={fileHandler}
            imageOnly={true}
          />
          <UploadButton
            text={"Add Files"}
            fileHandler={fileHandler2}
            imageOnly={false}
          />
        </>
      )}
      <br></br>
      {error.map((e) => {
        return <ErrorMsg text={e} />;
      })}
      <br></br>
      <div className="flex-row">
        {rolesStream && user && rolesStream[user.Role][`EditUnitDetails`] && (
          <PrimaryButton
            text={isEditing ? "Save" : "Edit"}
            handleClick={() => {
              handleUpdate();
            }}
          />
        )}
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}

export default UnitDetails;
