import { UserAuth } from "../AuthContext";
import classes from "../styles/DropdownWithLabel.module.css";

const DropdownWithLabel = ({
  label,
  items,
  handleChange,
  value,
  notEditable,
  defaultVal,
  label_bn,
}) => {
  const { bn } = UserAuth();
  return (
    <div className={classes.inputContainer}>
      <label>
        {bn ? label : label_bn}
        <span>{value && notEditable ? `: ${value}` : null}</span>
      </label>
      {!notEditable && (
        <select
          value={defaultVal}
          onChange={(e) => {
            handleChange(e, label);
          }}
        >
          {items.map((i) => {
            return <option value={i.Value}>{i.Name}</option>;
          })}
        </select>
      )}
    </div>
  );
};

export default DropdownWithLabel;
