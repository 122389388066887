import classes from "../styles/FieldSection.module.css";

const FieldSection = ({ children }) => {
  return (
    <>
      <div className={classes.fieldSection}>{children}</div>
    </>
  );
};

export default FieldSection;
