import { useEffect, useState, useRef } from "react";
import classes from "../styles/NotificationDropdown.module.css";
import { Link, useNavigate } from "react-router-dom";
import { getPendingRequests } from "../firebase";
import { UserAuth } from "../AuthContext";

const NotificationDropdown = (setIsDropdownVisible, isDropdownVisible) => {
  const [notificationData, setNotificationData] = useState([]);
  const { user, rolesStream } = UserAuth();
  // const [isDropdownVisible, setIsDropdownVisible] = useState(true); // the new state
  const dropdownRef = useRef(null); // ref for the dropdown
  const navigate = useNavigate();

  const fetchRequests = async () => {
    const reqs = await getPendingRequests();
    setNotificationData(reqs);

    reqs.map((r, index) => {
      if (
        r.typeId == "otherComplain" &&
        !rolesStream[user.Role][`RecieveOtherComplain`]
      ) {
        delete reqs[index];
      } else if (
        r.typeId == "propertyComplain" &&
        !rolesStream[user.Role][`RecievePropertyComplain`]
      ) {
        delete reqs[index];
      } else if (
        r.typeId == "tenantComplain" &&
        !rolesStream[user.Role][`RecieveTenantComplain`]
      ) {
        delete reqs[index];
      } else if (
        r.typeId == "moveIn" &&
        !rolesStream[user.Role][`RecieveMoveInApplication`]
      ) {
        delete reqs[index];
      } else if (
        r.typeId == "moveOut" &&
        !rolesStream[user.Role][`RecieveMoveOutApplication`]
      ) {
        delete reqs[index];
      } else if (
        r.typeId == "changePositionTenant" &&
        !rolesStream[user.Role][`RecieveChangePositionTenantApplication`]
      ) {
        delete reqs[index];
      } else if (
        r.typeId == "changeSecondaryTenant" &&
        !rolesStream[user.Role][`RecieveChangeSecondaryTenantApplication`]
      ) {
        delete reqs[index];
      }
    });
  };

  useEffect(() => {
    fetchRequests();
  }, [user, rolesStream]);

  if (!isDropdownVisible) {
    return null;
  }

  return (
    <>
      {isDropdownVisible && (
        <div ref={dropdownRef} className={classes.notificationContainer}>
          <div className={classes.notificationHeader}>
            <Link to="/requests/all">All Requests</Link>
            <Link to="/requests/my">My Requests</Link>
          </div>
          <div className={classes.notificationsList}>
            {notificationData.map((n) => {
              return (
                <div
                  key={n.id}
                  onClick={() => navigate(`/requests/${n.typeId}/${n.id}`)}
                >
                  <p>{`${n.type} Request`}</p>
                  <p>{`by ${n.RequestedBy}`}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationDropdown;
