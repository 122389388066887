import classes from "../styles/UnitTitleStatement.module.css";

const UnitTitleStatement = ({ Property, Floor, UnitName }) => {
  return (
    <div className={classes.unitTitleHolder}>
      <div className="flex-column">
        <p style={{ fontWeight: "bold", fontSize: "0.9rem" }}>{UnitName}</p>
        <div className="flex-row">
          <p className={classes.Sub}>{`${Property}, ${Floor}`}</p>
        </div>
      </div>
    </div>
  );
};

export default UnitTitleStatement;
