import React, { useEffect } from "react";
import PageTitle from "../../components/PageTitle";
import { useState } from "react";
import {
  AddLog,
  DeleteFile,
  GetFiles,
  deleteProperty,
  getProperty,
  updateProperty,
} from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { TextInputWithLabel } from "../../components/TextInputWithLabel";
import PrimaryButton from "../../components/PrimaryButton";
import BlueItem from "../../components/BlueItem";
import { AttachFile, Delete } from "@mui/icons-material";
import DeleteButton from "../../components/DeleteButton";
import DeleteDialog from "../../components/DeleteDialog";
import PrimaryDeleteButton from "../../components/PrimaryDeleteButton";
import { UserAuth } from "../../AuthContext";

function PropertyDetails() {
  const [propertyData, setPropertyData] = useState({});
  const { propertyId } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [files, setFiles] = useState({});
  const [toggleDelete, setToggleDelete] = useState(false);
  const navigate = useNavigate();
  const { user, rolesStream } = UserAuth();

  const [fieldData] = useState([
    {
      label: "Property Name",
      placeholder: "Enter Property Name",
      label_bn: "প্রপার্টির নাম",
      placeholder_bn: "প্রপার্টির নাম লিখুন",
    },
    {
      label: "Property Size",
      placeholder: "Enter Property Size",
      label_bn: "প্রপার্টির আকার",
      placeholder_bn: "প্রপার্টির আকার লিখুন",
    },
    {
      label: "Property Location",
      placeholder: "Enter Property Location",
      label_bn: "প্রপার্টির অবস্থান",
      placeholder_bn: "প্রপার্টির অবস্থান লিখুন",
    },
    {
      label: "Default Floor Area",
      placeholder: "Enter Floor Area",
      label_bn: "ডিফল্ট তলার ক্ষেত্রফল",
      placeholder_bn: "তলার ক্ষেত্রফল লিখুন",
    },
    {
      label: "Property Type",
      placeholder: "Enter Property Type",
      label_bn: "সম্পত্তির ধরণ",
      placeholder_bn: "সম্পত্তির ধরণ লিখুন",
    },
  ]);

  const fetchProperty = async (id) => {
    const property = await getProperty(id);
    console.log(property);
    setPropertyData(property);
  };

  const handleUpdate = async () => {
    if (isEditing) {
      setIsEditing(false);
      await updateProperty(propertyId, propertyData);
      await AddLog(
        `${user.Name} has updated a Property: ${propertyData.PropertyName}`
      );
      console.log("edited");
    } else {
      setIsEditing(true);
    }
  };

  const handleChange = async (e, label) => {
    let obj = {};
    obj[label.replace(/\s+/g, "")] = e.target.value;
    setPropertyData((prev) => {
      return { ...prev, ...obj };
    });
  };

  const handleDelete = async () => {
    await deleteProperty(propertyId);
    navigate("/properties");
  };

  const getFiles = async () => {
    const files = await GetFiles(`Properties/${propertyId}`);
    console.log("files");
    setFiles(files);
  };

  const deleteFile = async (fileName) => {
    await DeleteFile(`Properties/${propertyId}/${fileName}`);
    getFiles();
  };

  useEffect(() => {
    fetchProperty(propertyId);
  }, [propertyId]);

  useEffect(() => {
    getFiles();
  }, [propertyId]);

  return (
    <>
      <PageTitle title="Property Details" />
      {Object.keys(propertyData).length > 0 && (
        <div className="flex-column">
          {fieldData.map((p) => {
            return (
              <React.Fragment key={p}>
                <TextInputWithLabel
                  handleChange={handleChange}
                  label={p.label}
                  placeholder={p.placeholder}
                  notEditable={!isEditing}
                  value={propertyData[p.label.replace(/\s+/g, "")]}
                />
              </React.Fragment>
            );
          })}
          <br></br>
          {Object.keys(files).length > 0 && (
            <label
              style={{
                fontSize: "0.8rem",
                color: "#9a9a9a",
                display: "flex",
                alignItems: "center",
                marginBottom: "0.5rem",
              }}
            >
              <AttachFile /> Files:
            </label>
          )}
          {Object.keys(files).length > 0 &&
            Object.keys(files).map((f) => {
              return (
                <div style={{ alignItems: "center" }} className="flex-row">
                  <BlueItem link={files[f]} text={f} />
                  {isEditing && (
                    <DeleteButton handleDelete={deleteFile} name={f} />
                  )}
                </div>
              );
            })}
          <br></br>
          {rolesStream &&
            user &&
            rolesStream[user.Role][`EditPropertyDetails`] && (
              <div className="flex-row">
                <PrimaryButton
                  text={isEditing ? "Save" : "Edit"}
                  handleClick={() => {
                    handleUpdate();
                  }}
                />

                <PrimaryDeleteButton
                  text={"Delete"}
                  handleClick={() => {
                    setToggleDelete(true);
                  }}
                />
              </div>
            )}

          {toggleDelete && (
            <DeleteDialog
              text="Are you sure you want to delete the property?"
              onApproval={() => {
                setToggleDelete(false);
                handleDelete();
              }}
              onDecline={() => {
                setToggleDelete(false);
              }}
            />
          )}
        </div>
      )}
    </>
  );
}

export default PropertyDetails;
