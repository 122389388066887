import { useEffect } from "react";
import { UserAuth } from "../AuthContext";
import { Navigate, useNavigate } from "react-router-dom";
import { SentimentVeryDissatisfied } from "@mui/icons-material";
import classes from "../styles/Inactive.module.css";

const Inactive = () => {
  const { user } = UserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.Status == "Active") {
      navigate("/home");
    }
  }, [user]);

  return (
    <div className={classes.inactiveContainer}>
      <SentimentVeryDissatisfied />
      <div>
        Your account is <span>Inactive</span>
      </div>
    </div>
  );
};

export default Inactive;
