import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../AuthContext";
import PrimaryButton from "../components/PrimaryButton";
import classes from "../styles/Login.module.css";
import { Loading } from "../components/Loading";
import ErrorMsg from "../components/ErrorMsg";

function Login() {
  const { Login, setUser } = UserAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [err, setErr] = useState("");

  const [loading, setLoading] = useState();

  const handleLogin = async () => {
    setLoading(true);
    const auth = await Login(username, password);
    if (auth == 1) {
      navigate("/home");
    } else if (auth == 2) {
      setErr(`Incorrect Password`);
      setLoading(false);
    } else {
      setErr(`Incorrect Credentials`);
      setLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <p>
        rent<span>milloh</span>
      </p>
      <input
        type="text"
        placeholder="Username"
        onChange={(e) => setUsername(e.target.value)}
        onKeyDown={(e) => {
          if (e.key == "Enter") {
            handleLogin();
          }
        }}
      ></input>
      <input
        type="password"
        placeholder="Password"
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={(e) => {
          if (e.key == "Enter") {
            handleLogin();
          }
        }}
      ></input>
      <br></br>
      {err.length != 0 && <ErrorMsg text={err} />}
      {loading && <Loading />}
      {!loading && <PrimaryButton text={"Log In"} handleClick={handleLogin} />}
      {!loading && <Link to={"/forgotPassword"}>Forgot Password</Link>}
    </div>
  );
}

export default Login;
