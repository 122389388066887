import { useNavigate } from "react-router-dom";
import classes from "../styles/PageTitle.module.css";
import { ArrowBack } from "@mui/icons-material";
import { UserAuth } from "../AuthContext";

const PageTitle = ({ title, subTitle, image, noBack, backLocation }) => {
  const navigate = useNavigate();
  const { user } = UserAuth();
  return (
    <div className={classes.titleContainer}>
      <div className="flex-row" style={{ alignItems: "center" }}>
        {!noBack && (
          <div
            className={`hidePrint ${classes.back}`}
            onClick={() => {
              if (!backLocation) {
                navigate(-1);
              } else {
                navigate(backLocation);
              }
            }}
          >
            <ArrowBack />
          </div>
        )}
        {image && <img src={image} />}
        <div className="flex-column">
          <div className={classes.title}>{title}</div>
          <div className={classes.subTitle}>{subTitle}</div>
          {user && <div className="printOnly">Generated By: {user.Name}</div>}
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
