import { UserAuth } from "../AuthContext";
import classes from "../styles/Dropdown.module.css";

const Dropdown = ({
  items,
  handleChange,
  defaultVal,
  label,
  value,
  big,
  label_bn,
}) => {
  const { bn } = UserAuth();
  return (
    <div className={`flex-column ${classes.dropdownContainer} hidePrint`}>
      <label>{bn ? label : label_bn}</label>
      <select
        className={classes.dropdown}
        value={value}
        defaultValue={defaultVal}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
      >
        {items.map((i) => {
          return <option value={i.value}>{i.text}</option>;
        })}
      </select>
    </div>
  );
};

export default Dropdown;
