import {
  collection,
  doc,
  onSnapshot,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { createContext, useContext } from "react";
import { useCookies } from "react-cookie";
import FullScreenLoading from "./components/FullScreenLoading";
import { db, findUser, updateUser } from "./firebase";
import { useNavigate } from "react-router-dom";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [rolesStream, setRolesStream] = useState();
  const roleSub = useRef();
  const userSub = useRef();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  const [bn, setBn] = useState(null);
  const [userCookie, setUserCookie, removeUserCookie] = useCookies();
  const [passwordCookie, setPasswordCookie, removePasswordCookie] =
    useCookies();

  const Login = async (username, password) => {
    const foundUser = await findUser(username);
    if (foundUser !== undefined && foundUser.Password === password) {
      setUser(foundUser);
      await updateUser(foundUser.id, { LastLogin: serverTimestamp() });
      setUserCookie("username", username, { path: "/" });
      setPasswordCookie("password", password, { path: "/" });
      startListening(foundUser.id);
      return 1;
    } else if (foundUser !== undefined && foundUser.Password !== password) {
      return 2;
    } else {
      return 0;
    }
  };

  const LogOut = async () => {
    if (userSub.current) {
      userSub.current();
    }

    if (roleSub.current) {
      roleSub.current();
    }

    setUser(null);
    removeUserCookie("username");
    removePasswordCookie("password");
    navigate("/login");
  };

  const startListening = (id) => {
    // Unsubscribe from the old user snapshot
    if (userSub.current) {
      userSub.current();
    }

    // Subscribe to the new user snapshot
    userSub.current = onSnapshot(doc(db, "Users", id), (doc) => {
      setUser({ ...doc.data(), id: doc.id });
    });

    // Unsubscribe from the old roles snapshot
    if (roleSub.current) {
      roleSub.current();
    }

    // Subscribe to the new roles snapshot
    const q = query(collection(db, "Roles"));
    roleSub.current = onSnapshot(q, (querySnapshot) => {
      const roles = {};
      querySnapshot.forEach((doc) => {
        roles[doc.id] = doc.data();
      });
      setRolesStream(roles);
    });
  };

  useEffect(() => {
    if (userCookie.username && passwordCookie.password) {
      Login(userCookie.username, passwordCookie.password).then(() =>
        setLoading(false)
      );
    } else {
      setLoading(false);
    }
  }, [passwordCookie.password, userCookie.username]);

  if (loading) {
    return (
      <>
        <FullScreenLoading />
      </>
    );
  }

  return (
    <UserContext.Provider
      value={{ user, Login, loading, bn, setBn, rolesStream, LogOut }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
