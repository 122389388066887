import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import PrimaryButton from "../../components/PrimaryButton";
import RedItem from "../../components/RedItem";
import {
  getFloors,
  getProperties,
  getRoles,
  getUnits,
  updateTenant,
  updateUnit,
} from "../../firebase";
import { Loading } from "../../components/Loading";
import { deleteField } from "firebase/firestore";
import DeleteDialog from "../../components/DeleteDialog";

const UpcomingCancellations = () => {
  const [cancellations, setCancellations] = useState([]);
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const fetchCancellations = async () => {
    const props = await getProperties();
    const unitsArr = [];

    for (let p of props) {
      const floors = await getFloors(p.id);
      for (let f of floors) {
        const units = await getUnits(p.id, f.id);
        for (let u of units) {
          console.log(u);
          if (u.cancellingSoon != undefined) {
            unitsArr.push({ ...u, propertyId: p.id, floorId: f.id });
          }
        }
      }
    }
    setCancellations(unitsArr);
    setLoading(false);
    console.log(unitsArr);
  };

  const cancelTenant = async () => {
    setLoading(true);
    await updateUnit(data.p, data.f, data.u, {
      currentBusinessName: "N/A",
      currentTenantId: "N/A",
      currentTenantType: "Vacant",
      currentTenant: "N/A",
      cancellingSoon: deleteField(),
    });
    await updateTenant(data.p, data.f, data.u, data.t, {
      past: true,
    });
    setLoading(false);
    setToggle(false);
    fetchCancellations();
  };

  useEffect(() => {
    fetchCancellations();
  }, []);

  return (
    <>
      <PageTitle title={"Upcoming Cancellations"} />
      {/* <PrimaryButton
        text={"Add Role"}
        handleClick={() => {
          navigate(`/users/roles/add`);
        }}
      /> */}
      <br></br>
      {loading && <Loading />}
      {cancellations.map((r) => {
        return (
          <div
          // style={{ width: "10rem" }}
          >
            <div className="flex-row">
              <div
                onClick={() =>
                  navigate(
                    `/properties/${r.propertyId}/floors/${r.floorId}/units/${r.id}`
                  )
                }
                style={{ minWidth: "10rem", marginRight: "1rem" }}
              >
                <RedItem
                  text={`${r.currentBusinessName} [Lease Ending: ${r.cancellingSoon}]`}
                />
              </div>
              <div
                onClick={() => {
                  setData({
                    p: r.propertyId,
                    f: r.floorId,
                    u: r.id,
                    t: r.currentTenantId,
                  });
                  setToggle(true);
                }}
              >
                <RedItem text={"Cancel Tenant"} />
              </div>
            </div>
          </div>
        );
      })}
      {toggle && (
        <DeleteDialog
          text="Are you sure you want to cancel?"
          onApproval={() => cancelTenant()}
          onDecline={() => setToggle(false)}
        />
      )}
    </>
  );
};

export default UpcomingCancellations;
