import { useState } from "react";
import classes from "../styles/MultiCheckbox.module.css";

const MultiCheckbox = ({
  title,
  items,
  notEditable,
  setItems,
  icon,
  mainIndex,
  parentIndex,
}) => {
  const [allChecked, setAllChecked] = useState(false);

  const handleAllChecked = (e, mainIndex) => {
    setAllChecked(e.target.checked);

    let updatedItems = [...items];

    updatedItems[parentIndex][mainIndex]["subItems"].map((i) => {
      i.checked = e.target.checked;
      console.log(i.checked);
    });
    setItems(updatedItems);
  };

  const handleSingleCheckboxChange = (e, index, mainIndex) => {
    const updatedCheckboxes = [...items];
    updatedCheckboxes[parentIndex][mainIndex]["subItems"][index].checked =
      e.target.checked;
    setItems(updatedCheckboxes);

    setAllChecked(
      updatedCheckboxes[parentIndex][mainIndex]["subItems"].every(
        (checkbox) => checkbox.checked
      )
    );
  };

  return (
    <div className={classes.multiCheckboxContainer}>
      <label className={allChecked ? classes.active : ``}>
        {icon}
        <p>{title}</p>
        {notEditable && (
          <input
            type="checkbox"
            checked={allChecked}
            onChange={(e) => handleAllChecked(e, mainIndex)}
          />
        )}
      </label>
      {items[parentIndex][mainIndex]["subItems"].map((checkbox, index) => (
        <label
          key={checkbox.id}
          className={checkbox.checked ? classes.active : ``}
        >
          {notEditable && (
            <input
              type="checkbox"
              checked={checkbox.checked}
              onChange={(e) => handleSingleCheckboxChange(e, index, mainIndex)}
            />
          )}
          <div style={checkbox.checked ? { color: "green" } : { color: "red" }}>
            {checkbox.label}
            {!notEditable &&
              (checkbox.checked ? (
                <p
                  style={{
                    color: "green",
                    fontWeight: "600",
                    marginLeft: "0.3rem",
                  }}
                >{`:True`}</p>
              ) : (
                <p
                  style={{
                    color: "red",
                    fontWeight: "600",
                    marginLeft: "0.3rem",
                  }}
                >{`:False`}</p>
              ))}
          </div>
        </label>
      ))}
    </div>
  );
};

export default MultiCheckbox;
