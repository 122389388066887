import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import { TextInputWithLabel } from "../../components/TextInputWithLabel";
import {
  AddLog,
  GetImage,
  getFloors,
  getProperties,
  getRoles,
  getUser,
  updateUser,
} from "../../firebase";
import { useParams } from "react-router-dom";
import PrimaryButton from "../../components/PrimaryButton";
import DropdownWithLabel from "../../components/DropdownWithLabel";
import { UserAuth } from "../../AuthContext";
import DropdownWithCheckboxes from "../../components/DropdownWithCheckboxes";
import TextLabel from "../../components/TextLabel";
import BlueItem from "../../components/BlueItem";

const UserDetails = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [userData, setUserData] = useState();
  const [propertyAccessData, setPropertyAccessData] = useState([]);
  const [items, setItems] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const { rolesStream, user } = UserAuth();

  const [fieldData] = useState([
    {
      label: "Name",
      placeholder: "Enter Name",
      label_bn: "নাম",
      placeholder_bn: "নাম লিখুন",
      type: "text",
    },
    {
      label: "Username",
      placeholder: "Enter Username",
      label_bn: "নাম",
      placeholder_bn: "নাম লিখুন",
      type: "text",
    },
    {
      label: "Designation",
      placeholder: "Enter Designation",
      label_bn: "পদবী",
      placeholder_bn: "পদবী লিখুন",
      type: "text",
    },
    {
      label: "Mobile",
      placeholder: "Enter Mobile",
      label_bn: "মোবাইল",
      placeholder_bn: "মোবাইল নম্বর লিখুন",
      type: "text",
    },
    {
      label: "Email",
      placeholder: "Enter Email",
      label_bn: "ইমেইল",
      placeholder_bn: "ইমেইল লিখুন",
      type: "text",
    },
    {
      label: "Status",
      type: "dropdown",
      items: [
        { Name: "Active", Value: "Active" },
        { Name: "Inactive", Value: "Inactive" },
      ],
    },
    {
      label: "Role",
      type: "dropdown-dynamic",
    },
  ]);

  const [image, setImage] = useState();

  const fetchImage = async () => {
    const x = await GetImage(`Users/${userData.id}`);
    setImage(x);
  };

  const { userId } = useParams();

  const handleUpdate = async () => {
    console.log(userData);
    if (isEditing) {
      setIsEditing(false);
      await updateUser(userId, {
        ...userData,
        PropertyAccess: propertyAccessData,
      });

      await AddLog(`${user.Name} has updated User: ${userData.Name}`);
      console.log("edited");
      fetchUsers();
    } else {
      setIsEditing(true);
    }
  };

  const handleChange = async (e, label) => {
    let obj = {};
    obj[label.replace(/\s+/g, "")] = e.target.value;
    setUserData((prev) => {
      return { ...prev, ...obj };
    });
  };

  const getRoleItems = (p) => {
    if (p.label === "Role") {
      return rolesData;
    } else {
      return p.items;
    }
  };

  const fetchUsers = async () => {
    const user = await getUser(userId);
    setUserData(user);
  };

  const fetchRoles = async () => {
    const allRoles = await getRoles();
    const roleData = [];
    allRoles.map((r) => {
      roleData.push({ Name: r.Name, Value: r.id });
    });
    console.log(roleData);
    setRolesData(roleData);
  };

  const fetchProperties = async () => {
    const arr = [];

    const properties = await getProperties();

    // Create an array of promises for getting floors data
    const floorsPromises = properties.map(async (p) => {
      let obj = {};
      const floors = await getFloors(p.id);
      const floorArr = [];
      obj["value"] = p.id;
      obj["label"] = p.PropertyName;
      floors.map((f) => {
        floorArr.push({ value: f.id, label: f.FloorName });
      });
      obj["children"] = floorArr;
      arr.push(obj);
    });

    // Wait for all the promises to resolve
    await Promise.all(floorsPromises);

    console.log(arr);
    setItems(arr);
  };

  const getFloorNameById = (propertyId, floorId) => {
    const property = items.find((item) => item.value === propertyId);
    if (property) {
      const floor = property.children.find((child) => child.value === floorId);
      if (floor) {
        return floor.label;
      }
    }
    return null;
  };

  const getPropertyNameById = (propertyId) => {
    const property = items.find((item) => item.value === propertyId);
    if (property) {
      return property.label;
    }
    return null;
  };

  useEffect(() => {
    fetchProperties();
    fetchUsers();
    fetchRoles();
  }, []);

  useEffect(() => {
    console.log(userData);
    fetchImage();
  }, [userData]);

  useEffect(() => {
    console.log(items);
  }, [items]);

  const handlePropertyAccess = async (x) => {
    setPropertyAccessData(x);
  };

  return (
    <>
      {userData && (
        <PageTitle
          title={userData.Name}
          subTitle="User Details"
          image={image}
        />
      )}
      {userData &&
        rolesStream &&
        rolesData.length > 0 &&
        fieldData.map((p) => {
          if (p.type == "text") {
            return (
              <React.Fragment key={p}>
                <TextInputWithLabel
                  handleChange={handleChange}
                  label={p.label}
                  placeholder={p.placeholder}
                  notEditable={!isEditing}
                  value={userData[p.label.replace(/\s+/g, "")]}
                />
              </React.Fragment>
            );
          } else if (p.type == "dropdown") {
            return (
              <React.Fragment key={p}>
                {p.label != "Status" ||
                  (rolesStream &&
                    user &&
                    rolesStream[user.Role][`StatusControl`] && (
                      <DropdownWithLabel
                        handleChange={handleChange}
                        label={p.label}
                        items={p.items}
                        notEditable={!isEditing}
                        defaultVal={userData[p.label.replace(/\s+/g, "")]}
                        value={userData[p.label.replace(/\s+/g, "")]}
                      />
                    ))}
              </React.Fragment>
            );
          } else if (p.type == "dropdown-dynamic") {
            return (
              <React.Fragment key={p}>
                {
                  <DropdownWithLabel
                    handleChange={handleChange}
                    label={p.label}
                    items={getRoleItems(p)}
                    notEditable={!isEditing}
                    defaultVal={userData.Role}
                    value={
                      rolesStream[userData[p.label.replace(/\s+/g, "")]].Name
                    }
                  />
                }
              </React.Fragment>
            );
          }
        })}
      {userData && (
        <>
          <TextLabel
            label="Property Access"
            value={`${userData.PropertyAccess == undefined ? "N/A" : ""}`}
          />
          {!isEditing &&
            userData.PropertyAccess &&
            userData.PropertyAccess.map((u) => {
              return (
                <div
                  style={{
                    width: "11rem",
                    // height: "",
                    background: "#f0f8fb",
                    border: "1px solid #0088c2",
                    borderRadius: "1rem",
                    textAlign: "center",
                    color: "#0088c2",
                    padding: "0.5rem",
                    fontSize: "0.8rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  {`Floor ${getFloorNameById(
                    u.parent,
                    u.floor
                  )}, Property ${getPropertyNameById(u.parent)}`}
                </div>
              );
            })}
        </>
      )}
      {isEditing && userData && userData.PropertyAccess && (
        <div className="flex-column">
          <DropdownWithCheckboxes
            mainLabel=""
            items={items}
            defaultValues={userData.PropertyAccess}
            handleChange={handlePropertyAccess}
          />
        </div>
      )}
      <br></br>
      {rolesStream && user && rolesStream[user.Role][`EditUser`] && (
        <PrimaryButton
          text={isEditing ? "Save" : "Edit"}
          handleClick={() => {
            handleUpdate();
          }}
        />
      )}
    </>
  );
};

export default UserDetails;
