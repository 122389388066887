import { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetEntries,
  GetEntriesOfFloor,
  GetEntriesOfProperty,
  db,
  getFloors,
  getProperties,
} from "../../firebase";
import { TableHeader } from "../../components/TableHeader";
import { Loading } from "../../components/Loading";
import { TableItem } from "../../components/TableItem";
import Dropdown from "../../components/Dropdown";
import UnitsContainer from "../../components/UnitsContainer";
import { DateInputWithLabel } from "../../components/DateInputWithLabel";
import ErrorMsg from "../../components/ErrorMsg";
import { collection, getDocs, query, where } from "firebase/firestore";
import DropdownWithLabel from "../../components/DropdownWithLabel";

const TenantEntryHistory = () => {
  const { tenantId } = useParams();
  const [currentProperty, setCurrentProperty] = useState();
  const [currentFloor, setCurrentFloor] = useState();
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("Rent");
  const navigate = useNavigate();
  const [date, setDate] = useState({
    "Start Date": getCurrentDateAndMonth(),
    "End Date": getCurrentDateAndMonth(),
  });

  function sortArrayByEntryTime(array) {
    return array.sort((a, b) => {
      const aTime = a.entryTime?.toDate().getTime();
      const bTime = b.entryTime?.toDate().getTime();

      if (aTime < bTime) {
        return -1;
      }
      if (aTime > bTime) {
        return 1;
      }
      return 0;
    });
  }

  function getCurrentDateAndMonth() {
    const currentDate = formatDate(new Date());
    return currentDate;
  }
  function convertFirebaseTimestampToDate(timestamp) {
    // Convert the Firebase Timestamp to a JavaScript Date object
    const date = timestamp.toDate();

    // Format the date as YYYY-MM-DD
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const handleChangeProperty = async (id) => {
    setLoading(true);
    setCurrentProperty(id);
  };

  const handleChangeFloor = async (id) => {
    setLoading(true);
    setCurrentFloor(id);
  };

  function formatTaka(amount) {
    const takaSymbol = "৳";
    const formattedAmount = new Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 2,
    }).format(amount);
    return `${takaSymbol}${formattedAmount}`;
  }

  const fetchEntries = async () => {
    const docRef = await getDocs(
      query(collection(db, "Entries"), where("TenantId", "==", tenantId))
    );
    const e = [];
    docRef.forEach((d) => {
      e.push({ ...d.data(), id: d.id });
    });
    setLoading(false);
    setEntries(e);
  };

  useEffect(() => {
    fetchEntries();
  }, []);

  const handleChange = (id) => {
    setType(id);
  };
  function findTextById(arr, id) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].value === id) {
        return arr[i].text;
      }
    }
    return null;
  }

  function convertDateFormat(dateStr) {
    // Create a date object from the string
    let date = new Date(dateStr);

    // Define the month names
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract the month and year from the date
    let month = monthNames[date.getUTCMonth()];
    let year = date.getUTCFullYear();

    // Return the formatted date
    return `${month}, ${year}`;
  }

  function formatDate(date) {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
  }

  return (
    <>
      <PageTitle title="Tenant Entry History" subTitle={`history`} />
      <div className="flex-row">
        <Dropdown
          label="Entry Type"
          items={[
            { text: "Rent", value: "Rent" },
            { text: "Utility", value: "Utility" },
            { text: "Service Charge", value: "Service Charge" },
          ]}
          handleChange={handleChange}
        />
      </div>
      <br></br>
      <TableHeader
        headings={[
          ["Entry Date", 5],
          ["Tenant Name", 6],
          ["Collected By", 4],
          ["Amount", 3],
          ["Type", 2],
          ["Payment Month", 4],
        ]}
      />
      {loading && <Loading />}
      <UnitsContainer>
        {entries.map((p) => {
          return (
            <div style={{ display: "flex" }} key={p.id}>
              {type == p.entryType && (
                <TableItem
                  handleClick={() => {
                    navigate(`/entries/${p.id}`);
                  }}
                  rowData={[
                    [convertFirebaseTimestampToDate(p.entryTime), 5],
                    [p.TenantName, 6],
                    [p.CollectedBy, 4],
                    [p.AmountPaid, 3],
                    [p.paymentType, 2],
                    [convertDateFormat(p.Month), 4],
                  ]}
                />
              )}
            </div>
          );
        })}
      </UnitsContainer>
    </>
  );
};

export default TenantEntryHistory;
