import React from "react";
import classes from "../styles/PrimaryButton.module.css";

function PrimaryButton({ text, handleClick, green, long, bnw }) {
  return (
    <div>
      <button
        className={`${bnw ? classes.bnw : ``} ${
          green ? classes.green : classes.pButton
        } ${long ? classes.long : ``}`}
        onClick={handleClick}
      >
        {text}
      </button>
    </div>
  );
}

export default PrimaryButton;
