import React from "react";
import "../styles/SuccessAnimation.scss"; // Import the SCSS file with the styles/

const SuccessAnimation = ({ text, green, darkYellow, noIcon, red }) => (
  <>
    <div
      className="flex-row"
      style={{
        alignItems: "center",
        color: `${
          green
            ? "darkGreen"
            : darkYellow
            ? "#d09300"
            : red
            ? "#e90000"
            : "#0088c2"
        }`,
        fontWeight: "500",
        fontSize: "1.75rem",
      }}
    >
      {!noIcon && (
        <svg
          width="80px"
          height="80px"
          viewBox="0 0 133 133"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="check-group"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <circle
              id="filled-circle"
              fill={`${
                green
                  ? "darkGreen"
                  : darkYellow
                  ? "#d09300"
                  : red
                  ? "darkred"
                  : "#0088c2"
              }`}
              cx="66.5"
              cy="66.5"
              r="54.5"
            ></circle>
            <circle
              id="white-circle"
              fill="#FFFFFF"
              cx="66.5"
              cy="66.5"
              r="55.5"
            ></circle>
            <circle
              id="outline"
              stroke={`${
                green
                  ? "darkGreen"
                  : darkYellow
                  ? "#d09300"
                  : red
                  ? "red"
                  : "#0088c2"
              }`}
              strokeWidth="4"
              cx="66.5"
              cy="66.5"
              r="54.5"
            ></circle>
            <polyline
              id="check"
              stroke="#FFFFFF"
              strokeWidth="4"
              points="41 70 56 85 92 49"
            ></polyline>
          </g>
        </svg>
      )}
      <p>{text}</p>
    </div>
  </>
);

export default SuccessAnimation;
