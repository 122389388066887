import { UserAuth } from "../AuthContext";
import classes from "../styles/TextAreaInput.module.css";

export const TextAreaInput = ({
  label,
  placeholder,
  handleChange,
  notEditable,
  value,
  placeholder_bn,
  label_bn,
}) => {
  const { bn } = UserAuth();
  return (
    <>
      <div className={`${classes.inputContainer} flex-column`}>
        <label>
          {bn ? label : label_bn}
          <span>{value && notEditable ? `: ${value}` : null}</span>
        </label>
        {!notEditable && placeholder && (
          <textarea
            defaultValue={value}
            placeholder={bn ? placeholder : placeholder_bn}
            onChange={(e) => handleChange(e, label)}
          ></textarea>
        )}
      </div>
    </>
  );
};
