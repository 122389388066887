import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import PrimaryButton from "../../components/PrimaryButton";
import {
  getFloor,
  getFloors,
  getProperties,
  getProperty,
  getUnits,
} from "../../firebase";
import { TableItem } from "../../components/TableItem";
import { TableHeader } from "../../components/TableHeader";
import UnitsContainer from "../../components/UnitsContainer";
import Dropdown from "../../components/Dropdown";
import { Loading } from "../../components/Loading";
import Table from "../../components/Table";
import UnitTitle from "../../components/UnitTitle";
import TextInputWithDropdown from "../../components/TextInputWithDropdown";
import { TextInputWithLabel } from "../../components/TextInputWithLabel";
import { UserAuth } from "../../AuthContext";

function ViewUnits() {
  const navigate = useNavigate();
  const location = useLocation();
  const { propertyId, floorId, block } = useParams();
  const [units, setUnits] = useState([]);
  const [floors, setFloors] = useState([]);
  const [properties, setProperties] = useState([]);
  const [currentProperty, setCurrentProperty] = useState();
  const [currentFloor, setCurrentFloor] = useState();
  const [searchData, setSearchData] = useState("");
  const [searchState, setSearchState] = useState("UnitNo");
  const [loading, setLoading] = useState(true);
  const [blockData, setBlockData] = useState();
  const { bn, user, rolesStream } = UserAuth();

  const fetchProperties = async () => {
    const docRef = await getProperties();
    const propertyData = [];
    docRef.forEach((d) => {
      propertyData.push({ text: d.PropertyName, value: d.id });
    });
    propertyData.push({ text: "All Properties", value: "allProperties" });
    setProperties(propertyData);
  };

  const fetchFloors = async (propertyId) => {
    const docRef = await getFloors(propertyId);
    console.log(docRef);
    const floorData = [];
    docRef.forEach((d) => {
      floorData.push({ text: d.FloorName, value: d.id });
    });
    floorData.push({ text: "All Floors", value: "allFloors" });
    setFloors(floorData);
  };

  const fetchSpecificFloorUnits = async (propertyId, floorId) => {
    const [prop, floor, unitsArr] = await Promise.all([
      getProperty(propertyId),
      getFloor(propertyId, floorId),
      getUnits(propertyId, floorId),
    ]);

    const propertyArr = [
      {
        PropertyName: prop.PropertyName,
        id: prop.id,
        Floors: [
          {
            FloorName: floor.FloorName,
            id: floor.id,
            Units: unitsArr,
          },
        ],
      },
    ];

    setUnits(propertyArr);
    setLoading(false);
  };

  const fetchSpecificPropertyUnits = async (propertyId) => {
    const [prop, floors] = await Promise.all([
      getProperty(propertyId),
      getFloors(propertyId),
    ]);

    const floorArrPromises = floors.map(async (f) => {
      const unitsArr = await getUnits(propertyId, f.id);
      return {
        FloorName: f.FloorName,
        id: f.id,
        Units: unitsArr,
      };
    });

    const floorsArr = await Promise.all(floorArrPromises);

    const propertyArr = [
      {
        PropertyName: prop.PropertyName,
        id: prop.id,
        Floors: floorsArr,
      },
    ];

    setUnits(propertyArr);
    setLoading(false);
  };

  const fetchAllUnits = async () => {
    const props = await getProperties();
    const propertyArrPromises = props.map(async (p) => {
      const floors = await getFloors(p.id);
      const floorArrPromises = floors.map(async (f) => {
        const unitsArr = await getUnits(p.id, f.id);
        return {
          FloorName: f.FloorName,
          id: f.id,
          Units: unitsArr,
        };
      });
      const floorsArr = await Promise.all(floorArrPromises);
      return {
        PropertyName: p.PropertyName,
        id: p.id,
        Floors: floorsArr,
      };
    });

    const propertyArr = await Promise.all(propertyArrPromises);
    setUnits(propertyArr);
    setLoading(false);
  };

  const handleChangeProperty = async (id) => {
    setLoading(true);
    navigate(`/properties/${id}/floors/allFloors/units/b/noBlocks`);
  };

  const handleChangeFloor = async (id) => {
    setLoading(true);
    navigate(`/properties/${propertyId}/floors/${id}/units/b/noBlocks`);
  };

  useEffect(() => {
    if (propertyId == "allProperties" && floorId == "allFloors") {
      fetchAllUnits();
    } else if (floorId == "allFloors" && propertyId != "allProperties") {
      console.log("All floors of specific property");
      fetchSpecificPropertyUnits(propertyId);
    } else if (propertyId != "allProperties" && floorId != "allFloors") {
      fetchSpecificFloorUnits(propertyId, floorId);
    }

    setCurrentProperty(propertyId);
    setCurrentFloor(floorId);
    fetchProperties();
    fetchFloors(propertyId);
  }, [propertyId, floorId]);

  useEffect(() => {
    console.log(units);
  }, [units]);

  useEffect(() => {
    setBlockData(block);
  }, [block]);

  const handleSearchChange = (e, label) => {
    setSearchData(e.target.value);
  };

  return (
    <div>
      <PageTitle
        title={bn ? "Units" : `ইউনিট`}
        backLocation={
          !location.pathname.includes("noBlocks")
            ? null
            : `/properties/${propertyId}/floors/`
        }
        subTitle={
          location.pathname.includes("noBlocks")
            ? `Showing All Blocks`
            : `of Block ${blockData}`
        }
      />
      <div className="flex-row">
        {currentFloor != "allFloors" &&
          currentProperty != "allProperties" &&
          rolesStream &&
          user &&
          rolesStream[user.Role][`AddUnits`] && (
            <PrimaryButton
              text={bn ? "Add Unit" : `অ্যাড ইউনিট`}
              handleClick={() =>
                navigate(
                  `/properties/${propertyId}/floors/${floorId}/units/add`
                )
              }
            />
          )}
        {!location.pathname.includes("noBlocks") && (
          <div style={{ marginLeft: "1rem" }}>
            <PrimaryButton
              text={bn ? "All Blocks" : ``}
              handleClick={() =>
                navigate(
                  `/properties/${propertyId}/floors/${floorId}/units/b/noBlocks`
                )
              }
            />
          </div>
        )}
      </div>

      <br></br>
      <div className="flex-wrap">
        {currentProperty && properties.length > 0 && (
          <Dropdown
            items={properties}
            label="Property"
            label_bn="প্রপার্টি"
            handleChange={handleChangeProperty}
            defaultVal={propertyId}
          />
        )}

        {currentFloor && floors.length > 0 && (
          <Dropdown
            items={floors}
            label="Floor"
            label_bn="ফ্লর"
            handleChange={handleChangeFloor}
            defaultVal={floorId}
          />
        )}
        <TextInputWithDropdown
          label="Search"
          placeholder="Search"
          setSearchState={setSearchState}
          hideBlock={true}
          name={true}
          handleChange={handleSearchChange}
        />
      </div>

      <br></br>
      {units && (
        <Table>
          <TableHeader
            headings={[
              [bn ? "Unit Info" : `ইউনিট ইনফো`, 7],
              [bn ? "Tenant Type" : `টেন্যান্ট টাইপ`, 6],
              [bn ? "Tenant Name" : `টেন্যান্ট নাম`, 6],
            ]}
          />
          {loading && <Loading />}

          {units.map((p) => {
            if (p.Floors) {
              return p.Floors.map((f) => {
                if (f.Units.length > 0) {
                  return (
                    <>
                      <UnitsContainer
                        Property={p.PropertyName}
                        Floor={f.FloorName}
                      >
                        {f.Units.map((u) => {
                          if (
                            (u.Block == blockData || blockData == "noBlocks") &&
                            ((searchData != "" &&
                              searchState == "UnitNo" &&
                              u.UnitNo.toString()
                                .toLowerCase()
                                .startsWith(
                                  searchData.toString().toLowerCase()
                                )) ||
                              (searchData != "" &&
                                searchState == "UnitName" &&
                                u.currentBusinessName
                                  .toString()
                                  .toLowerCase()
                                  .startsWith(
                                    searchData.toString().toLowerCase()
                                  )) ||
                              searchData == "")
                          ) {
                            return (
                              <>
                                <TableItem
                                  handleClick={() => {
                                    if (
                                      rolesStream &&
                                      user &&
                                      rolesStream[user.Role][`ViewUnitDetails`]
                                    ) {
                                      // console.log(p);
                                      navigate(
                                        `/properties/${p.id}/floors/${f.id}/units/${u.id}`
                                      );
                                    }
                                  }}
                                  rowData={[
                                    [
                                      <UnitTitle
                                        UnitNo={u.UnitNo}
                                        UnitName={u.currentBusinessName}
                                        Block={u.Block}
                                      />,
                                      7,
                                    ],
                                    [<div>{`${u.currentTenantType}`}</div>, 6],
                                    [<div>{`${u.currentTenant}`}</div>, 6],
                                  ]}
                                />
                              </>
                            );
                          }
                        })}
                        <br></br>
                      </UnitsContainer>
                    </>
                  );
                }
              });
            }
          })}
        </Table>
      )}
    </div>
  );
}

export default ViewUnits;
