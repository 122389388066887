import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AddLog, getFloors, getProperties, updateFloor } from "../../firebase";
import PageTitle from "../../components/PageTitle";
import { TableHeader } from "../../components/TableHeader";
import { TableItem } from "../../components/TableItem";
import PrimaryButton from "../../components/PrimaryButton";
import { Loading } from "../../components/Loading";
import TableEdit from "../../components/TableEdit";
import Dropdown from "../../components/Dropdown";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { UserAuth } from "../../AuthContext";

function ViewFloors() {
  const { propertyId } = useParams();
  const [floors, setFloors] = useState([]);
  const [properties, setProperties] = useState([]);
  const [currentProperty, setCurrentProperty] = useState();
  const [loading, setLoading] = useState(true);
  const { user, rolesStream, bn } = UserAuth();

  const navigate = useNavigate();

  function hasMatchingParent(id, arrayOfObjects) {
    for (let i = 0; i < arrayOfObjects.length; i++) {
      if (arrayOfObjects[i].floor === id) {
        return true;
      }
    }
    return false;
  }

  const fetchFloors = async () => {
    setFloors([]);
    const docRef = await getFloors(propertyId, setFloors);
    docRef.map((p, index) => {
      if (
        hasMatchingParent(p.id, user.PropertyAccess) == false &&
        rolesStream[user.Role].Name != "Admin" &&
        rolesStream[user.Role].Name != "SuperAdmin"
      ) {
        delete docRef[index];
      }
    });
    console.log(docRef);
    setFloors(docRef);
    setLoading(false);
  };

  const fetchProperties = async () => {
    const docRef = await getProperties();
    const propertyData = [];
    docRef.forEach((d) => {
      propertyData.push({ text: d.PropertyName, value: d.id });
      if (d.id == propertyId) {
        setCurrentProperty(d.PropertyName);
      }
    });
    setProperties(propertyData);
  };

  const handleChangeProperty = async (id) => {
    setLoading(true);
    navigate(`/properties/${id}/floors`);
  };

  function rearrangeArray(arrayy, fromIndex, toIndex) {
    let array = arrayy;
    // Check if input indices are valid
    if (
      fromIndex < 0 ||
      toIndex < 0 ||
      fromIndex >= array.length ||
      toIndex >= array.length
    ) {
      return null;
    }

    // Remove the element from the array and store it in a variable
    const removedElement = array.splice(fromIndex, 1)[0];

    // Insert the removed element after the target index
    array.splice(toIndex, 0, removedElement);

    // Update the Order property of each object in the array
    for (let i = 0; i < array.length; i++) {
      array[i].Order = i;
    }

    return array;
  }

  const swap = async (x, y) => {
    console.log(floors);
    console.log(x);
    console.log(y);
    const newArr = rearrangeArray(floors, x, y);
    console.log(newArr);

    newArr.map(async (n) => {
      await updateFloor(propertyId, n.id, { Order: n.Order });
    });
    await AddLog(
      `${user.Name} changed Floor Order in Property: ${currentProperty}`
    );
    setLoading(true);
    fetchFloors();
  };

  useEffect(() => {
    fetchFloors();
    fetchProperties();
  }, [propertyId]);

  return (
    <>
      <PageTitle
        title={bn ? "Floors" : `ফ্লর`}
        subTitle={`${!bn ? `সব তলা দেখুন` : `View all floors`}${
          currentProperty ? `: ${currentProperty}` : ``
        }`}
      />
      {rolesStream && user && rolesStream[user.Role][`AddFloors`] && (
        <PrimaryButton
          text={bn ? "Add Floor" : `অ্যাড প্রপার্টি`}
          handleClick={() => navigate(`/properties/${propertyId}/floors/add`)}
        />
      )}
      <br></br>
      {currentProperty && (
        <Dropdown
          items={properties}
          handleChange={handleChangeProperty}
          defaultVal={propertyId}
        />
      )}
      <br />
      <TableHeader
        headings={[
          [bn ? "Floor" : `ফ্লর`, 7],
          [bn ? "Units" : `ইউনিট`, 3],
        ]}
      />

      {loading && <Loading />}

      <DragDropContext
        onDragEnd={(result) => {
          if (
            result.destination &&
            result.destination.droppableId === "cluster"
          ) {
            // call function with index of dropped item
            const droppedIndex = result.source.index;
            const destinationIndex = result.destination.index;
            const droppedId = floors[droppedIndex].id;
            swap(droppedIndex, destinationIndex);
            // changeIndex(droppedIndex, destinationIndex, droppedId);
          }
        }}
      >
        <Droppable droppableId="cluster">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {floors.map((p, index) => (
                <Draggable
                  // isDragDisabled={!rolee.editFloor}
                  draggableId={p.id}
                  index={index}
                >
                  {(provided) => (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <div style={{ display: "flex" }} key={p.id}>
                        <TableItem
                          handleClick={() => {
                            if (
                              rolesStream &&
                              user &&
                              rolesStream[user.Role][`ViewUnits`]
                            ) {
                              navigate(
                                `/properties/${propertyId}/floors/${p.id}/blocks`
                              );
                            }
                          }}
                          rowData={[
                            [p.FloorName, 7],
                            [p.unitCount, 3],
                          ]}
                        />
                        {rolesStream &&
                          user &&
                          rolesStream[user.Role][`EditFloorDetails`] && (
                            <TableEdit
                              handleClick={() =>
                                navigate(
                                  `/properties/${propertyId}/floors/${p.id}`
                                )
                              }
                            />
                          )}
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* {floors.map((p) => {
        return (
          <div style={{ display: "flex" }} key={p.id}>
            <TableItem
              handleClick={() => {
                navigate(`/properties/${propertyId}/floors/${p.id}/units`);
              }}
              rowData={[
                [p.FloorName, 7],
                [p.unitCount, 3],
              ]}
            />
            <TableEdit
              handleClick={() =>
                navigate(`/properties/${propertyId}/floors/${p.id}`)
              }
            />
          </div>
        );
      })} */}
      {!loading && floors.length == 0 && <div>Nothing here</div>}
    </>
  );
}

export default ViewFloors;
