import { useState } from "react";
import classes from "../styles/UnitsContainer.module.css";
import { KeyboardArrowDown } from "@mui/icons-material";

const UnitsContainer = ({ Property, Floor, children }) => {
  const [toggled, setToggled] = useState(true);
  return (
    <>
      {Property && Floor && (
        <div
          className={classes.floorTitle}
          onClick={() => setToggled(!toggled)}
        >
          <div className="hidePrint">
            <KeyboardArrowDown></KeyboardArrowDown>
          </div>

          <div>{`${Property} > ${Floor}`}</div>
        </div>
      )}
      <div className={classes.childUnits}>{toggled && children}</div>
    </>
  );
};

export default UnitsContainer;
