import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import PrimaryButton from "../../components/PrimaryButton";
import RedItem from "../../components/RedItem";
import { getRoles } from "../../firebase";

const ViewRoles = () => {
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();

  const fetchRoles = async () => {
    const roles = await getRoles();
    setRoles(roles);
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <>
      <PageTitle title={"View Roles"} />
      <PrimaryButton
        text={"Add Role"}
        handleClick={() => {
          navigate(`/users/roles/add`);
        }}
      />
      <br></br>
      {roles.map((r) => {
        return (
          <div
            onClick={() => {
              navigate(`/users/roles/${r.id}`);
            }}
          >
            <RedItem text={r.Name} />
          </div>
        );
      })}
    </>
  );
};

export default ViewRoles;
