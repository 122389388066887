import { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import { getUsers } from "../../firebase";
import PrimaryButton from "../../components/PrimaryButton";
import { TableHeader } from "../../components/TableHeader";
import { Loading } from "../../components/Loading";
import { useNavigate } from "react-router-dom";
import { TableItem } from "../../components/TableItem";
import { UserAuth } from "../../AuthContext";
import Table from "../../components/Table";

const ViewUsers = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { rolesStream } = UserAuth();

  function formatFirebaseTimestamp(firebaseTimestamp) {
    if (!firebaseTimestamp) {
      return "";
    }

    if (typeof firebaseTimestamp === "string") {
      return firebaseTimestamp;
    }

    const dateObject = firebaseTimestamp.toDate();
    const formattedDate = dateObject.toLocaleDateString();
    const formattedTime = dateObject.toLocaleTimeString();
    const formattedDateTime = `${formattedDate} ${formattedTime}`;

    return formattedDateTime;
  }

  const fetchUsers = async () => {
    setLoading(true);
    const users = await getUsers();
    setLoading(false);
    setData(users);
    console.log(formatFirebaseTimestamp(users[0].LastLogin));
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <PageTitle title={"View Users"} />
      <PrimaryButton
        text={"Add User"}
        handleClick={() => navigate("/users/add")}
      />
      <br></br>

      <Table>
        <TableHeader
          headings={[
            ["Name", 5],
            ["Status", 5],
            ["Email", 10],
            ["Role", 5],
            ["Last Login", 7],
          ]}
        />
        {loading && <Loading />}
        {rolesStream &&
          data &&
          data.map((d) => {
            return (
              <div style={{ display: "flex" }} key={d.id}>
                <TableItem
                  handleClick={() => {
                    navigate(`/users/${d.id}`);
                  }}
                  rowData={[
                    [d.Name, 5],
                    [d.Status, 5],
                    [
                      `${
                        d.Email.length > 20
                          ? d.Email.substring(0, 20) + "..."
                          : d.Email
                      }`,
                      10,
                    ],
                    [rolesStream[d.Role].Name, 5],
                    [formatFirebaseTimestamp(d.LastLogin), 7],
                  ]}
                />
              </div>
            );
          })}
      </Table>
    </>
  );
};

export default ViewUsers;
