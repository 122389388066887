import { EventNote, LocationCity, LocationOn } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../../components/PageTitle";
import { TextInputWithLabel } from "../../components/TextInputWithLabel";
import { CheckboxInputWithLabel } from "../../components/CheckboxInputWithLabel";
import FieldSection from "../../components/FieldSection";
import FieldContainer from "../../components/FieldContainer";
import {
  AddLog,
  addUnit,
  getExistingUnitNo,
  getFloor,
  getProperty,
} from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { DateInputWithLabel } from "../../components/DateInputWithLabel";
import PrimaryButton from "../../components/PrimaryButton";
import { Loading } from "../../components/Loading";
import SuccessAnimation from "../../components/SuccessAnimation";
import ErrorMsg from "../../components/ErrorMsg";
import { UserAuth } from "../../AuthContext";

function AddUnit() {
  const [data, setData] = useState({ "CommonSpace(ifany)": 0 });
  const [error, setError] = useState([]);
  const { user } = UserAuth();
  const [currentFloor, setCurrentFloor] = useState();
  const [currentProperty, setCurrentProperty] = useState();
  const { propertyId, floorId } = useParams();
  const [rent, setRent] = useState();
  const navigate = useNavigate();
  const [utility, setUtility] = useState();
  const [sc, setSc] = useState();
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [fieldData] = useState([
    [
      {
        fieldTitle: "Location",
        fieldIcon: <LocationOn />,
        fieldItems: [
          {
            label: "Property",
            type: "text",
          },
          {
            label: "Floor",
            type: "text",
          },
          {
            label: "Block",
            placeholder: "Enter Block",
            type: "text",
          },
          {
            label: "Unit No",
            placeholder: "Enter Unit No",
            type: "text",
          },
          {
            label: "Unit Space",
            placeholder: "Enter Unit Space",
            type: "text",
          },
        ],
      },
      {
        fieldTitle: "Entry Info",
        fieldIcon: <EventNote />,
        fieldItems: [
          {
            label: "Rent",
            type: "checkbox",
          },
          {
            label: "Rent Start Date",
            placeholder: "Enter Rent Start Date",
            type: "date",
            activation: "Rent",
          },
          {
            label: "Unit rate per sq",
            placeholder: "Enter Unit rate per sq",
            type: "text",
            activation: "Rent",
          },
          {
            label: "Utility",
            type: "checkbox",
          },
          {
            label: "Utility Start Date",
            placeholder: "Enter Utility Start Date",
            type: "date",
            activation: "Utility",
          },
          {
            label: "Utility Rate",
            placeholder: "Enter Utility Rate",
            type: "text",
            activation: "Utility",
          },
          {
            label: "Previous Usage",
            placeholder: "Enter Previous Usage",
            type: "text",
            activation: "Utility",
          },
          {
            label: "Service Charge",
            type: "checkbox",
          },
          {
            label: "SC Start Date",
            placeholder: "Enter SC Start Date",
            type: "date",
            activation: "SC",
          },
          {
            label: "Common Space (if any)",
            placeholder: "Enter Common Space",
            type: "text",
            activation: "SC",
          },
          {
            label: "Service Charge Rate",
            placeholder: "Enter Service Charge Rate",
            type: "text",
            activation: "SC",
          },
        ],
      },
    ],
  ]);

  function getCurrentMonth() {
    // Get current date
    let date = new Date();

    // Get the month and year from the date
    let month = date.getMonth() + 1; // getMonth() is zero-indexed, so we add 1
    let year = date.getFullYear();

    // Format the month to always have two digits
    if (month < 10) {
      month = "0" + month;
    }

    // Return the formatted date
    return `${year}-${month}`;
  }

  const handleClick = async () => {
    setLoading(true);

    const errs = await checkProperties(data);
    if (!errs.length == 0) {
      console.log(errs);
      setError(errs);
      setLoading(false);
      return;
    }
    setError([]);

    let rsd = "N/A";
    let usd = "N/A";
    let ssd = "N/A";

    if (data[`RentStartDate`] != undefined) {
      rsd = data[`RentStartDate`];
    }

    if (data[`UtilityStartDate`] != undefined) {
      usd = data[`UtilityStartDate`];
    }

    if (data[`SCStartDate`] != undefined) {
      ssd = data[`SCStartDate`];
    }

    const id = await addUnit(propertyId, floorId, {
      "CommonSpace(ifany)": 0,
      rsd: rsd,
      ssd: ssd,
      usd: usd,
      ...data,
      UnitNo: parseInt(data[`UnitNo`]),
      currentTenant: "N/A",
      currentTenantId: "N/A",
      currentTenantType: "Vacant",
      VacantFrom: getCurrentMonth(),
      floorData: {
        x: 0,
        y: 0,
        width: 50,
        height: 50,
      },
      unassigned: true,
    });

    await AddLog(
      `${user.Name} has added Unit${data.UnitNo} in Block: ${data.Block}`
    );
    console.log(id);
    setDone(true);
    setLoading(false);
    setTimeout(() => {
      navigate(
        `/properties/${propertyId}/floors/${floorId}/units/b/${data["Block"]}`
      );
    }, [3000]);
  };

  const handleData = (e, label) => {
    const obj = {};
    obj[label.replace(/\s+/g, "")] = e.target.value;
    setData((prev) => {
      return { ...prev, ...obj };
    });
  };

  const fetchProperty = async () => {
    const prop = await getProperty(propertyId);
    setCurrentProperty(prop.PropertyName);
  };

  const fetchFloor = async () => {
    const floor = await getFloor(propertyId, floorId);
    setCurrentFloor(floor.FloorName);
  };
  async function checkProperties(obj) {
    let errors = [];

    if (!obj.hasOwnProperty("Block")) {
      errors.push("Block is missing.");
    } else if (typeof obj.Block !== "string" || !/^[A-Z]$/.test(obj.Block)) {
      errors.push("Block must be a single capital English letter.");
    }

    if (!obj.hasOwnProperty("UnitNo")) {
      errors.push("UnitNo is missing.");
    } else if (isNaN(Number(obj.UnitNo))) {
      errors.push("UnitNo must be a number");
    }

    if (!obj.hasOwnProperty("UnitSpace")) {
      errors.push("UnitSpace is missing.");
    } else if (isNaN(Number(obj.UnitSpace))) {
      errors.push("UnitSpace must be a number");
    }
    let blockandunit = [];

    if (!isNaN(Number(obj.UnitNo)) && !isNaN(Number(obj.UnitSpace))) {
      blockandunit = await getExistingUnitNo(
        propertyId,
        floorId,
        obj["Block"],
        obj["UnitNo"]
      );
    }

    console.log(blockandunit);

    if (blockandunit.length != 0) {
      errors.push("Same Block with UnitNo exists");
    }

    return errors;
  }

  useEffect(() => {
    console.log(data);
  }, [data]);

  useEffect(() => {
    console.log(currentFloor);
  }, [currentFloor]);

  useEffect(() => {
    fetchProperty();
  }, [propertyId]);

  useEffect(() => {
    fetchFloor();
  }, [floorId]);

  useEffect(() => {
    console.log(currentProperty);
  }, [currentProperty]);

  const handleChange = async (e, label) => {
    // console.log(label);
    if (label == "Rent") {
      setRent(e.target.checked);
      let obj = {};
      obj["Rent"] = e.target.checked;
      setData((prev) => {
        return { ...prev, ...obj };
      });
    } else if (label == "Utility") {
      setUtility(e.target.checked);
      let obj = {};
      obj["Utility"] = e.target.checked;
      setData((prev) => {
        return { ...prev, ...obj };
      });
    } else if (label == "Service Charge") {
      setSc(e.target.checked);
      let obj = {};
      obj["ServiceCharge"] = e.target.checked;
      setData((prev) => {
        return { ...prev, ...obj };
      });
    }
  };

  useEffect(() => {
    console.log(rent);
  }, [rent]);

  return (
    <>
      <PageTitle title="Add Unit" />
      {currentFloor &&
        currentProperty &&
        fieldData.map((f) => {
          return (
            <FieldContainer>
              {f.map((x) => {
                return (
                  <FieldSection>
                    <div
                      className="flex-row color-primary"
                      style={{ marginBottom: "0.5rem" }}
                    >
                      {x.fieldIcon}
                      <label>{x.fieldTitle}</label>
                    </div>
                    {x["fieldItems"].map((y) => {
                      if (
                        !y.activation ||
                        (y.activation == "Rent" && rent) ||
                        (y.activation == "Utility" && utility) ||
                        (y.activation == "SC" && sc)
                      ) {
                        if (y.type == "text") {
                          return (
                            <TextInputWithLabel
                              label={y.label}
                              notEditable={
                                y.placeholder == undefined ? true : false
                              }
                              value={
                                y.label == "Property"
                                  ? currentProperty
                                  : y.label == "Floor"
                                  ? currentFloor
                                  : ""
                              }
                              placeholder={y.placeholder}
                              handleChange={handleData}
                            />
                          );
                        } else if (y.type == "checkbox") {
                          return (
                            <CheckboxInputWithLabel
                              label={y.label}
                              handleChange={handleChange}
                              isEditable={false}
                              placeholder={"bleh"}
                            />
                          );
                        } else if (y.type == "date") {
                          return (
                            <DateInputWithLabel
                              label={y.label}
                              notEditable={
                                y.placeholder == undefined ? true : false
                              }
                              value={
                                y.label == "Property"
                                  ? currentProperty
                                  : y.label == "Floor"
                                  ? currentFloor
                                  : ""
                              }
                              placeholder={y.placeholder}
                              handleChange={handleData}
                            />
                          );
                        }
                      }
                    })}
                  </FieldSection>
                );
              })}
            </FieldContainer>
          );
        })}
      <br></br>
      {loading && <Loading />}
      {error.map((e) => {
        return <ErrorMsg text={e} />;
      })}
      <br></br>
      {!loading && !done && (
        <PrimaryButton text="Assign Unit" handleClick={() => handleClick()} />
      )}
      {done && <SuccessAnimation text={"Unit Added"} />}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
}

export default AddUnit;
