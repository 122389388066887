// import React, { Component } from 'react'
// import QrReader from 'react-qr-scanner'
// export default class QrScanner extends Component {

//   constructor(props){

//     super(props)

//     this.state = {
//       delay: 100,
//       result: 'No result',
//     }

//     this.handleScan = this.handleScan.bind(this)
//   }
//   handleScan(data){
//     if(data){
//       window.location=data.text;
//     }
//     // console.log(data);

//     // this.setState({
//     //   result: data,
//     // })
//   }
//   handleError(err){
//     console.error(err)
//   }
//   render(){
//     const previewStyle = {
//       height: 240,
//       width: 320,
//     }

//     return(
//       <div>
//         <QrReader
//           delay={this.state.delay}
//           style={previewStyle}
//           onError={this.handleError}
//           onScan={this.handleScan}
//           key="environment"
//           // constraints={ {
//           //   facingMode: 'environment',

//           // } }
//           />
//         {/* <p>{this.state.result}</p> */}
//       </div>
//     )
//   }
// }

import React, { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import classes from "../styles/QRScanner.module.css";

export default function QRScanner() {
  const [data, setData] = useState("No result");

  useEffect(() => {
    if (data != "No result") {
      window.location = data;
    }

    console.log(data);
  }, [data]);

  return (
    <>
      <div className={classes.QRcont}>
        <p>{"QR Scanner"}</p>
        <QrReader
          onResult={(result, error) => {
            if (!!result) {
              setData(result?.text);
            }

            if (!!error) {
              console.info(error);
            }
          }}
          constraints={{ facingMode: "environment" }}
        />
      </div>
    </>
  );
}
