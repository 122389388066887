// import { useEffect, useState } from "react";
// import PageTitle from "../../components/PageTitle";
// import UnitSelector from "../../components/UnitSelector";
// import TextLabel from "../../components/TextLabel";
// import {
//   AddEntry,
//   GetEntryTenantMonth,
//   GetImage,
//   GetLatestEntryOfUnit,
//   getFloorName,
//   getPropertyName,
//   getUnit,
//   updateUnit,
// } from "../../firebase";
// import { DateInputWithLabel } from "../../components/DateInputWithLabel";
// import PrimaryButton from "../../components/PrimaryButton";
// import { UserAuth } from "../../AuthContext";
// import { TextInputWithLabel } from "../../components/TextInputWithLabel";
// import SuccessAnimation from "../../components/SuccessAnimation";

// const UtilityEntry = () => {
//   const [currentFloor, setCurrentFloor] = useState();
//   const [currentProperty, setCurrentProperty] = useState();
//   const [currentFloorName, setCurrentFloorName] = useState();
//   const [currentPropertyName, setCurrentPropertyName] = useState();
//   const [meterUsage, setMeterUsage] = useState(0);
//   const [alreadyPaid, setAlreadyPaid] = useState(false);
//   const [prevUsage, setPrevUsage] = useState(0);
//   const [currentUnit, setCurrentUnit] = useState();
//   const [data, setData] = useState({ Month: "2023-01" });
//   const { user } = UserAuth();
//   const [image, setImage] = useState();
//   const [fieldData] = useState([
//     {
//       label: "currentTenant",
//       origin: "unit",
//       forceName: "Tenant Name",
//     },
//     {
//       label: "Unit No",
//       origin: "unit",
//     },
//     {
//       label: "Property Name",
//       origin: "property",
//     },
//     {
//       label: "Floor Name",
//       origin: "floor",
//     },
//     {
//       label: "Block",
//       origin: "unit",
//     },
//     {
//       label: "Due",
//       origin: "this",
//     },
//     {
//       label: "Previous Meter Usage",
//       origin: "meterReading",
//     },
//   ]);

//   const [unitData, setUnitData] = useState();
//   const [overPay, setOverPay] = useState(false);
//   const [partiallyPaid, setPartiallyPaid] = useState();
//   const [entryData, setEntryData] = useState();

//   const fetchUnitData = async () => {
//     setAlreadyPaid(false);
//     const unit = await getUnit(currentProperty, currentFloor, currentUnit);
//     setUnitData(unit);

//     const entries = await GetEntryTenantMonth(
//       unit["currentTenantId"],
//       data["Month"]
//     );
//     entries.forEach((e, index) => {
//       if (e.entryType != "Utility") {
//         delete entries[index];
//       }
//     });
//     let latestMeterReading = 0;

//     const latest = await GetLatestEntryOfUnit(
//       currentProperty,
//       currentFloor,
//       currentUnit,
//       data["Month"],
//       `Utility`
//     );

//     if (latest.length != 0 && latest[0].paymentType == "Partial") {
//       latestMeterReading = latest[0].MeterReading - unit["PreviousUsage"];
//       setPrevUsage(latest[0].MeterReading);
//       console.log(unit["PreviousUsage"]);
//     } else if (latest.length != 0 && latest[0].paymentType == "Full") {
//       latestMeterReading = latest[0].oldMeterUsage - unit["PreviousUsage"];
//       setPrevUsage(latest[0].MeterReading);
//     } else {
//       latestMeterReading = meterUsage - unit["PreviousUsage"];
//     }

//     const rent = parseInt(unit["UtilityRate"]) * latestMeterReading;

//     console.log(latestMeterReading);

//     let previousPayment = 0;
//     if (entries.length != 0) {
//       entries.map((e) => {
//         previousPayment += e.AmountPaid;
//       });
//     }
//     let due;
//     if (latest.length > 0 && latest[0].paymentType == "Full") {
//       setAlreadyPaid(true);
//       due = 0;
//     } else {
//       due = rent - previousPayment;
//     }

//     setEntryData({
//       totalRent: rent,
//       due: due,
//       mr: unit["PreviousUsage"],
//     });
//   };

//   useEffect(() => {
//     console.log(prevUsage);
//   }, [prevUsage]);

//   const fetchImage = async () => {
//     const x = await GetImage(`Tenants/${unitData["currentTenantId"]}`);
//     setImage(x);
//   };

//   const handleData = (e, label) => {
//     const obj = {};
//     obj[label.replace(/\s+/g, "")] = e.target.value;
//     setData((prev) => {
//       return { ...prev, ...obj };
//     });
//   };

//   const handleMeterUsage = (e, label) => {
//     setMeterUsage(e.target.value);
//   };

//   const handleAmount = (e, label) => {
//     const obj = {};
//     obj[label.replace(/\s+/g, "")] = parseInt(e.target.value);
//     setData((prev) => {
//       return { ...prev, ...obj };
//     });
//   };

//   function getNextMonth(dateString) {
//     const date = new Date(dateString + "-01");
//     date.setMonth(date.getMonth() + 1);

//     const year = date.getFullYear();
//     const month = date.getMonth() + 1;
//     const monthString = month < 10 ? "0" + month : month;

//     return `${year}-${monthString}`;
//   }

//   function formatMonthYear(dateString) {
//     const date = new Date(dateString + "-01");
//     const monthNames = [
//       "January",
//       "February",
//       "March",
//       "April",
//       "May",
//       "June",
//       "July",
//       "August",
//       "September",
//       "October",
//       "November",
//       "December",
//     ];

//     const year = date.getFullYear();
//     const month = monthNames[date.getMonth()];

//     return `${month}, ${year}`;
//   }

//   const handleClick = async () => {
//     setPrevUsage(0);
//     setMeterUsage(0);
//     setOverPay(false);
//     let paymentType;
//     if (data["AmountPaid"] == entryData["due"]) {
//       paymentType = "Full";
//     } else if (data["AmountPaid"] < entryData["due"]) {
//       paymentType = "Partial";
//     }
//     if (!(data["AmountPaid"] > entryData["due"])) {
//       const entryId = await AddEntry({
//         ...data,
//         TenantId: unitData["currentTenantId"],
//         UnitId: unitData["id"],
//         PropertyId: currentProperty,
//         FloorId: currentFloor,
//         TenantName: unitData["currentTenant"],
//         CollectedBy: user.Username,
//         paymentType: paymentType,
//         entryType: "Utility",
//         remainingAmount: entryData["due"] - data["AmountPaid"],
//         totalAmount: entryData["totalRent"],
//         UnitNo: unitData["UnitNo"],
//         BusinessName: unitData["currentBusinessName"],
//         MeterReading: prevUsage != 0 ? prevUsage : meterUsage,
//         oldMeterUsage: unitData["PreviousUsage"],
//       });
//       sendMessageToTelegram(
//         `_*${user.Username}*_ collected *Service Charge*💰 of *${
//           unitData["currentBusinessName"]
//         }*\nProperty: *${currentPropertyName}*\nFloor: *${currentFloorName}*\nBlock: *${
//           unitData["Block"]
//         }*\nAmount Collected: *${data["AmountPaid"]}* \nRemaining Amount: *${
//           entryData["due"] - data["AmountPaid"]
//         }*\nMonth: *${formatMonthYear(
//           data["Month"]
//         )}*\nPayment Type: *${paymentType}*`
//       );
//       if (paymentType == "Full") {
//         await updateUnit(currentProperty, currentFloor, unitData["id"], {
//           UtilityStartDate: getNextMonth(data["Month"]),
//           PreviousUsage: prevUsage != 0 ? prevUsage : meterUsage,
//         });
//       }
//       console.log(entryId);
//     } else {
//       setOverPay(true);
//     }
//     fetchUnitData();
//   };

//   useEffect(() => {
//     fetchUnitData();
//   }, [currentUnit, data.Month, meterUsage]);

//   useEffect(() => {
//     fetchUnitData();
//     console.log(prevUsage);
//   }, [prevUsage]);

//   const fetchPropertyName = async () => {
//     const propertyName = await getPropertyName(currentProperty);
//     setCurrentPropertyName(propertyName);
//   };

//   const fetchFloorName = async () => {
//     const floorName = await getFloorName(currentProperty, currentFloor);
//     setCurrentFloorName(floorName);
//   };

//   useEffect(() => {
//     fetchPropertyName();
//   }, [currentProperty]);

//   useEffect(() => {
//     fetchFloorName();
//   }, [currentFloor]);

//   useEffect(() => {
//     fetchImage();
//   }, [unitData]);

//   async function sendMessageToTelegram(text) {
//     const url = `https://api.telegram.org/bot${botToken}/sendMessage`;

//     const payload = {
//       chat_id: "1930867829",
//       text: text,
//       parse_mode: "MarkdownV2",
//     };

//     try {
//       const response = await fetch(url, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(payload),
//       });

//       if (!response.ok) {
//         const errorDetails = await response.text();
//         throw new Error(
//           `Error sending message to Telegram: ${response.statusText}. Details: ${errorDetails}`
//         );
//       }

//       const result = await response.json();
//       console.log("Message sent successfully:", result);
//     } catch (error) {
//       console.error("Error sending message to Telegram:", error);
//     }
//   }

//   const botToken = "6042041220:AAGpZ1kHQp1JMX83dAyASi3ozcw3UrJ0ppk";

//   return (
//     <>
//       <PageTitle title="Utility Entry" image={image} />
//       <UnitSelector
//         setCurrentProperty={setCurrentProperty}
//         setCurrentFloor={setCurrentFloor}
//         setCurrentUnit={setCurrentUnit}
//       />
//       {currentUnit &&
//         unitData &&
//         entryData &&
//         fieldData.map((f) => {
//           return (
//             <TextLabel
//               label={f.forceName || f.label}
//               value={
//                 f.origin == "unit"
//                   ? `${unitData[f.label.replace(/\s+/g, "")]}`
//                   : f.origin == "property"
//                   ? `${currentPropertyName}`
//                   : f.origin == "floor"
//                   ? `${currentFloorName}`
//                   : f.origin == "this"
//                   ? `${entryData["due"]}`
//                   : f.origin == "meterReading"
//                   ? `${entryData["mr"]}`
//                   : ""
//               }
//             />
//           );
//         })}
//       <br></br>
//       {
//         <div>
//           {currentUnit && (
//             <>
//               <DateInputWithLabel
//                 label="Month"
//                 notEditable={false}
//                 placeholder={"bel"}
//                 handleChange={handleData}
//               />
//               {entryData &&
//                 unitData &&
//                 unitData["UtilityStartDate"] == data["Month"] &&
//                 entryData["due"] != 0 &&
//                 entryData["totalRent"] == entryData["due"] && (
//                   <TextInputWithLabel
//                     label="Meter Usage"
//                     notEditable={false}
//                     placeholder={"Enter Usage"}
//                     handleChange={handleMeterUsage}
//                   />
//                 )}
//               {(meterUsage != 0 || prevUsage != 0) &&
//                 alreadyPaid == false &&
//                 unitData &&
//                 unitData["UtilityStartDate"] == data["Month"] && (
//                   <TextInputWithLabel
//                     label="Amount Paid"
//                     notEditable={false}
//                     placeholder={"Enter Amount"}
//                     handleChange={handleAmount}
//                   />
//                 )}

//               <br></br>
//               {overPay && <div>Amount Paid cannot be greater than Due</div>}
//               {entryData && entryData["due"] == 0 && (
//                 <SuccessAnimation text={"Already Paid"} green={true} />
//               )}
//               {entryData &&
//                 entryData["due"] != 0 &&
//                 entryData["totalRent"] != entryData["due"] && (
//                   <SuccessAnimation text={"Partially Paid"} darkYellow={true} />
//                 )}
//               {entryData &&
//                 unitData &&
//                 unitData["UtilityStartDate"] == data["Month"] &&
//                 entryData["due"] != 0 &&
//                 entryData["totalRent"] == entryData["due"] && (
//                   <SuccessAnimation
//                     text={"Not Paid"}
//                     red={true}
//                     noIcon={true}
//                   />
//                 )}
//               {unitData && unitData["UtilityStartDate"] != data["Month"] && (
//                 <div style={{ color: "darkRed", fontWeight: "bold" }}>
//                   <br></br>
//                   {`Your Next Payment Month is: ${formatMonthYear(
//                     unitData["UtilityStartDate"]
//                   )}`}
//                 </div>
//               )}
//               <br></br>
//               {entryData &&
//                 entryData["due"] != 0 &&
//                 unitData &&
//                 unitData["UtilityStartDate"] == data["Month"] && (
//                   <PrimaryButton text="Add Entry" handleClick={handleClick} />
//                 )}
//             </>
//           )}
//         </div>
//       }
//     </>
//   );
// };

// export default UtilityEntry;

import { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import UnitSelector from "../../components/UnitSelector";
import TextLabel from "../../components/TextLabel";
import {
  AddEntry,
  GetEntryTenantMonth,
  GetImage,
  getFloorName,
  getPropertyName,
  getUnit,
  updateUnit,
} from "../../firebase";
import { DateInputWithLabel } from "../../components/DateInputWithLabel";
import PrimaryButton from "../../components/PrimaryButton";
import { UserAuth } from "../../AuthContext";
import { TextInputWithLabel } from "../../components/TextInputWithLabel";
import SuccessAnimation from "../../components/SuccessAnimation";
import EntriesPreview from "../../components/EntriesPreview";
import { useLocation, useNavigate } from "react-router-dom";
import { Loading } from "../../components/Loading";

const UtilityEntry = () => {
  const [currentFloor, setCurrentFloor] = useState();
  const [currentProperty, setCurrentProperty] = useState();
  const [currentFloorName, setCurrentFloorName] = useState();
  const [currentPropertyName, setCurrentPropertyName] = useState();
  const [currentUnit, setCurrentUnit] = useState();
  const [meterReading, setMeterReading] = useState(1);
  const [data, setData] = useState({ Month: "2023-01" });
  const location = useLocation();
  const { user, rolesStream } = UserAuth();
  const [image, setImage] = useState();
  const [fieldData] = useState([
    {
      label: "currentTenant",
      label_bn: "বর্তমান ভাড়াটিয়া",
      origin: "unit",
      forceName: "Tenant Name",
    },
    {
      label: "Unit No",
      label_bn: "ইউনিট নং",
      origin: "unit",
    },
    {
      label: "Property Name",
      label_bn: "সম্পত্তির নাম",
      origin: "property",
    },
    {
      label: "Floor Name",
      label_bn: "তলার নাম",
      origin: "floor",
    },
    {
      label: "Block",
      label_bn: "ব্লক",
      origin: "unit",
    },
    {
      label: "Due",
      label_bn: "বাকি",
      origin: "this",
    },
    {
      label: "Current Meter Reading",
      label_bn: "বর্তমান মিটার পড়া",
      origin: "mr",
    },
  ]);

  const [unitData, setUnitData] = useState();
  const [overPay, setOverPay] = useState(false);
  const [partiallyPaid, setPartiallyPaid] = useState();
  const navigate = useNavigate();
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [entryData, setEntryData] = useState();

  const fetchUnitData = async () => {
    let mr = 0;
    const unit = await getUnit(currentProperty, currentFloor, currentUnit);
    setUnitData(unit);

    let entries = await GetEntryTenantMonth(
      unit["currentTenantId"],
      data["Month"]
    );
    entries.forEach((e, index) => {
      if (e.entryType != "Utility") {
        delete entries[index];
      }
    });
    var filtered = entries.filter(function (el) {
      return el != null;
    });
    entries = filtered;

    let previousPayment = 0;
    let AlreadyPaid = false;
    if (entries.length != 0) {
      entries.map((e) => {
        if (e.paymentType == "Full") {
          AlreadyPaid = true;
        }
        previousPayment += e.AmountPaid;
        mr = e.newMeterUsage;
      });
    } else {
      mr = meterReading;
    }
    let rent =
      parseInt(unit["UtilityRate"]) *
      (parseInt(mr) - parseInt(unit["PreviousUsage"]));
    console.log(entries);
    if (AlreadyPaid) {
      rent =
        parseInt(unit["UtilityRate"]) *
        (parseInt(entries[0]["newMeterUsage"]) -
          parseInt(entries[0]["oldMeterUsage"]));
    }
    const due = rent - previousPayment;
    console.log(parseInt(unit["PreviousUsage"]));
    setEntryData({
      totalRent: rent,
      due: due,
      mr: mr,
    });
  };

  const fetchImage = async () => {
    const x = await GetImage(`Tenants/${unitData["currentTenantId"]}`);
    setImage(x);
  };

  const handleData = (e, label) => {
    const obj = {};
    obj[label.replace(/\s+/g, "")] = e.target.value;
    setData((prev) => {
      return { ...prev, ...obj };
    });
  };

  const handleAmount = (e, label) => {
    const obj = {};
    obj[label.replace(/\s+/g, "")] = parseInt(e.target.value);
    setData((prev) => {
      return { ...prev, ...obj };
    });
  };

  const handleMeterReading = (e, label) => {
    setMeterReading(e.target.value);
  };

  function getNextMonth(dateString) {
    const date = new Date(dateString + "-01");
    date.setMonth(date.getMonth() + 1);

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const monthString = month < 10 ? "0" + month : month;

    return `${year}-${monthString}`;
  }

  function formatMonthYear(dateString) {
    const date = new Date(dateString + "-01");
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const year = date.getFullYear();
    const month = monthNames[date.getMonth()];

    return `${month}, ${year}`;
  }

  const handleClick = async () => {
    setLoading(true);
    setOverPay(false);
    let paymentType;
    if (data["AmountPaid"] == entryData["due"]) {
      paymentType = "Full";
    } else if (data["AmountPaid"] < entryData["due"]) {
      paymentType = "Partial";
    }

    if (!(data["AmountPaid"] > entryData["due"])) {
      const entryId = await AddEntry({
        ...data,
        TenantId: unitData["currentTenantId"],
        UnitId: unitData["id"],
        PropertyId: currentProperty,
        Property: currentPropertyName,
        Floor: currentFloorName,
        Block: unitData[`Block`],
        FloorId: currentFloor,
        TenantName: unitData["currentTenant"],
        CollectedBy: user.Name,
        paymentType: paymentType,
        entryType: "Utility",
        oldMeterUsage: unitData["PreviousUsage"],
        newMeterUsage: entryData["mr"],
        remainingAmount: entryData["due"] - data["AmountPaid"],
        totalAmount: entryData["totalRent"],
        UnitNo: unitData["UnitNo"],
        BusinessName: unitData["currentBusinessName"],
      });
      sendMessageToTelegram(
        `_*${user.Name}*_ collected *Utility Bill*💰 of *${
          unitData["currentBusinessName"]
        }*\nProperty: *${currentPropertyName}*\nFloor: *${currentFloorName}*\nBlock: *${
          unitData["Block"]
        }*\nAmount Collected: *${data["AmountPaid"]}* \nRemaining Amount: *${
          entryData["due"] - data["AmountPaid"]
        }*\nMonth: *${formatMonthYear(
          data["Month"]
        )}*\nPayment Type: *${paymentType}*`
      );
      if (paymentType == "Full") {
        await updateUnit(currentProperty, currentFloor, unitData["id"], {
          UtilityStartDate: getNextMonth(data["Month"]),
          PreviousUsage: entryData["mr"],
        });
      }
      console.log(entryId);
      setTimeout(() => {
        navigate(`/entries/${entryId}`);
      }, [3000]);
    } else {
      setOverPay(true);
    }
    fetchUnitData();
    setLoading(false);
    setDone(true);
  };

  useEffect(() => {
    fetchUnitData();
  }, [currentUnit, data.Month, meterReading]);

  const fetchPropertyName = async () => {
    const propertyName = await getPropertyName(currentProperty);
    setCurrentPropertyName(propertyName);
  };

  const fetchFloorName = async () => {
    const floorName = await getFloorName(currentProperty, currentFloor);
    setCurrentFloorName(floorName);
  };

  useEffect(() => {
    fetchPropertyName();
  }, [currentProperty]);

  useEffect(() => {
    fetchFloorName();
  }, [currentFloor]);

  useEffect(() => {
    fetchImage();
  }, [unitData]);

  async function sendMessageToTelegram(text) {
    const url = `https://api.telegram.org/bot${botToken}/sendMessage`;

    const payload = {
      chat_id: "1999590644",
      text: text,
      parse_mode: "MarkdownV2",
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorDetails = await response.text();
        throw new Error(
          `Error sending message to Telegram: ${response.statusText}. Details: ${errorDetails}`
        );
      }

      const result = await response.json();
      console.log("Message sent successfully:", result);
    } catch (error) {
      console.error("Error sending message to Telegram:", error);
    }
  }

  useEffect(() => {
    if (rolesStream && user) {
      if (rolesStream[user.Role][`UtilityCollection`] != true) {
        navigate(`/home`);
      }
    }
  }, [rolesStream, user, location]);

  const botToken = "6042041220:AAGpZ1kHQp1JMX83dAyASi3ozcw3UrJ0ppk";

  return (
    <>
      <PageTitle title="Utility Entry" image={image} />
      <UnitSelector
        setCurrentProperty={setCurrentProperty}
        setCurrentFloor={setCurrentFloor}
        setCurrentUnit={setCurrentUnit}
        onlyPrimary={true}
        onlyPosition={true}
      />
      {currentUnit && (
        <EntriesPreview
          type="Utility"
          property={currentProperty}
          floor={currentFloor}
          unit={currentUnit}
        />
      )}
      {currentUnit &&
        unitData &&
        entryData &&
        fieldData.map((f) => {
          return (
            <>
              {(f.label != "Due" || rolesStream[user.Role][`ViewDue`]) && (
                <TextLabel
                  label={f.forceName || f.label}
                  label_bn={f.label_bn}
                  value={
                    f.origin == "unit"
                      ? `${unitData[f.label.replace(/\s+/g, "")]}`
                      : f.origin == "property"
                      ? `${currentPropertyName}`
                      : f.origin == "floor"
                      ? `${currentFloorName}`
                      : ""
                      ? f.origin == "this"
                      : `${entryData["due"]}`
                  }
                />
              )}
            </>
          );
        })}
      <br></br>
      {
        <div>
          {currentUnit && (
            <>
              <DateInputWithLabel
                label="Month"
                notEditable={false}
                placeholder={"bel"}
                handleChange={handleData}
              />
              {entryData &&
                entryData["due"] != 0 &&
                unitData &&
                unitData["UtilityStartDate"] == data["Month"] &&
                entryData["mr"] != undefined &&
                entryData["mr"] != 0 &&
                !(
                  entryData["due"] != 0 &&
                  entryData["totalRent"] != entryData["due"]
                ) && (
                  <TextInputWithLabel
                    label="Meter Reading"
                    notEditable={false}
                    placeholder={"Enter Meter Reading"}
                    handleChange={handleMeterReading}
                  />
                )}
              {entryData &&
                entryData["due"] != 0 &&
                unitData &&
                unitData["UtilityStartDate"] == data["Month"] && (
                  <TextInputWithLabel
                    label="Amount Paid"
                    notEditable={false}
                    placeholder={"Enter Amount"}
                    handleChange={handleAmount}
                  />
                )}
              <br></br>
              {overPay && <div>Amount Paid cannot be greater than Due</div>}
              {entryData && entryData["due"] == 0 && (
                <SuccessAnimation text={"Already Paid"} green={true} />
              )}
              {entryData &&
                entryData["due"] != 0 &&
                entryData["totalRent"] != entryData["due"] && (
                  <SuccessAnimation text={"Partially Paid"} darkYellow={true} />
                )}
              {entryData &&
                unitData &&
                unitData["UtilityStartDate"] == data["Month"] &&
                entryData["due"] != 0 &&
                entryData["totalRent"] == entryData["due"] && (
                  <SuccessAnimation
                    text={"Not Paid"}
                    red={true}
                    noIcon={true}
                  />
                )}
              {unitData && unitData["UtilityStartDate"] != data["Month"] && (
                <div style={{ color: "darkRed", fontWeight: "bold" }}>
                  <br></br>
                  {`Your Next Payment Month is: ${formatMonthYear(
                    unitData["UtilityStartDate"]
                  )}`}
                </div>
              )}
              <br></br>
              {entryData &&
                ((entryData["due"] != 0 &&
                  unitData &&
                  unitData["UtilityStartDate"] == data["Month"]) ||
                  !(
                    entryData["mr"] != undefined &&
                    (meterReading != 0 || entryData["mr"] != 0)
                  )) &&
                !loading &&
                !done && (
                  <PrimaryButton text="Add Entry" handleClick={handleClick} />
                )}
            </>
          )}
          {loading && <Loading />}
        </div>
      }
    </>
  );
};

export default UtilityEntry;
