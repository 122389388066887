import classes from "../styles/SideButtons.module.css";
import {
  Close,
  Map,
  MenuSharp,
  Notifications,
  QrCodeScanner,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../AuthContext";

const SideButtons = ({
  navToggled,
  setNavToggled,
  notifToggled,
  setNotifToggled,
}) => {
  const navigate = useNavigate();
  const handleToggle = () => {
    setNavToggled(!navToggled);
  };
  const { user, rolesStream } = UserAuth();

  return (
    <>
      <div className={classes.buttonsContainer}>
        {rolesStream && user && rolesStream[user.Role][`FloorViewAccess`] && (
          <div
            onClick={() => {
              navigate("/floorView");
            }}
          >
            <Map />
          </div>
        )}
        <div
          onClick={() => {
            navigate("/qr");
          }}
        >
          <QrCodeScanner />
        </div>
        <div onClick={() => setNotifToggled(!notifToggled)}>
          <Notifications />
        </div>
        <div className={classes.hamBurger} onClick={handleToggle}>
          {!navToggled ? <MenuSharp /> : <Close />}
        </div>
      </div>
    </>
  );
};

export default SideButtons;
