import { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import { useNavigate } from "react-router-dom";
import {
  GetLatestEntryOfUnit,
  getFloor,
  getFloors,
  getProperties,
  getProperty,
  getUnits,
} from "../../firebase";
import UnitsContainer from "../../components/UnitsContainer";
import { Loading } from "../../components/Loading";
import { TableHeader } from "../../components/TableHeader";
import Table from "../../components/Table";
import Dropdown from "../../components/Dropdown";
import { TableItem } from "../../components/TableItem";
import { DateInputWithLabel } from "../../components/DateInputWithLabel";
import TextInputWithDropdown from "../../components/TextInputWithDropdown";
import UnitTitle from "../../components/UnitTitle";
import StatusItem from "../../components/StatusItem";

const UtilityCollected = () => {
  const navigate = useNavigate();
  const [propertyId, setPropertyId] = useState("allProperties");
  const [floorId, setFloorId] = useState("allFloors");
  const [units, setUnits] = useState([]);
  const [floors, setFloors] = useState([]);
  const [properties, setProperties] = useState([]);
  const [currentProperty, setCurrentProperty] = useState();
  const [currentFloor, setCurrentFloor] = useState();
  const [month, setMonth] = useState();
  const [loading, setLoading] = useState(true);
  const [searchData, setSearchData] = useState("");
  const [searchState, setSearchState] = useState("Block");

  function getCurrentDateAndMonth() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const monthString = month < 10 ? "0" + month : month;

    return `${year}-${monthString}`;
  }

  const fetchProperties = async () => {
    const docRef = await getProperties();
    const propertyData = [];
    docRef.forEach((d) => {
      propertyData.push({ text: d.PropertyName, value: d.id });
    });
    propertyData.push({ text: "All Properties", value: "allProperties" });
    setProperties(propertyData);
  };

  const fetchFloors = async (propertyId) => {
    const docRef = await getFloors(propertyId);
    console.log(docRef);
    const floorData = [];
    docRef.forEach((d) => {
      floorData.push({ text: d.FloorName, value: d.id });
    });
    floorData.push({ text: "All Floors", value: "allFloors" });
    setFloors(floorData);
  };

  const fetchSpecificFloorUnits = async (propertyId, floorId) => {
    const [prop, floor, unitsArr] = await Promise.all([
      getProperty(propertyId),
      getFloor(propertyId, floorId),
      getUnits(propertyId, floorId),
    ]);
    await Promise.all(
      unitsArr.map(async (u) => {
        const latest = await GetLatestEntryOfUnit(
          propertyId,
          floorId,
          u.id,
          month ? month : getCurrentDateAndMonth(),
          "Utility"
        );
        if (latest[0]) {
          u["Status"] = latest[0]["paymentType"];
        }
      })
    );
    const propertyArr = [
      {
        PropertyName: prop.PropertyName,
        id: prop.id,
        Floors: [
          {
            FloorName: floor.FloorName,
            id: floor.id,
            Units: unitsArr,
          },
        ],
      },
    ];

    setUnits(propertyArr);
    setLoading(false);
  };

  const fetchSpecificPropertyUnits = async (propertyId) => {
    const [prop, floors] = await Promise.all([
      getProperty(propertyId),
      getFloors(propertyId),
    ]);

    const floorArrPromises = floors.map(async (f) => {
      const unitsArr = await getUnits(propertyId, f.id);

      // Use Promise.all() with map() to wait for all promises to resolve
      await Promise.all(
        unitsArr.map(async (u) => {
          const latest = await GetLatestEntryOfUnit(
            propertyId,
            f.id,
            u.id,
            month ? month : getCurrentDateAndMonth(),
            "Utility"
          );
          if (latest[0]) {
            u["Status"] = latest[0]["paymentType"];
          }
        })
      );

      console.log(unitsArr);
      return {
        FloorName: f.FloorName,
        id: f.id,
        Units: unitsArr,
      };
    });

    const floorsArr = await Promise.all(floorArrPromises);

    const propertyArr = [
      {
        PropertyName: prop.PropertyName,
        id: prop.id,
        Floors: floorsArr,
      },
    ];

    setUnits(propertyArr);
    setLoading(false);
  };

  const fetchAllUnits = async () => {
    const props = await getProperties();
    const propertyArrPromises = props.map(async (p) => {
      const floors = await getFloors(p.id);
      const floorArrPromises = floors.map(async (f) => {
        const unitsArr = await getUnits(p.id, f.id);
        await Promise.all(
          unitsArr.map(async (u) => {
            const latest = await GetLatestEntryOfUnit(
              p.id,
              f.id,
              u.id,
              month ? month : getCurrentDateAndMonth(),
              "Utility"
            );
            if (latest[0]) {
              u["Status"] = latest[0]["paymentType"];
            }
          })
        );
        return {
          FloorName: f.FloorName,
          id: f.id,
          Units: unitsArr,
        };
      });
      const floorsArr = await Promise.all(floorArrPromises);
      return {
        PropertyName: p.PropertyName,
        id: p.id,
        Floors: floorsArr,
      };
    });

    const propertyArr = await Promise.all(propertyArrPromises);
    setUnits(propertyArr);
    setLoading(false);
  };

  function getPreviousMonth(dateString) {
    const date = new Date(dateString + "-01");
    date.setMonth(date.getMonth() - 1);

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const monthString = month < 10 ? "0" + month : month;

    return `${year}-${monthString}`;
  }

  const handleChangeProperty = async (id) => {
    setLoading(true);
    setPropertyId(id);
  };

  const handleChangeFloor = async (id) => {
    setLoading(true);
    setFloorId(id);
  };

  const handleChange = (e, label) => {
    setLoading(true);
    setMonth(e.target.value);
  };

  const handleSearchChange = (e, label) => {
    setSearchData(e.target.value);
  };

  useEffect(() => {
    if (propertyId == "allProperties" && floorId == "allFloors") {
      fetchAllUnits();
    } else if (floorId == "allFloors" && propertyId != "allProperties") {
      console.log("All floors of specific property");
      fetchSpecificPropertyUnits(propertyId);
    } else if (propertyId != "allProperties" && floorId != "allFloors") {
      fetchSpecificFloorUnits(propertyId, floorId);
    }

    setCurrentProperty(propertyId);
    setCurrentFloor(floorId);
    fetchProperties();
    fetchFloors(propertyId);
  }, [propertyId, floorId, month]);

  useEffect(() => {
    console.log(searchState);
  }, [searchState]);

  useEffect(() => {
    console.log(searchData);
  }, [searchData]);

  useEffect(() => {
    console.log(units);
  }, [units]);

  useEffect(() => {
    setFloorId("allFloors");
  }, [propertyId]);

  function findTextById(arr, id) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].value === id) {
        return arr[i].text;
      }
    }
    return null;
  }

  function convertDateFormat(dateStr) {
    // Create a date object from the string
    let date = new Date(dateStr);

    // Define the month names
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract the month and year from the date
    let month = monthNames[date.getUTCMonth()];
    let year = date.getUTCFullYear();

    // Return the formatted date
    return `${month}, ${year}`;
  }

  return (
    <>
      <PageTitle
        title="Utility Collected"
        subTitle={`of ${
          currentProperty == "allProperties"
            ? "All Properties"
            : findTextById(properties, currentProperty)
        }, ${
          currentFloor == "allFloors"
            ? "All Floors"
            : findTextById(floors, currentFloor)
        } of Month: ${convertDateFormat(month)}`}
      />

      <div className="flex-wrap">
        {currentProperty && properties.length > 0 && (
          <Dropdown
            items={properties}
            label="Property"
            handleChange={handleChangeProperty}
            defaultVal={propertyId}
          />
        )}

        {currentFloor && floors.length > 0 && (
          <Dropdown
            items={floors}
            label="Floor"
            value={floorId}
            handleChange={handleChangeFloor}
            defaultVal={floorId}
          />
        )}

        <DateInputWithLabel
          small={true}
          label="Month"
          placeholder={"Month"}
          handleChange={handleChange}
        />
      </div>
      <TextInputWithDropdown
        label="Search"
        placeholder="Search"
        name={true}
        setSearchState={setSearchState}
        handleChange={handleSearchChange}
      />
      <br></br>
      {units && (
        <Table>
          <TableHeader
            headings={[
              ["Unit Info", 7],
              ["Rent Status", 6],
              ["Paid Till", 6],
            ]}
          />
          {loading && <Loading />}

          {!loading &&
            units.map((p) => {
              if (p.Floors) {
                return p.Floors.map((f) => {
                  if (f.Units.length > 0) {
                    return (
                      <>
                        <UnitsContainer
                          Property={p.PropertyName}
                          Floor={f.FloorName}
                        >
                          {f.Units.map((u) => {
                            if (
                              (searchData != "" &&
                                searchState == "Block" &&
                                u.Block?.toUpperCase() ==
                                  searchData?.toUpperCase()) ||
                              (searchData != "" &&
                                searchState == "UnitNo" &&
                                u.UnitNo.toString().startsWith(
                                  searchData.toString()
                                )) ||
                              (searchData != "" &&
                                searchState == "UnitName" &&
                                u.currentBusinessName
                                  .toString()
                                  .toLowerCase()
                                  .startsWith(
                                    searchData.toString().toLowerCase()
                                  )) ||
                              searchData == ""
                            ) {
                              return (
                                <>
                                  {u.currentTenantId != "N/A" && (
                                    <TableItem
                                      handleClick={() => {
                                        navigate(
                                          `/properties/${p.id}/floors/${f.id}/units/${u.id}`
                                        );
                                      }}
                                      rowData={[
                                        [
                                          <div>
                                            <UnitTitle
                                              UnitNo={u.UnitNo}
                                              UnitName={u.currentBusinessName}
                                              Block={u.Block}
                                            />
                                          </div>,
                                          7,
                                        ],
                                        [
                                          <>
                                            {month >= u.usd ? (
                                              <div>
                                                {u.Status ? (
                                                  <StatusItem
                                                    status={u.Status}
                                                  />
                                                ) : (
                                                  <StatusItem
                                                    status={"Not Paid"}
                                                  />
                                                )}
                                              </div>
                                            ) : (
                                              "N/A"
                                            )}
                                          </>,
                                          6,
                                        ],
                                        [
                                          <div>
                                            {u.UtilityStartDate == u.usd
                                              ? `N/A`
                                              : `${convertDateFormat(
                                                  getPreviousMonth(
                                                    u.UtilityStartDate
                                                  )
                                                )}`}
                                          </div>,
                                          6,
                                        ],
                                      ]}
                                    />
                                  )}
                                </>
                              );
                            }
                          })}
                          <br></br>
                        </UnitsContainer>
                      </>
                    );
                  }
                });
              }
            })}
        </Table>
      )}
    </>
  );
};

export default UtilityCollected;
