const ErrorMsg = ({ text }) => {
  return (
    <>
      <div
        style={{
          color: "darkred",
          fontWeight: "600",
          fontSize: "1rem",
        }}
      >
        {text}
      </div>
    </>
  );
};

export default ErrorMsg;
