import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import classes from "../styles/EntriesPreview.module.css";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../AuthContext";

const EntriesPreview = ({ type, property, floor, unit }) => {
  const [entries, setEntries] = useState([]);
  const { bn } = UserAuth();
  const navigate = useNavigate();
  const fetchEntries = async () => {
    const docRef = await getDocs(
      query(
        collection(db, "Entries"),
        where("PropertyId", "==", property),
        where("FloorId", "==", floor),
        where("UnitId", "==", unit),
        where("entryType", "==", type),
        orderBy("entryTime"),
        limit(3)
      )
    );
    const ent = [];
    docRef.forEach((d) => {
      ent.push({ ...d.data(), id: d.id });
    });
    setEntries(ent);
  };

  useEffect(() => {
    fetchEntries();
  }, [type, property, floor, unit]);
  return (
    <>
      <div className={classes.entriesContainer}>
        {bn ? `Previous Entries:` : `পুরানো এন্ট্রি:`}
        {entries.map((e) => {
          return (
            <div onClick={() => navigate(`/entries/${e.id}`)}>
              {e.Month} : {e.paymentType}
            </div>
          );
        })}
      </div>
      <br></br>
    </>
  );
};

export default EntriesPreview;
