import classes from "../styles/DeleteDialog.module.css";

const DeleteDialog = ({ text, onDecline, onApproval }) => {
  return (
    <div className={`${classes.dialogContainer} ${classes.fadeIn}`}>
      <div className={classes.mainContainer}>
        <p>{text}</p>
        <div className={classes.buttonContainer}>
          <button
            className={classes.green}
            onClick={() => {
              onApproval();
            }}
          >
            Yes
          </button>
          <button
            className={classes.red}
            onClick={() => {
              onDecline();
            }}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteDialog;
