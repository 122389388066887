import { UserAuth } from "../AuthContext";
import classes from "../styles/DateInputWithLabel.module.css";

export const DateInputWithLabel = ({
  label,
  label_bn,
  placeholder,
  handleChange,
  notEditable,
  value,
  small,
  type,
}) => {
  const { bn } = UserAuth();
  return (
    <>
      <div className={`${classes.inputContainer} flex-column hidePrint`}>
        <label>
          {bn ? label : label_bn}
          <span>{value && notEditable ? `: ${value}` : null}</span>
        </label>
        {!notEditable && placeholder && (
          <input
            style={
              small
                ? { width: "7rem", height: "2.5rem", marginRight: "1rem" }
                : {}
            }
            type={type == undefined ? `month` : `date`}
            defaultValue={value}
            placeholder={placeholder}
            onChange={(e) => handleChange(e, label)}
          ></input>
        )}
      </div>
    </>
  );
};
