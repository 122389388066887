import { useEffect, useState } from "react";
import PageTitle from "../components/PageTitle";
import { GetLogs } from "../firebase";

const ActivityLog = () => {
  const [logs, setLogs] = useState([]);

  const fetchLogs = async () => {
    const logData = await GetLogs(100);
    setLogs(logData);
  };

  function firebaseTimestampToReadableTime(timestamp) {
    const date = timestamp.toDate();
    const dateOptions = {
      day: "2-digit",
      month: "long",
      year: "numeric",
    };

    const timeOptions = {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    const formattedDate = new Intl.DateTimeFormat("en-US", dateOptions).format(
      date
    );
    const formattedTime = new Intl.DateTimeFormat("en-US", timeOptions).format(
      date
    );

    return `${formattedDate}, ${formattedTime}`;
  }

  useEffect(() => {
    fetchLogs();
  }, []);

  return (
    <>
      <PageTitle title="Activity Log" />
      {logs.map((l) => {
        return (
          <div
            style={{
              marginBottom: "0.3rem",
            }}
          >
            <span
              style={{
                backgroundColor: "#dbdbdb",
                borderRadius: "2rem",
                marginRight: "0.5rem",
                padding: "0rem 0.5rem 0rem 0.5rem",
                minWidth: "11rem",
                display: "inline-block",
                color: "grey",
              }}
            >
              {firebaseTimestampToReadableTime(l.time)}
            </span>
            {l.text}
          </div>
        );
      })}
    </>
  );
};

export default ActivityLog;
