import { useParams } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import { useEffect, useState } from "react";
import { GetEntry } from "../../firebase";
import TextLabel from "../../components/TextLabel";

const EntryDetails = () => {
  const { entryId } = useParams();
  const [entryData, setEntryData] = useState([]);

  const [fieldData] = useState([
    // {
    //   label: "Floor",
    //   key: "FloorName",
    // },
    {
      label: "Property",
      key: "Property",
    },
    {
      label: "Floor",
      key: "Floor",
    },
    {
      label: "Block",
      key: "Block",
    },
    {
      label: "UnitNo",
      key: "UnitNo",
    },
    {
      label: "Tenant",
      key: "TenantName",
    },
    {
      label: "Collected",
      key: "AmountPaid",
    },
    {
      label: "Status",
      key: "paymentType",
    },
    {
      label: "Due",
      key: "remainingAmount",
    },
    {
      label: "Collector",
      key: "CollectedBy",
    },
    {
      label: "Entried On",
      key: "entryTime",
    },
    {
      label: "Month",
      key: "Month",
    },
  ]);

  const fetchEntry = async () => {
    const entry = await GetEntry(entryId);
    console.log(entry);
    setEntryData(entry);
  };

  function convertDateFormat(dateStr) {
    // Create a date object from the string
    let date = new Date(dateStr);

    // Define the month names
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract the month and year from the date
    let month = monthNames[date.getUTCMonth()];
    let year = date.getUTCFullYear();

    // Return the formatted date
    return `${month}, ${year}`;
  }

  useEffect(() => {
    fetchEntry();
  }, [entryId]);

  return (
    <>
      {entryData && (
        <PageTitle title="Entry Details" subTitle={entryData.entryType} />
      )}
      {entryData &&
        fieldData.map((e) => {
          return (
            <TextLabel
              label={e.label}
              value={
                e.label == "Month"
                  ? convertDateFormat(entryData[e.key]?.toString())
                  : e.label == "Entried On"
                  ? entryData[e.key]?.toDate().toLocaleDateString()
                  : entryData[e.key]?.toString()
              }
            />
          );
        })}
    </>
  );
};

export default EntryDetails;
