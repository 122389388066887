import { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import UnitSelector from "../../components/UnitSelector";
import TextLabel from "../../components/TextLabel";
import {
  AddEntry,
  GetEntryTenantMonth,
  GetImage,
  getFloorName,
  getPropertyName,
  getUnit,
  updateUnit,
} from "../../firebase";
import { DateInputWithLabel } from "../../components/DateInputWithLabel";
import PrimaryButton from "../../components/PrimaryButton";
import { UserAuth } from "../../AuthContext";
import { TextInputWithLabel } from "../../components/TextInputWithLabel";
import SuccessAnimation from "../../components/SuccessAnimation";
import EntriesPreview from "../../components/EntriesPreview";
import { useLocation, useNavigate } from "react-router-dom";
import { Loading } from "../../components/Loading";

const ServiceChargeEntry = () => {
  const [currentFloor, setCurrentFloor] = useState();
  const [currentProperty, setCurrentProperty] = useState();
  const [currentFloorName, setCurrentFloorName] = useState();
  const [currentPropertyName, setCurrentPropertyName] = useState();
  const [currentUnit, setCurrentUnit] = useState();
  const navigate = useNavigate();
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [data, setData] = useState({ Month: "2023-01" });
  const { user, rolesStream } = UserAuth();
  const [image, setImage] = useState();
  const [fieldData] = useState([
    {
      label: "currentTenant",
      label_bn: "বর্তমান ভাড়াটিয়া",
      origin: "unit",
      forceName: "Tenant Name",
    },
    {
      label: "Unit No",
      label_bn: "ইউনিট নং",
      origin: "unit",
    },
    {
      label: "Property Name",
      label_bn: "সম্পত্তির নাম",
      origin: "property",
    },
    {
      label: "Floor Name",
      label_bn: "তলার নাম",
      origin: "floor",
    },
    {
      label: "Block",
      label_bn: "ব্লক",
      origin: "unit",
    },
    {
      label: "Due",
      label_bn: "বাকি",
      origin: "this",
    },
  ]);

  const [unitData, setUnitData] = useState();
  const [overPay, setOverPay] = useState(false);
  const [partiallyPaid, setPartiallyPaid] = useState();
  const [entryData, setEntryData] = useState();

  const fetchUnitData = async () => {
    const unit = await getUnit(currentProperty, currentFloor, currentUnit);
    setUnitData(unit);

    const entries = await GetEntryTenantMonth(
      unit["currentTenantId"],
      data["Month"]
    );
    entries.forEach((e, index) => {
      if (e.entryType != "Service Charge") {
        delete entries[index];
      }
    });
    const rent =
      (parseInt(unit["CommonSpace(ifany)"]) + parseInt(unit["UnitSpace"])) *
      unit["ServiceChargeRate"];
    let previousPayment = 0;
    if (entries.length != 0) {
      entries.map((e) => {
        previousPayment += e.AmountPaid;
      });
    }
    const due = rent - previousPayment;
    console.log(entries);
    setEntryData({
      totalRent: rent,
      due: due,
    });
  };

  const fetchImage = async () => {
    const x = await GetImage(`Tenants/${unitData["currentTenantId"]}`);
    setImage(x);
  };

  const handleData = (e, label) => {
    const obj = {};
    obj[label.replace(/\s+/g, "")] = e.target.value;
    setData((prev) => {
      return { ...prev, ...obj };
    });
  };

  const handleAmount = (e, label) => {
    const obj = {};
    obj[label.replace(/\s+/g, "")] = parseInt(e.target.value);
    setData((prev) => {
      return { ...prev, ...obj };
    });
  };

  function getNextMonth(dateString) {
    const date = new Date(dateString + "-01");
    date.setMonth(date.getMonth() + 1);

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const monthString = month < 10 ? "0" + month : month;

    return `${year}-${monthString}`;
  }

  function formatMonthYear(dateString) {
    const date = new Date(dateString + "-01");
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const year = date.getFullYear();
    const month = monthNames[date.getMonth()];

    return `${month}, ${year}`;
  }

  const handleClick = async () => {
    setLoading(true);
    setOverPay(false);
    let paymentType;
    if (data["AmountPaid"] == entryData["due"]) {
      paymentType = "Full";
    } else if (data["AmountPaid"] < entryData["due"]) {
      paymentType = "Partial";
    }

    if (!(data["AmountPaid"] > entryData["due"])) {
      const entryId = await AddEntry({
        ...data,
        TenantId: unitData["currentTenantId"],
        UnitId: unitData["id"],
        Property: currentPropertyName,
        Floor: currentFloorName,
        Block: unitData[`Block`],
        PropertyId: currentProperty,
        FloorId: currentFloor,
        TenantName: unitData["currentTenant"],
        CollectedBy: user.Name,
        paymentType: paymentType,
        entryType: "Service Charge",
        remainingAmount: entryData["due"] - data["AmountPaid"],
        totalAmount: entryData["totalRent"],
        UnitNo: unitData["UnitNo"],
        BusinessName: unitData["currentBusinessName"],
      });
      sendMessageToTelegram(
        `_*${user.Name}*_ collected *Service Charge*💰 of *${
          unitData["currentBusinessName"]
        }*\nProperty: *${currentPropertyName}*\nFloor: *${currentFloorName}*\nBlock: *${
          unitData["Block"]
        }*\nAmount Collected: *${data["AmountPaid"]}* \nRemaining Amount: *${
          entryData["due"] - data["AmountPaid"]
        }*\nMonth: *${formatMonthYear(
          data["Month"]
        )}*\nPayment Type: *${paymentType}*`
      );
      if (paymentType == "Full") {
        await updateUnit(currentProperty, currentFloor, unitData["id"], {
          SCStartDate: getNextMonth(data["Month"]),
        });
      }
      console.log(entryId);
      setTimeout(() => {
        navigate(`/entries/${entryId}`);
      }, [3000]);
    } else {
      setOverPay(true);
    }
    fetchUnitData();
    setLoading(false);
    setDone(true);
  };

  useEffect(() => {
    fetchUnitData();
  }, [currentUnit, data.Month]);

  const fetchPropertyName = async () => {
    const propertyName = await getPropertyName(currentProperty);
    setCurrentPropertyName(propertyName);
  };

  const fetchFloorName = async () => {
    const floorName = await getFloorName(currentProperty, currentFloor);
    setCurrentFloorName(floorName);
  };

  useEffect(() => {
    fetchPropertyName();
  }, [currentProperty]);

  useEffect(() => {
    fetchFloorName();
  }, [currentFloor]);

  useEffect(() => {
    fetchImage();
  }, [unitData]);

  useEffect(() => {
    if (rolesStream && user) {
      if (rolesStream[user.Role][`ServiceChargeCollection`] != true) {
        navigate(`/home`);
      }
    }
  }, [rolesStream, user, location]);

  async function sendMessageToTelegram(text) {
    const url = `https://api.telegram.org/bot${botToken}/sendMessage`;

    const payload = {
      chat_id: "1999590644",
      text: text,
      parse_mode: "MarkdownV2",
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorDetails = await response.text();
        throw new Error(
          `Error sending message to Telegram: ${response.statusText}. Details: ${errorDetails}`
        );
      }

      const result = await response.json();
      console.log("Message sent successfully:", result);
    } catch (error) {
      console.error("Error sending message to Telegram:", error);
    }
  }

  const botToken = "6042041220:AAGpZ1kHQp1JMX83dAyASi3ozcw3UrJ0ppk";

  return (
    <>
      <PageTitle title="Service Charge Entry" image={image} />
      <UnitSelector
        setCurrentProperty={setCurrentProperty}
        setCurrentFloor={setCurrentFloor}
        setCurrentUnit={setCurrentUnit}
        onlyPrimary={true}
        onlyPosition={true}
      />
      {currentUnit && (
        <EntriesPreview
          type="Service Charge"
          property={currentProperty}
          floor={currentFloor}
          unit={currentUnit}
        />
      )}
      {currentUnit &&
        unitData &&
        entryData &&
        fieldData.map((f) => {
          return (
            <>
              {(f.label != "Due" || rolesStream[user.Role][`ViewDue`]) && (
                <TextLabel
                  label={f.forceName || f.label}
                  label_bn={f.label_bn}
                  value={
                    f.origin == "unit"
                      ? `${unitData[f.label.replace(/\s+/g, "")]}`
                      : f.origin == "property"
                      ? `${currentPropertyName}`
                      : f.origin == "floor"
                      ? `${currentFloorName}`
                      : ""
                      ? f.origin == "this"
                      : `${entryData["due"]}`
                  }
                />
              )}
            </>
          );
        })}
      <br></br>
      {
        <div>
          {currentUnit && (
            <>
              <DateInputWithLabel
                label="Month"
                notEditable={false}
                placeholder={"bel"}
                handleChange={handleData}
              />
              {entryData &&
                entryData["due"] != 0 &&
                unitData &&
                unitData["SCStartDate"] == data["Month"] && (
                  <TextInputWithLabel
                    label="Amount Paid"
                    notEditable={false}
                    placeholder={"Enter Amount"}
                    handleChange={handleAmount}
                  />
                )}
              <br></br>
              {overPay && <div>Amount Paid cannot be greater than Due</div>}
              {entryData && entryData["due"] == 0 && (
                <SuccessAnimation text={"Already Paid"} green={true} />
              )}
              {entryData &&
                entryData["due"] != 0 &&
                entryData["totalRent"] != entryData["due"] && (
                  <SuccessAnimation text={"Partially Paid"} darkYellow={true} />
                )}
              {entryData &&
                unitData &&
                unitData["SCStartDate"] == data["Month"] &&
                entryData["due"] != 0 &&
                entryData["totalRent"] == entryData["due"] && (
                  <SuccessAnimation
                    text={"Not Paid"}
                    red={true}
                    noIcon={true}
                  />
                )}
              {unitData && unitData["SCStartDate"] != data["Month"] && (
                <div style={{ color: "darkRed", fontWeight: "bold" }}>
                  <br></br>
                  {`Your Next Payment Month is: ${formatMonthYear(
                    unitData["SCStartDate"]
                  )}`}
                </div>
              )}
              <br></br>
              {entryData &&
                entryData["due"] != 0 &&
                unitData &&
                unitData["SCStartDate"] == data["Month"] &&
                !loading &&
                !done && (
                  <PrimaryButton text="Add Entry" handleClick={handleClick} />
                )}
              {loading && <Loading />}
            </>
          )}
        </div>
      }
    </>
  );
};

export default ServiceChargeEntry;
