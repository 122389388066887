// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  listAll,
  ref,
  uploadBytes,
} from "firebase/storage";
import { UserAuth } from "./AuthContext";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCt-lnXaKS0oWd-TGqHiRCfmWMDsp4uCsM",
  authDomain: "rentmilloh-production.firebaseapp.com",
  projectId: "rentmilloh-production",
  storageBucket: "rentmilloh-production.appspot.com",
  messagingSenderId: "546620586399",
  appId: "1:546620586399:web:b6a3f393b5210ce8c425aa",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);

// Firebase storage reference
export const storage = getStorage(app);

//Add Log
export const AddLog = async (logText) => {
  const docRef = await addDoc(collection(db, "ActivityLog"), {
    text: logText,
    time: serverTimestamp(),
  });
  return docRef.id;
};

//Add Entry
export const AddEntry = async (data) => {
  const docRef = await addDoc(collection(db, "Entries"), {
    ...data,
    entryTime: serverTimestamp(),
  });
  return docRef.id;
};

//Get Specific Entries
export const GetEntry = async (id) => {
  const docRef = await getDoc(doc(db, "Entries", id));

  return docRef.data();
};

export const GetEntriesOfMonth = async (type, month) => {
  const docRef = await getDocs(
    query(
      collection(db, "Entries"),
      where("entryType", "==", type),
      where("Month", "==", month)
    )
  );
  const entries = [];
  docRef.forEach((d) => {
    entries.push({ ...d.data(), id: d.id });
    console.log(d.data());
  });

  return entries;
};

const toUTCDate = (localDate) => {
  const utcDate = new Date(
    localDate.getTime() - localDate.getTimezoneOffset() * 60000
  );
  return utcDate;
};

//Get All Entries
export const GetEntries = async (startDate, endDate, type) => {
  console.log(endDate);
  const utcStartDate = toUTCDate(startDate);
  const utcEndDate = toUTCDate(endDate);

  const docRef = await getDocs(
    query(
      collection(db, "Entries"),
      orderBy("entryTime", "desc"),
      where("entryTime", ">=", Timestamp.fromDate(utcStartDate)),
      where("entryTime", "<=", Timestamp.fromDate(utcEndDate)),
      where("entryType", "==", type)
    )
  );
  const entries = [];
  docRef.forEach((d) => {
    entries.push({ ...d.data(), id: d.id });
    console.log(d.data());
  });

  return entries;
};

//Get Latest Entry of specific unit
export const GetLatestEntryOfUnit = async (
  propertyId,
  floorId,
  unitId,
  month,
  type
) => {
  const docRef = await getDocs(
    query(
      collection(db, "Entries"),
      orderBy("entryTime", "desc"),
      where("PropertyId", "==", propertyId),
      where("FloorId", "==", floorId),
      where("UnitId", "==", unitId),
      where("Month", "==", month),
      where("entryType", "==", type),
      limit(1)
    )
  );
  const entries = [];
  docRef.forEach((d) => {
    entries.push({ ...d.data(), id: d.id });
    console.log(d.data());
  });

  return entries;
};

export const GetEntryOfUnitOfMonth = async (
  propertyId,
  floorId,
  unitId,
  month,
  type
) => {
  const docRef = await getDocs(
    query(
      collection(db, "Entries"),
      orderBy("entryTime", "desc"),
      where("PropertyId", "==", propertyId),
      where("FloorId", "==", floorId),
      where("UnitId", "==", unitId),
      where("Month", "==", month),
      where("entryType", "==", type)
    )
  );
  const entries = [];
  docRef.forEach((d) => {
    entries.push({ ...d.data(), id: d.id });
    console.log(d.data());
  });

  return entries;
};

//Get Entries of a specific floor
export const GetEntriesOfFloor = async (
  propertyId,
  floorId,
  startDate,
  endDate,
  type
) => {
  const utcStartDate = toUTCDate(startDate);
  const utcEndDate = toUTCDate(endDate);
  const docRef = await getDocs(
    query(
      collection(db, "Entries"),
      orderBy("entryTime", "desc"),
      where("PropertyId", "==", propertyId),
      where("FloorId", "==", floorId),
      where("entryTime", ">=", Timestamp.fromDate(utcStartDate)),
      where("entryTime", "<=", Timestamp.fromDate(utcEndDate)),
      where("entryType", "==", type)
    )
  );
  const entries = [];
  docRef.forEach((d) => {
    entries.push({ ...d.data(), id: d.id });
    console.log(d.data());
  });

  return entries;
};

//Get Entries of a specific property
export const GetEntriesOfProperty = async (
  propertyId,
  startDate,
  endDate,
  type
) => {
  const utcStartDate = toUTCDate(startDate);
  const utcEndDate = toUTCDate(endDate);
  const docRef = await getDocs(
    query(
      collection(db, "Entries"),
      orderBy("entryTime", "desc"),
      where("PropertyId", "==", propertyId),
      where("entryTime", ">=", Timestamp.fromDate(utcStartDate)),
      where("entryTime", "<=", Timestamp.fromDate(utcEndDate)),
      where("entryType", "==", type)
    )
  );
  const entries = [];
  docRef.forEach((d) => {
    entries.push({ ...d.data(), id: d.id });
    console.log(d.data());
  });

  return entries;
};

//Get Entry of Tenant and Month
export const GetEntryTenantMonth = async (tenantId, month) => {
  const docRef = await getDocs(
    query(
      collection(db, "Entries"),
      where("Month", "==", month),
      where("TenantId", "==", tenantId)
    )
  );

  const entries = [];
  docRef.forEach((d) => {
    entries.push({ ...d.data(), id: d.id });
  });

  return entries;
};

//Get Logs
export const GetLogs = async (limitMin) => {
  const docRef = await getDocs(
    query(
      collection(db, "ActivityLog"),
      orderBy("time", "desc"),
      limit(limitMin)
    )
  );

  const logs = [];
  for (const doc of docRef.docs) {
    logs.push({
      id: doc.id,
      ...doc.data(),
    });
  }
  return logs;
};

//Upload File
export const UploadFile = async (file, location) => {
  const storageRef = ref(storage, `${location}/${file.name}`);
  uploadBytes(storageRef, file).then((snapshot) => {
    console.log("Uploaded a blob or file!");
  });
};

//Upload Image
export const UploadImage = async (file, location) => {
  const storageRef = ref(storage, `${location}/dp`);
  uploadBytes(storageRef, file).then((snapshot) => {
    console.log("Uploaded a blob or file!");
  });
};

// Get Image
export const GetImage = async (location) => {
  const imageRef = ref(storage, `${location}/dp`);
  let imageUrl = "";

  try {
    imageUrl = await getDownloadURL(imageRef);
  } catch (error) {
    console.error("Error getting image download URL: ", error);
  }

  return imageUrl;
};

//Get Files
export const GetFiles = async (location) => {
  const listRef = ref(storage, `${location}`);
  let urls = {};

  const res = await listAll(listRef);
  const downloadURLPromises = res.items.map(async (itemRef) => {
    try {
      const url = await getDownloadURL(itemRef);
      urls[itemRef.name] = url;
    } catch (error) {
      console.error("Error getting download URL: ", error);
    }
  });

  await Promise.all(downloadURLPromises);

  return urls;
};

export const DeleteFile = async (fileLocation) => {
  const fileRef = ref(storage, `${fileLocation}`);
  console.log(fileLocation);
  // Delete the file
  deleteObject(fileRef)
    .then(() => {
      // File deleted successfully
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
    });
};

//Add Request
export const addRequest = async (data) => {
  const docRef = await addDoc(collection(db, "Requests"), { ...data });
  return docRef.id;
};

//Update Request
export const updateRequest = async (id, data) => {
  const docRef = await updateDoc(doc(db, "Requests", id), { ...data });
};

//Get All Requests
export const getAllRequests = async () => {
  const docRef = await getDocs(
    query(collection(db, "Requests"), orderBy("time", "desc"))
  );

  const requests = [];
  docRef.forEach((d) => {
    requests.push({ ...d.data(), id: d.id });
  });
  return requests;
};

//Get Pending Requests
export const getPendingRequests = async () => {
  const docRef = await getDocs(
    query(
      collection(db, "Requests"),
      orderBy("time", "desc"),
      where("status", "==", "Pending")
    )
  );

  const requests = [];
  docRef.forEach((d) => {
    requests.push({ ...d.data(), id: d.id });
  });
  return requests;
};

//Get Specific Request
export const getRequest = async (id) => {
  const docRef = await getDoc(doc(db, "Requests", id));

  return { ...docRef.data(), id: docRef.id };
};

//Get All Properties
export const getProperties = async () => {
  const docRef = await getDocs(collection(db, "Properties"));

  const properties = [];
  for (const doc of docRef.docs) {
    const NoOfFloors = await getNumberOfFloors(doc.id);
    properties.push({
      id: doc.id,
      ...doc.data(),
      floorCount: NoOfFloors,
    });
  }
  return properties;
};

//Get Property Name from ID
export const getPropertyName = async (id) => {
  const docRef = await getDoc(doc(db, "Properties", id));

  return docRef.data().PropertyName;
};

//Update Property
export const updateProperty = async (id, data) => {
  await updateDoc(doc(db, "Properties", id), { ...data });
};

//Delete Property
export const deleteProperty = async (id) => {
  const floors = await getFloors(id);
  floors.map(async (f) => {
    await deleteDoc(doc(db, `Properties/${id}/Floors`, f.id));
  });
  await deleteDoc(doc(db, "Properties", id));
};

//Get Specific Property
export const getProperty = async (id) => {
  const docRef = await getDoc(doc(db, "Properties", id));

  return { ...docRef.data(), id: docRef.id };
};

//Add Property
export const addProperty = async (data) => {
  const docRef = await addDoc(collection(db, "Properties"), { ...data });
  return docRef.id;
};

export const getNumberOfFloors = async (propertyId) => {
  const count = await getCountFromServer(
    collection(db, `Properties/${propertyId}/Floors`)
  );
  return count.data().count;
};

//Get All Floors
export const getFloors = async (propertyId) => {
  const docRef = await getDocs(
    query(
      collection(db, `Properties/${propertyId}/Floors`),
      orderBy("Order", "asc")
    )
  );
  const floors = [];
  for (const doc of docRef.docs) {
    const unitCount = await getNumberOfUnits(propertyId, doc.id);
    floors.push({ id: doc.id, ...doc.data(), unitCount: unitCount });
  }
  return floors;
};

//Get Specific Floors
export const getFloor = async (propertyId, floorId) => {
  const docRef = await getDoc(
    doc(db, `Properties/${propertyId}/Floors`, floorId)
  );

  return { ...docRef.data(), id: docRef.id };
};

//Get Floor Name from ID
export const getFloorName = async (propertyId, floorId) => {
  const docRef = await getDoc(
    doc(db, `Properties/${propertyId}/Floors`, floorId)
  );
  console.log(docRef.data().FloorName);
  return docRef.data().FloorName;
};

//Add Floor
export const addFloor = async (propertyId, data) => {
  const docRef = await addDoc(
    collection(db, `Properties/${propertyId}/Floors`),
    { ...data }
  );
  return docRef.id;
};

//Update Floor
export const updateFloor = async (propertyId, floorId, data) => {
  await updateDoc(doc(db, `Properties/${propertyId}/Floors`, floorId), {
    ...data,
  });
};

export const getNumberOfUnits = async (propertyId, floorId) => {
  const count = await getCountFromServer(
    collection(db, `Properties/${propertyId}/Floors/${floorId}/Units`)
  );
  return count.data().count;
};

//Add Unit
export const addUnit = async (propertyId, floorId, data) => {
  const docRef = await addDoc(
    collection(db, `Properties/${propertyId}/Floors/${floorId}/Units`),
    {
      ...data,
    }
  );
  return docRef.id;
};

//Get Specific Unit
export const getUnit = async (propertyId, floorId, unitId) => {
  const docRef = await getDoc(
    doc(db, `Properties/${propertyId}/Floors/${floorId}/Units`, unitId)
  );

  return { ...docRef.data(), id: docRef.id };
};

export const getExistingUnitNo = async (propertyId, floorId, Block, UnitNo) => {
  const docRef = await getDocs(
    query(
      collection(db, `Properties/${propertyId}/Floors/${floorId}/Units`),
      where("Block", "==", Block),
      where("UnitNo", "==", parseInt(UnitNo))
    )
  );

  const units = [];
  docRef.forEach((d) => {
    units.push({ ...d.data(), id: d.id });
  });

  return units;
};

//Get All Units
export const getUnits = async (propertyId, floorId) => {
  const docRef = await getDocs(
    query(
      collection(db, `Properties/${propertyId}/Floors/${floorId}/Units`),
      orderBy("Block", "asc"),
      orderBy("UnitNo", "asc")
    )
  );

  const units = [];
  docRef.forEach((d) => {
    units.push({ ...d.data(), id: d.id });
  });

  return units;
};

//Update Unit
export const updateUnit = async (propertyId, floorId, unitId, data) => {
  await updateDoc(
    doc(db, `Properties/${propertyId}/Floors/${floorId}/Units`, unitId),
    {
      ...data,
    }
  );
};

//Add Floor Item
export const addFloorItem = async (propertyId, floorId, item) => {
  try {
    const docRef = await addDoc(
      collection(db, `Properties/${propertyId}/Floors/${floorId}/floorItems`),
      {
        type: item,
        floorId: floorId,
        propertyId: propertyId,
      }
    );
  } catch (err) {
    console.log(err);
  }
};

//Update Floor Item
export const updateFloorItem = async (propertyId, floorId, itemId, data) => {
  await updateDoc(
    doc(db, `Properties/${propertyId}/Floors/${floorId}/floorItems`, itemId),
    { ...data }
  );
};

//Delete Floor Item
export const deleteFloorItem = async (propertyId, floorId, itemId) => {
  await deleteDoc(
    doc(db, `Properties/${propertyId}/Floors/${floorId}/floorItems`, itemId)
  );
};

//Get Floor Item
export const getFloorItems = async (propertyId, floorId) => {
  const docRef = await getDocs(
    query(
      collection(db, `Properties/${propertyId}/Floors/${floorId}/floorItems`),
      where("propertyId", "==", propertyId),
      where("floorId", "==", floorId)
    )
  );

  const items = [];
  docRef.forEach((d) => {
    items.push({ ...d.data(), id: d.id });
  });
  return items;
};

//Add Tenant
export const addTenant = async (propertyId, floorId, unitId, data) => {
  const docRef = await addDoc(
    collection(
      db,
      `Properties/${propertyId}/Floors/${floorId}/Units/${unitId}/Tenants`
    ),
    {
      ...data,
      addDate: serverTimestamp(),
    }
  );
  return docRef.id;
};

//Get Specific Tenant
export const getTenant = async (propertyId, floorId, unitId, tenantId) => {
  const docRef = await getDoc(
    doc(
      db,
      `Properties/${propertyId}/Floors/${floorId}/Units/${unitId}/Tenants`,
      tenantId
    )
  );

  return { ...docRef.data(), id: docRef.id };
};

//Update Tenant
export const updateTenant = async (
  propertyId,
  floorId,
  unitId,
  tenantId,
  data
) => {
  await updateDoc(
    doc(
      db,
      `Properties/${propertyId}/Floors/${floorId}/Units/${unitId}/Tenants`,
      tenantId
    ),
    {
      ...data,
    }
  );
};

//Find User with username
export const findUser = async (username) => {
  let foundUser;
  const docRef = await getDocs(
    query(collection(db, "Users"), where("Username", "==", username))
  );
  docRef.forEach((d) => {
    foundUser = { ...d.data(), id: d.id };
  });
  return foundUser;
};

//Get All Users
export const getUsers = async () => {
  const docRef = await getDocs(collection(db, "Users"));

  const users = [];
  docRef.forEach((d) => {
    users.push({ ...d.data(), id: d.id });
  });

  return users;
};

//Get Specific User
export const getUser = async (id) => {
  const docRef = await getDoc(doc(db, "Users", id));
  return { ...docRef.data(), id: docRef.id };
};

//Update Role
export const updateUser = async (id, data) => {
  await updateDoc(doc(db, "Users", id), { ...data });
};

//Get All Roles
export const getRoles = async () => {
  const docRef = await getDocs(collection(db, "Roles"));

  const roles = [];
  for (const doc of docRef.docs) {
    roles.push({
      id: doc.id,
      ...doc.data(),
    });
  }
  return roles;
};

//Get Specific Role
export const getRole = async (id) => {
  const docRef = await getDoc(doc(db, "Roles", id));

  return docRef.data();
};

//Update Role
export const updateRole = async (id, data) => {
  await updateDoc(doc(db, "Roles", id), { ...data });
};
