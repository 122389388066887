import { Edit } from "@mui/icons-material";
import classes from "../styles/TableEdit.module.css";

const TableEdit = ({ handleClick }) => {
  return (
    <div onClick={() => handleClick()} className={classes.edit}>
      <Edit />
    </div>
  );
};

export default TableEdit;
