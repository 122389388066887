import { useEffect, useState } from "react";
import PageTitle from "../components/PageTitle";
import { useNavigate } from "react-router-dom";
import {
  GetEntryOfUnitOfMonth,
  GetLatestEntryOfUnit,
  db,
  getFloor,
  getFloors,
  getProperties,
  getProperty,
  getUnits,
} from "../firebase";
import Dropdown from "../components/Dropdown";
import { DateInputWithLabel } from "../components/DateInputWithLabel";
import classes from "../styles/Statistics.module.css";
import {
  Chart as ChartJs,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import { collection, getDocs, query, where } from "firebase/firestore";

ChartJs.register(
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

const Statistics = () => {
  const navigate = useNavigate();
  const [propertyId, setPropertyId] = useState("allProperties");
  const [floorId, setFloorId] = useState("allFloors");
  const [units, setUnits] = useState([]);
  const [floors, setFloors] = useState([]);
  const [properties, setProperties] = useState([]);
  const [currentProperty, setCurrentProperty] = useState();
  const [currentFloor, setCurrentFloor] = useState();
  const [statusData, setStatusData] = useState();
  const [month, setMonth] = useState({
    "Start Date": getCurrentDateAndMonth(),
    "End Date": getCurrentDateAndMonth(),
  });
  const [loading, setLoading] = useState(true);
  const [searchData, setSearchData] = useState("");
  const [searchState, setSearchState] = useState("Block");
  const [totalRecieveable, setTotalRecieveable] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [type, setType] = useState("Rent");
  const [lastMonthsRev, setLastMonthsRev] = useState([]);
  const [lastMonths, setLastMonths] = useState([]);

  function getCurrentDateAndMonth() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const monthString = month < 10 ? "0" + month : month;

    return `${year}-${monthString}`;
  }

  const fetchProperties = async () => {
    const docRef = await getProperties();
    const propertyData = [];
    docRef.forEach((d) => {
      propertyData.push({ text: d.PropertyName, value: d.id });
    });
    propertyData.push({ text: "All Properties", value: "allProperties" });
    setProperties(propertyData);
  };

  const fetchFloors = async (propertyId) => {
    const docRef = await getFloors(propertyId);
    console.log(docRef);
    const floorData = [];
    docRef.forEach((d) => {
      floorData.push({ text: d.FloorName, value: d.id });
    });
    floorData.push({ text: "All Floors", value: "allFloors" });
    setFloors(floorData);
  };

  const fetchSpecificFloorUnits = async (propertyId, floorId) => {
    let grand = 0;
    let grandPaid = 0;
    let statusObj = {
      NotPaid: 0,
      Partial: 0,
      Full: 0,
    };
    let [prop, floor, unitsArr] = await Promise.all([
      getProperty(propertyId),
      getFloor(propertyId, floorId),
      getUnits(propertyId, floorId),
    ]);

    unitsArr = unitsArr.filter((u) => u.currentTenantId != "N/A");
    const months = getMonthsInRange(month["Start Date"], month["End Date"]);

    await Promise.all(
      unitsArr.map(async (u) => {
        let total = 0;
        let totalPaid = 0;
        await Promise.all(
          // Add Promise.all here
          months.map(async (m) => {
            const latest = await GetLatestEntryOfUnit(
              propertyId,
              floorId,
              u.id,
              m ? m : getCurrentDateAndMonth(),
              type
            );
            const latestPaid = await GetEntryOfUnitOfMonth(
              propertyId,
              floorId,
              u.id,
              m ? m : getCurrentDateAndMonth(),
              type
            );

            latestPaid.map((l) => {
              totalPaid += l["AmountPaid"];
              if (latest[0].paymentType == "Partial") {
                statusObj[`Partial`] += l["AmountPaid"];
              } else if (latest[0].paymentType == "Full") {
                statusObj[`Full`] += l["AmountPaid"];
              }
            });

            if (latest[0]) {
              total += latest[0]["remainingAmount"];

              statusObj[`NotPaid`] += latest[0]["remainingAmount"];
            } else {
              if (type == "Service Charge") {
                if (m >= u.ssd && m >= u.ssd) {
                  total +=
                    (parseInt(u.UnitSpace) +
                      parseInt(u["CommonSpace(ifany)"])) *
                    u.ServiceChargeRate;
                  statusObj[`NotPaid`] +=
                    (parseInt(u.UnitSpace) +
                      parseInt(u["CommonSpace(ifany)"])) *
                    u.ServiceChargeRate;
                }
              } else if (type == "Utility") {
                total += 0;
              } else if (type == "Rent") {
                if (m >= u.rsd && m >= u.rsd) {
                  total += u.UnitSpace * u.Unitratepersq;
                  statusObj[`NotPaid`] += u.UnitSpace * u.Unitratepersq;
                }
              }
            }
          })
        );
        if (!total) {
          total = 0;
        }
        console.log(total);
        u["total"] = total;
        grand += total;
        grandPaid += totalPaid;
        setTotalRecieveable(grand);
        setTotalPaid(grandPaid);
        setStatusData(statusObj);
      })
    );
    const propertyArr = [
      {
        PropertyName: prop.PropertyName,
        id: prop.id,
        Floors: [
          {
            FloorName: floor.FloorName,
            id: floor.id,
            Units: unitsArr,
          },
        ],
      },
    ];

    setUnits(propertyArr);
    setLoading(false);
  };

  const fetchSpecificPropertyUnits = async (propertyId) => {
    let grand = 0;
    let grandPaid = 0;
    let statusObj = {
      NotPaid: 0,
      Partial: 0,
      Full: 0,
    };
    const [prop, floors] = await Promise.all([
      getProperty(propertyId),
      getFloors(propertyId),
    ]);

    const floorArrPromises = floors.map(async (f) => {
      let unitsArr = await getUnits(propertyId, f.id);

      unitsArr = unitsArr.filter((u) => u.currentTenantId != "N/A");
      const months = getMonthsInRange(month["Start Date"], month["End Date"]);

      await Promise.all(
        unitsArr.map(async (u) => {
          let total = 0;
          let totalPaid = 0;
          await Promise.all(
            // Add Promise.all here
            months.map(async (m) => {
              const latest = await GetLatestEntryOfUnit(
                propertyId,
                f.id,
                u.id,
                m ? m : getCurrentDateAndMonth(),
                type
              );

              const latestPaid = await GetEntryOfUnitOfMonth(
                propertyId,
                f.id,
                u.id,
                m ? m : getCurrentDateAndMonth(),
                type
              );

              latestPaid.map((l) => {
                totalPaid += l["AmountPaid"];
                if (latest[0].paymentType == "Partial") {
                  statusObj[`Partial`] += l["AmountPaid"];
                } else if (latest[0].paymentType == "Full") {
                  statusObj[`Full`] += l["AmountPaid"];
                }
              });

              if (latest[0]) {
                total += latest[0]["remainingAmount"];

                statusObj[`NotPaid`] += latest[0]["remainingAmount"];
              } else {
                if (type == "Service Charge") {
                  if (m >= u.ssd && m >= u.ssd) {
                    total +=
                      (parseInt(u.UnitSpace) +
                        parseInt(u["CommonSpace(ifany)"])) *
                      u.ServiceChargeRate;
                    statusObj[`NotPaid`] +=
                      (parseInt(u.UnitSpace) +
                        parseInt(u["CommonSpace(ifany)"])) *
                      u.ServiceChargeRate;
                  }
                } else if (type == "Utility") {
                  total += 0;
                } else if (type == "Rent") {
                  if (m >= u.rsd && m >= u.rsd) {
                    total += u.UnitSpace * u.Unitratepersq;
                    statusObj[`NotPaid`] += u.UnitSpace * u.Unitratepersq;
                  }
                }
              }
            })
          );
          if (!total) {
            total = 0;
          }

          console.log(total);
          u["total"] = total;
          grand += total;
          grandPaid += totalPaid;
          setTotalPaid(grandPaid);
          setStatusData(statusObj);
          setTotalRecieveable(grand);
        })
      );

      console.log(unitsArr);
      return {
        FloorName: f.FloorName,
        id: f.id,
        Units: unitsArr,
      };
    });

    const floorsArr = await Promise.all(floorArrPromises);

    const propertyArr = [
      {
        PropertyName: prop.PropertyName,
        id: prop.id,
        Floors: floorsArr,
      },
    ];

    setUnits(propertyArr);
    setLoading(false);
  };

  function getMonthsInRange(startDate, endDate) {
    const start = new Date(startDate + "-01");
    const end = new Date(endDate + "-01");
    const months = [];

    while (start <= end) {
      const year = start.getFullYear();
      const month = (start.getMonth() + 1).toString().padStart(2, "0");
      months.push(`${year}-${month}`);

      start.setMonth(start.getMonth() + 1);
    }

    return months;
  }

  const fetchAllUnits = async () => {
    let grand = 0;
    let grandPaid = 0;
    let statusObj = {
      NotPaid: 0,
      Partial: 0,
      Full: 0,
    };
    const monthlyData = [];
    const props = await getProperties();
    const propertyArrPromises = props.map(async (p) => {
      const floors = await getFloors(p.id);
      const floorArrPromises = floors.map(async (f) => {
        let unitsArr = await getUnits(p.id, f.id);

        unitsArr = unitsArr.filter((u) => u.currentTenantId != "N/A");
        const months = getMonthsInRange(month["Start Date"], month["End Date"]);

        console.log(months);
        await Promise.all(
          unitsArr.map(async (u) => {
            let total = 0;
            let totalPaid = 0;
            await Promise.all(
              // Add Promise.all here
              months.map(async (m) => {
                const latest = await GetLatestEntryOfUnit(
                  p.id,
                  f.id,
                  u.id,
                  m ? m : getCurrentDateAndMonth(),
                  type
                );

                const latestPaid = await GetEntryOfUnitOfMonth(
                  p.id,
                  f.id,
                  u.id,
                  m ? m : getCurrentDateAndMonth(),
                  type
                );

                latestPaid.map((l) => {
                  totalPaid += l["AmountPaid"];
                  if (latest[0].paymentType == "Partial") {
                    statusObj[`Partial`] += l["AmountPaid"];
                  } else if (latest[0].paymentType == "Full") {
                    statusObj[`Full`] += l["AmountPaid"];
                  }
                });
                if (latest[0]) {
                  console.log(u.id);
                  total += latest[0]["remainingAmount"];
                  statusObj[`NotPaid`] += latest[0]["remainingAmount"];
                } else {
                  if (type == "Service Charge") {
                    if (m >= u.ssd && m >= u.ssd) {
                      total +=
                        (parseInt(u.UnitSpace) +
                          parseInt(u["CommonSpace(ifany)"])) *
                        u.ServiceChargeRate;
                      statusObj[`NotPaid`] +=
                        (parseInt(u.UnitSpace) +
                          parseInt(u["CommonSpace(ifany)"])) *
                        u.ServiceChargeRate;
                    }
                  } else if (type == "Utility") {
                    total += 0;
                  } else if (type == "Rent") {
                    if (m >= u.rsd && m >= u.rsd) {
                      total += u.UnitSpace * u.Unitratepersq;
                      statusObj[`NotPaid`] += u.UnitSpace * u.Unitratepersq;
                    }
                  }
                }
              })
            );
            if (!total) {
              total = 0;
            }
            u["total"] = total;
            grand += total;
            grandPaid += totalPaid;
            setTotalRecieveable(grand);
            setTotalPaid(grandPaid);
            setStatusData(statusObj);
          })
        );

        return {
          FloorName: f.FloorName,
          id: f.id,
          Units: unitsArr,
        };
      });
      const floorsArr = await Promise.all(floorArrPromises);
      return {
        PropertyName: p.PropertyName,
        id: p.id,
        Floors: floorsArr,
      };
    });

    const propertyArr = await Promise.all(propertyArrPromises);
    setUnits(propertyArr);

    setLoading(false);
  };

  function getPreviousMonth(dateString) {
    const date = new Date(dateString + "-01");
    date.setMonth(date.getMonth() - 1);

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const monthString = month < 10 ? "0" + month : month;

    return `${year}-${monthString}`;
  }

  const handleChangeProperty = async (id) => {
    setLoading(true);
    setPropertyId(id);
    console.log(properties);
  };

  const handleChangeFloor = async (id) => {
    setLoading(true);
    setFloorId(id);
  };

  const handleChangeType = async (id) => {
    setLoading(true);
    setType(id);
  };

  const handleChange = (e, label) => {
    setLoading(true);
    let obj = {};
    obj[label] = e.target.value;
    setMonth((prev) => {
      return { ...prev, ...obj };
    });
  };

  const handleSearchChange = (e, label) => {
    setSearchData(e.target.value);
  };

  function formatTaka(amount) {
    const takaSymbol = "৳";
    const formattedAmount = new Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 2,
    }).format(amount);
    return `${takaSymbol}${formattedAmount}`;
  }

  const fetchAllEntriesOfAllUnits = async () => {
    let allMonthData = await Promise.all(
      lastMonths.map(async (l) => {
        let total = 0;
        const monthArr = [];
        const monthData = await getDocs(
          query(
            collection(db, "Entries"),
            where("entryType", "==", type),
            where("Month", "==", l)
          )
        );
        monthData.forEach((d) => {
          monthArr.push(d.data());
        });
        monthArr.map((m) => {
          total += m.AmountPaid;
        });
        return total;
      })
    );
    setLastMonthsRev(allMonthData);
  };

  const fetchAllEntriesOfSpecificProperty = async () => {
    let allMonthData = await Promise.all(
      lastMonths.map(async (l) => {
        let total = 0;
        const monthArr = [];
        const monthData = await getDocs(
          query(
            collection(db, "Entries"),
            where("entryType", "==", type),
            where("Month", "==", l),
            where("PropertyId", "==", currentProperty)
          )
        );
        monthData.forEach((d) => {
          monthArr.push(d.data());
        });
        monthArr.map((m) => {
          total += m.AmountPaid;
        });
        return total;
      })
    );
    setLastMonthsRev(allMonthData);
  };

  const fetchAllEntriesOfSpecificFloor = async () => {
    let allMonthData = await Promise.all(
      lastMonths.map(async (l) => {
        let total = 0;
        const monthArr = [];
        const monthData = await getDocs(
          query(
            collection(db, "Entries"),
            where("entryType", "==", type),
            where("Month", "==", l),
            where("FloorId", "==", currentFloor)
          )
        );
        monthData.forEach((d) => {
          monthArr.push(d.data());
        });
        monthArr.map((m) => {
          total += m.AmountPaid;
        });
        return total;
      })
    );
    setLastMonthsRev(allMonthData);
  };

  useEffect(() => {
    if (propertyId == "allProperties" && floorId == "allFloors") {
      fetchAllUnits();
      fetchAllEntriesOfAllUnits();
    } else if (floorId == "allFloors" && propertyId != "allProperties") {
      console.log("All floors of specific property");
      fetchSpecificPropertyUnits(propertyId);
      fetchAllEntriesOfSpecificProperty();
    } else if (propertyId != "allProperties" && floorId != "allFloors") {
      fetchSpecificFloorUnits(propertyId, floorId);
      fetchAllEntriesOfSpecificFloor();
    }

    setCurrentProperty(propertyId);
    setCurrentFloor(floorId);
    fetchProperties();
    fetchFloors(propertyId);
  }, [
    propertyId,
    floorId,
    month,
    type,
    lastMonths,
    currentProperty,
    currentFloor,
  ]);

  useEffect(() => {
    console.log(searchState);
  }, [searchState]);

  useEffect(() => {
    console.log(searchData);
  }, [searchData]);

  useEffect(() => {
    console.log(units);
  }, [units]);

  useEffect(() => {
    console.log(month);
  }, [month]);

  useEffect(() => {
    getLastFiveMonths();
  }, []);

  function findTextById(arr, id) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].value === id) {
        return arr[i].text;
      }
    }
    return null;
  }

  function getLastFiveMonths() {
    let currentDate = new Date();

    let months = [];
    let fiveMonthsFromNow = new Date(
      currentDate.setMonth(currentDate.getMonth() + 5)
    );

    for (let i = 0; i < 10; i++) {
      let month = fiveMonthsFromNow.getMonth() + 1 - i;
      let year = fiveMonthsFromNow.getFullYear();

      if (month <= 0) {
        month += 12;
        year -= 1;
      }

      let formattedMonth = `${year}-${month.toString().padStart(2, "0")}`;
      months.unshift(formattedMonth);
    }

    setLastMonths(months);
  }
  function convertMonthsToNames(monthsArray) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return monthsArray.map((dateString) => {
      const dateComponents = dateString.split("-");
      const year = dateComponents[0].slice(-2);
      const monthIndex = parseInt(dateComponents[1], 10) - 1;
      return `${monthNames[monthIndex]} '${year}`;
    });
  }

  const data = {
    labels: lastMonths
      ? [...convertMonthsToNames(lastMonths)]
      : ["Jan", "Feb", "Mar", "Apr", "May"],
    // labels: ["Jan", "Feb", "Mar", "Apr", "May"],
    datasets: [
      {
        label: "Total Revenue",
        data: lastMonthsRev ? [...lastMonthsRev] : ["0", "0", "0", "0", "0"],
        // data: ["0", "3", "1", "5", "0"],
        backgroundColor: "#0088C2",
        borderColor: "black",
        barPercentage: 0.7,
        categoryPercentage: 0.8,
        borderRadius: 7,
      },
    ],
  };

  const data2 = {
    labels: ["Paid", "Not Paid", "Partially Paid"],
    datasets: [
      {
        label: "Amount",
        data: statusData
          ? [statusData["Full"], statusData["NotPaid"], statusData["Partial"]]
          : [0, 0, 0],
        backgroundColor: ["lightgreen", "red", "orange"],
        borderColor: "white",
        barPercentage: 0.7,
        borderWidth: 2,
        categoryPercentage: 0.8,
      },
    ],
  };

  return (
    <>
      <PageTitle
        title="Statistics"
        subTitle={`of ${
          currentProperty == "allProperties"
            ? "All Properties"
            : findTextById(properties, currentProperty)
        }, ${
          currentFloor == "allFloors"
            ? "All Floors"
            : findTextById(floors, currentFloor)
        } of ${month["Start Date"]} to ${month["End Date"]}`}
      />

      <div className="flex-row">
        {currentProperty && properties.length > 0 && (
          <Dropdown
            items={properties}
            label="Property"
            handleChange={handleChangeProperty}
            defaultVal={propertyId}
          />
        )}

        {currentFloor && floors.length > 0 && (
          <Dropdown
            items={floors}
            label="Floor"
            handleChange={handleChangeFloor}
            defaultVal={floorId}
          />
        )}

        <Dropdown
          items={[
            { text: "Rent", value: "Rent" },
            { text: "Utility", value: "Utility" },
            { text: "Service Charge", value: "Service Charge" },
          ]}
          label="Type"
          handleChange={handleChangeType}
          defaultVal={`Rent`}
        />
      </div>

      <div className="flex-row" style={{ marginTop: "0.5rem" }}>
        <DateInputWithLabel
          small={true}
          label="Start Date"
          placeholder={"Start Date"}
          handleChange={handleChange}
        />
        <DateInputWithLabel
          small={true}
          label="End Date"
          placeholder={"End Date"}
          handleChange={handleChange}
        />
      </div>
      <br></br>
      <div className={classes.statsContainerTop}>
        <div className={classes.statsRec}>
          Recieveable: <p>{`${formatTaka(totalRecieveable)}`}</p>
        </div>
        <div className={classes.statsCol}>
          Collected: <p>{`${formatTaka(totalPaid)}`}</p>
        </div>
      </div>

      <div className={classes.statsContainerBottom}>
        <div className={classes.barContainer}>
          <Bar
            className={classes.bar}
            style={{ maxWidth: "16rem", maxHeight: "16rem" }}
            data={data}
            options={{
              responsive: true,

              maintainAspectRatio: false,
              padding: 0,
              margin: 0,
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                y: {
                  display: false,
                  grid: {
                    display: false,
                  },
                },
                x: {
                  grid: {
                    display: false,
                  },
                },
              },
            }}
          ></Bar>
        </div>
        <div className={classes.pieContainer}>
          <Pie
            style={{ maxWidth: "20rem", maxHeight: "20rem" }}
            data={data2}
            options={{}}
          ></Pie>
        </div>
      </div>

      <br></br>
    </>
  );
};

export default Statistics;
