import classes from "../styles/FullScreenLoading.module.css";

const FullScreenLoading = () => {
  return (
    <>
      <div className={classes.loaderDiv}>
        <span className={classes.loader}>
          <span></span>
          <span></span>
        </span>
      </div>
    </>
  );
};

export default FullScreenLoading;
